import average from './average';

function standardDeviation(values) {
  const avg = average(values);

  const squareDiffs = values.map(value => {
    const diff = value - avg;
    return diff * diff;
  });

  const avgSquareDiff = average(squareDiffs);

  return Math.sqrt(avgSquareDiff);
}

export default standardDeviation;
