import createMinAvgMaxStdDevPercentileString from 'plugins/math/create_min_avg_max_std_dev_percentile_string';

function debugInfoFromShakaStats(stats, segmentsStats, currentDateString, currentQuality) {
  const bufferingDurations = stats.stateHistory.filter((h) => h.state === 'buffering').map((d) => d.duration * 1000);

  const debugInfo = {
    date: currentDateString,
    quality: currentQuality,
    'estimated bandwidth': `${Math.round(stats.estimatedBandwidth / 1024)} kbps (${Math.round(
      stats.estimatedBandwidth / 1024 / 8,
    )} kB/s)`,
    'stream bandwidth': `${Math.round(stats.streamBandwidth / 1024)} kbps (${Math.round(
      stats.streamBandwidth / 1024 / 8,
    )} kB/s)`,
    'play time': `${Math.round(stats.playTime * 1000)} ms`,
    'buffering count': `${bufferingDurations.length}`,
    'buffering times': `${createMinAvgMaxStdDevPercentileString(bufferingDurations)} ms`,
    'buffering total': `${Math.round(stats.bufferingTime * 1000)} ms`,
  };

  for (const [name, info] of Object.entries(segmentsStats)) {
    const durations = info.filter((i) => !i.fromCache).map((d) => d.duration);
    const sizes = info.filter((i) => !i.fromCache).map((d) => Math.round(d.size / 1024));
    const speeds = info.filter((i) => !i.fromCache).map((d) => Math.round(d.speed / 1024));

    debugInfo[`${name} count`] = `${info.filter((i) => !i.fromCache).length}/${info.length}`;
    debugInfo[`${name} time`] = `${createMinAvgMaxStdDevPercentileString(durations)} ms`;
    debugInfo[`${name} size`] = `${createMinAvgMaxStdDevPercentileString(sizes)} kB`;
    debugInfo[`${name} speed`] = `${createMinAvgMaxStdDevPercentileString(speeds, true)} kB/s`;
  }

  return debugInfo;
}

export default debugInfoFromShakaStats;
