const INVALID_USER_AGENTS = [
  'HeadlessChrome',
  'i4ds-bot',
  'YandexBot',
  'Google Bot',
  'Jooblebot',
  'SMTBot',
  'QuickSproutBot',
  'Googlebot',
  'DuckDuckBot',
  'Marketing Miner Bot',
  'JobboerseBot',
  'PrivacyAwareBot',
  'Phantom.js bot',
  'MsnBot-Media',
  'LmcTestbot',
  'AhrefsBot',
  'AppEngine-Google',
  'Exabot-Thumbnails',
  'Mappy',
  'AdsBot-Google-Mobile',
  'YandexMobileBot',
  'screeenly-bot',
  'semanticbot',
  'AdsBot-Google',
].map(userAgent => userAgent.toLowerCase());

function isBotUserAgent() {
  const userAgent = (navigator.userAgent || '').toLowerCase();
  for (const invalidUserAgent of INVALID_USER_AGENTS) {
    if (userAgent.indexOf(invalidUserAgent) >= 0) {
      return true;
    }
  }

  return false;
}

export default isBotUserAgent;
