import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

class Slide {
  constructor(type, options, callbacks) {
    this.props = {
      type,
      element: null,
      isLoading: false,
      isLoaded: false,
      currentQuality: null,
    };

    this.callbacks = callbacks;
    this.options = options;
  }

  load(quality) {
    this.props.currentQuality = quality;
    this.props.isLoading = true;
  }

  loadingQuality(quality) {
    if (!isVariableDefinedNotNull(this.currentQuality) && !isVariableDefinedNotNull(quality)) {
      return this.isLoading;
    }

    return this.isLoading && this.currentQuality === quality;
  }

  loadedQuality(quality) {
    if (!isVariableDefinedNotNull(this.currentQuality) && !isVariableDefinedNotNull(quality)) {
      return this.isLoaded;
    }

    return this.isLoaded && this.currentQuality === quality;
  }

  get time() {
    return this.options.time;
  }

  get type() {
    return this.props.type;
  }

  get element() {
    return this.props.element;
  }

  set element(value) {
    this.props.element = value;
  }

  get isLoaded() {
    return this.props.isLoaded;
  }

  set isLoaded(value) {
    this.props.isLoaded = value;
  }

  get isLoading() {
    return this.props.isLoading;
  }

  set isLoading(value) {
    this.props.isLoading = value;
  }

  get currentQuality() {
    return this.props.currentQuality;
  }

  get width() {
    if (!this.element) {
      return 0;
    }

    if (this.type === 'video') {
      if (!this.element.firstElementChild) return 0;

      return this.element.firstElementChild.videoWidth;
    }

    return this.element.naturalWidth;
  }

  get height() {
    if (!this.element) {
      return 0;
    }

    if (this.type === 'video') {
      if (!this.element.firstElementChild) return 0;

      return this.element.firstElementChild.videoHeight;
    }

    return this.element.naturalHeight;
  }
}

export default Slide;
