import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

import videoFormatNotSupportedUserMessage from './video_format_not_supported_user_message';
import videoLoadingFailedUserMessage from './video_loading_failed_user_message';
import nativeErrorCodeName from './native_error_code_name';

function createUserMessageArrayForNativeError(error, presentationId) {
  let userMessageArray;
  if (error.code === 4) {
    userMessageArray = videoFormatNotSupportedUserMessage(presentationId);
  } else {
    userMessageArray = videoLoadingFailedUserMessage(presentationId);
  }

  const debugDetails = [];

  const errorCategoryName = error.constructor.name;
  const errorCodeName = nativeErrorCodeName(error);

  debugDetails.push(errorCategoryName);
  debugDetails.push(`${errorCodeName} (${error.code})`);

  if (isVariableDefinedNotNull(error.message)) {
    debugDetails.push(error.message);
  }

  return {
    userMessageArray,
    debugDetails,
  };
}

export default createUserMessageArrayForNativeError;
