import VideoSlideBase from './video_slide_base';

class EditorVideo extends VideoSlideBase {
  _loadVideoPlayer() {
    if (this.options.data.service && this.options.data.id) {
      this._videoPlayer.load(this.options.data.service, this.options.data.id, {
        videoCdnServers: this.options.data.videoServers || this._videoServers,
      });
    } else if (this.options.url) {
      this._videoPlayer.load('url', this.url);
    }
  }

  set alwaysMuted(value) {
    this.options.data.muted = value;

    if (this._videoPlayer) {
      this._videoPlayer.muted = value;
    }
  }

  get loading() {
    return this.options.loading;
  }

  get url() {
    return this.options.url;
  }

  get originalUrl() {
    return this.options.originalUrl;
  }
}

export default EditorVideo;
