import log from 'modules/player/log';
import WistiaPlayerApiLoader from 'wistia-player-api-loader';

import defer from 'plugins/utilities/defer';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

const valueOrDefault = (object, key, defaultValue) =>
  isVariableDefinedNotNull(object[key]) ? object[key] : defaultValue;

export default class Wistia {
  constructor(element, options) {
    this.element = element;
    this.options = options;

    this.props = {
      loading: false,
      ready: false,
      currentTime: 0,
      duration: 0,

      state: '',

      volume: valueOrDefault(this.options.initial, 'volume', 100),
      muted: valueOrDefault(this.options.initial, 'muted', false),
      playbackRate: valueOrDefault(this.options.initial, 'playbackRate', 1),

      ratio: valueOrDefault(this.options.initial, 'ratio', 16 / 9.0),

      availablePlaybackRates: [],
    };
  }

  load(service, videoId) {
    this.loading = true;

    WistiaPlayerApiLoader.load((WistiaSDK) => this.doLoad(WistiaSDK, videoId));
  }

  doLoad(WistiaSDK, videoId) {
    this.wistia = WistiaSDK.embed(videoId, {
      container: this.element.getAttribute('id'),
      autoPlay: false,
      controlsVisibleOnLoad: false,
      doNotTrack: true,
      endVideoBehavior: 'default',
      fullscreenButton: false,
      googleAnalytics: false,
      muted: false,
      playbackRateControl: false,
      playbar: false,
      playButton: false,
      preload: 'metadata',
      qualityControl: false,
      qualityMax: 3840,
      qualityMin: 224,
      seo: false,
      settingsControl: false,
      silentAutoPlay: false,
      smallPlayButton: false,
      time: 0,
      videoFoam: true,
      volumeControl: false,
      wmode: 'transparent',
    });

    this.wistia.hasData((data) => this.processWistiaData(data));
    this.wistia.bind('play', (data) => this.onWistiaPlay(data));
    this.wistia.bind('pause', (data) => this.onWistiaPause(data));
    this.wistia.bind('ended', (data) => this.onWistiaEnded(data));
    this.wistia.bind('timechange', (data) => this.onWistiaTimechange(data));
  }

  play() {
    this.wistia.play();
  }

  pause() {
    this.wistia.pause();
  }

  setMuted(muted) {
    if (muted) {
      this.wistia.volume(0);
    } else {
      this.wistia.volume(this.props.volume / 100.0);
    }

    this.props.muted = muted;
    this.options.callbacks.volumeChanged(this.props.volume, this.props.muted);
  }

  seekToLivePosition() {
    console.warn('Not implemented!');
  }

  // eslint-disable-next-line no-unused-vars
  setSubtitleTrack(track) {
    console.warn('Not implemented!');
  }

  set volume(value) {
    this.props.volume = value;
    this.props.muted = false;
    this.wistia.volume(this.props.volume / 100.0);

    this.options.callbacks.volumeChanged(this.props.volume, this.props.muted);
  }

  get currentTime() {
    return this.props.currentTime;
  }

  set currentTime(value) {
    this.props.currentTime = value;
    this.wistia.time(value / 1000.0);
  }

  get duration() {
    return this.props.duration;
  }

  set playbackRate(value) {
    this.wistia.playbackRate(value);

    this.props.playbackRate = value;
    this.options.callbacks.playbackRateChanged(this.props.playbackRate);
  }

  // General

  get inLivePosition() {
    return false;
  }

  get ready() {
    return this.props.ready;
  }

  get loading() {
    return this.props.loading;
  }

  set loading(value) {
    if (value === this.props.loading) {
      return;
    }

    this.props.loading = value;
    this.options.callbacks.loadingChanged(value);
  }

  get state() {
    return this.props.state;
  }

  set state(value) {
    if (value === this.props.state) {
      return;
    }

    this.props.state = value;
    this.options.callbacks.stateChanged(value);
  }

  // Internal

  updateAvailablePlaybackRates() {
    this.props.availablePlaybackRates = [1, 1.25, 1.5, 1.75, 2];
    this.options.callbacks.availablePlaybackRatesChanged(this.props.availablePlaybackRates);
  }

  processWistiaData(data) {
    log('WISTIA', 'data', data);

    this.updateAvailablePlaybackRates();

    this.playbackRate = 1;

    this.props.duration = Math.floor(this.wistia.duration() * 1000);

    if (this.props.muted) {
      this.wistia.volume(0);
    } else {
      this.wistia.volume(this.props.volume);
    }

    this.options.callbacks.volumeChanged(this.props.volume, this.props.muted);

    this.loading = false;
    this.state = 'paused';

    defer(() => {
      this.props.ready = true;
      this.options.callbacks.ready();
    });
  }

  onWistiaPlay(data) {
    if (!data) {
      return;
    }

    if (!this.options.autoPlay && data.source === 'non-user-event') {
      log('WISTIA', 'skip play', data);
      return;
    }

    log('WISTIA', 'play', data);

    this.loading = false;
    this.state = 'playing';
  }

  onWistiaPause(data) {
    if (!data) {
      return;
    }

    if (!this.options.autoPlay && data.source === 'non-user-event') {
      log('WISTIA', 'skip pause', data);
      return;
    }

    log('WISTIA', 'pause', data);

    this.loading = false;
    this.state = 'paused';
  }

  onWistiaEnded(data) {
    log('WISTIA', 'ended', data);

    this.loading = false;
    this.state = 'paused';

    this.options.callbacks.ended();
  }

  onWistiaTimechange(data) {
    log('WISTIA', 'timechange', data);

    this.props.duration = Math.floor(this.wistia.duration() * 1000);
    this.props.currentTime = Math.floor(data * 1000);
  }
}
