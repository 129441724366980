import shaka from 'shaka-player';

function shakaErrorCategoryName(error) {
  for (const k in shaka.util.Error.Category) {
    if (shaka.util.Error.Category[k] === error.category) {
      return k;
    }
  }

  return `UNKNOWN (${error.category})`;
}

export default shakaErrorCategoryName;
