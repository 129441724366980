function isMobileDevice() {
  if (navigator.userAgent.match(/(iPhone|iPod|iPad)/i)) {
    return true;
  }

  if (navigator.userAgent.match(/(BlackBerry)/i)) {
    return true;
  }

  if (navigator.userAgent.match(/(Android)/i)) {
    return true;
  }

  return false;
}

export default isMobileDevice;
