function createSegmentStatsFromNetworkResponse(response) {
  const uriParts = response.uri.split('/');
  const nameParts = uriParts[uriParts.length - 1].split(/[_.]/);
  const name = `${nameParts[0]}_${nameParts[1]}`;

  return {
    name,
    stats: {
      uri: response.uri,
      duration: response.timeMs,
      size: response.data.byteLength,
      speed: response.data.byteLength / (response.timeMs / 1000.0),
      fromCache: !!response.fromCache,
    },
  };
}

export default createSegmentStatsFromNetworkResponse;
