import cs from 'modules/player/localizations/cs';
import de from 'modules/player/localizations/de';
import en from 'modules/player/localizations/en';

export default function playerI18n(locale) {
  if (locale === 'cs') return cs;
  if (locale === 'de') return de;

  return en;
}
