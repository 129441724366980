import formatTime from 'modules/player/format_time';
import cs from 'modules/player/localizations/cs';
import de from 'modules/player/localizations/de';
import en from 'modules/player/localizations/en';
import log from 'modules/player/log';
import icon from 'modules/player/view/icons/icons';

import hide from 'plugins/element/hide';
import inlineCss from 'plugins/element/inline_css';
import isElement from 'plugins/element/is_element';
import isVisible from 'plugins/element/is_visible';
import remove from 'plugins/element/remove';
import show from 'plugins/element/show';
import getSpinnerInContainerAsElement from 'plugins/spinner/get_spinner_in_container_as_element';
import stimulus, { FUSE_TOOLTIP_CONTROLLER } from 'plugins/stimulus';
import toCamelCase from 'plugins/string/to_camel_case';
import addListener from 'plugins/utilities/add_listener';
import isArray from 'plugins/utilities/is_array';
import isObject from 'plugins/utilities/is_object';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';
import removeListener from 'plugins/utilities/remove_listener';

import BookmarksModal from './bookmarks_modal';
import BookmarksPopup from './bookmarks_popup';
// eslint-disable-next-line import/no-unresolved
import clickToLoadHtml from './click_to_load.html?raw';
import Controls from './controls';
// eslint-disable-next-line import/no-unresolved
import errorHtml from './error.html?raw';
import Fullscreen from './fullscreen';
import './images/slides-title-logo.png';
// eslint-disable-next-line import/no-unresolved
import livestreamFinishedHtml from './livestream_finished.html?raw';
import Menu from './menu';
// eslint-disable-next-line import/no-unresolved
import playerHtml from './player.html?raw';
import PlaylistsModal from './playlists_modal';
// eslint-disable-next-line import/no-unresolved
import processingHtml from './processing.html?raw';
import Progressbar from './progressbar';
import ReviewNotePopup from './review_note_popup';
import './styles/index.css';
import VolumePanel from './volume_panel';
import ZoomSlider from './zoom_slider';

const VIDEO_SIZE_CLASS_BREAKPOINTS = [195, 275, 350, 400, 450, 500];
const SLIDES_SIZE_CLASS_BREAKPOINTS = [140, 250, 330, 420];

export default class Html {
  constructor(element, options, callbacks) {
    this.element = element;
    this.options = options;
    this.callbacks = callbacks;

    this.elements = {};

    this.props = {
      mode: this.options.mode,
      duration: 0,
      isVertical: false,
      debugInfo: {},
      slidesLinkifyTitle: true,
      slidesTitle: null,
      slidesTitleLink: null,
      classes: new Set(),

      size: null,

      subtitlesFontSize: 0.025,
      subtitlesWords: [],
      maxSubtitlesLines: 2,
      subtitlesWordsTimeoutDelay: 8000,
      subtitlesWordsTimeout: null,

      actionListenerId: null,
      actionWithParamListenerId: null,
    };
  }

  addActionListener() {
    this.props.actionListenerId = addListener(
      this.rootElement,
      'click',
      '[data-slp-action]:not([disabled])',
      (event) => {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();

        const target = event.selectorTarget;
        const action = target.getAttribute('data-slp-action');

        this.callbacks.actionCallback(action);

        if (document.activeElement.nodeName === 'BODY') {
          this.rootElement.focus();
        }
      },
    );
  }

  addActionWithParamListener() {
    this.props.actionWithParamListenerId = addListener(
      this.rootElement,
      'click',
      '[data-slp-action-w-param]:not([disabled])',
      (event) => {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();

        const target = event.selectorTarget;
        const action = target.getAttribute('data-slp-action-w-param');
        const param = target.getAttribute('data-slp-action-param');

        this.callbacks.actionWithParamCallback(action, param);

        if (document.activeElement.nodeName === 'BODY') {
          this.rootElement.focus();
        }
      },
    );
  }

  renderClickToLoad() {
    this.element.innerHTML = clickToLoadHtml;

    this.toggleClasses();
    this.initializeMemoizedElements();
    this.initializeIcons();
  }

  renderPlayer() {
    this.element.innerHTML = playerHtml;

    this.toggleClasses();
    this.initializeMemoizedElements();
    this.initializeIcons();
    this.initializeLocalization();
    this.initializeVolumePanel();
    this.initializeProgressbar();
    this.initializeSettings();
    this.initializeControlsVisibility();
    this.initializeZoomSlider();
    this.initializeFullscreen();
    this.initializeModals();
    this.initializePopups();

    this.updateAvailableSubtitlesSizes();

    this.mode = this.props.mode;

    if (window.parent === window) {
      this.rootElement.focus();
    }
  }

  renderError(error) {
    this.element.innerHTML = errorHtml;

    this.toggleClasses();
    this.initializeMemoizedElements();
    this.initializeIcons();
    this.findTarget('errorText').innerHTML = error;
  }

  renderProcessing(text) {
    this.element.innerHTML = processingHtml;

    this.toggleClasses();
    this.initializeMemoizedElements();
    this.initializeIcons();
    if (isVariableDefinedNotNull(text)) {
      this.findTarget('processingText').innerHTML = text;
    }
  }

  renderLivestreamFinished() {
    this.element.innerHTML = livestreamFinishedHtml;

    this.toggleClasses();
    this.initializeMemoizedElements();
    this.initializeIcons();
  }

  initializeVolumePanel() {
    this.volumePanel = new VolumePanel(
      this.findTarget('volumePanel'),
      this.findTarget('volumeSlider'),
      this.findTarget('volumeBar'),
      this.findTarget('volumeBarCurrent'),
      (volume) => this.callbacks.actionWithParamCallback('volume', volume),
    );
  }

  initializeProgressbar() {
    this.progressbar = new Progressbar(
      this.findTarget('progressbar'),
      this.findTarget('progressbarPadding'),
      this.findTarget('progressbarCurrent'),
      this.findTarget('progressbarMouse'),
      this.findTarget('progressbarBookmarks'),
      this.findTarget('progressbarReviewNotes'),
      (time) => this.callbacks.actionWithParamCallback('startSeek', time),
      (time) => this.callbacks.actionWithParamCallback('updateSeek', time),
      (time) => this.callbacks.actionWithParamCallback('endSeek', time),
      this.callbacks.progressImageUrlCallback,
      this.initializeLocalization.bind(this),
    );
  }

  initializeSettings() {
    this.settings = new Menu(this.findTarget('settingsButton'), this.findTarget('settingsMenu'), 'root', (visible) =>
      this.menuChangeCallback(visible),
    );

    this.liveSlideVideoSettings = new Menu(
      this.findTarget('liveSlidesVideoSettingsButton'),
      this.findTarget('liveSlidesVideoSettingsMenu'),
      'liveSlidesVideoMenuRoot',
      (visible) => this.menuChangeCallback(visible),
    );
  }

  initializeControlsVisibility() {
    this.controls = new Controls(
      this.findTarget('media'),
      {
        allowHiddenControlsWhenPaused: this.options.allowHiddenControlsWhenPaused,
      },
      (shouldShow) => this.toggleControls(shouldShow),
    );

    this.controls.visible = true;

    this.toggleClass('hideBigPlayButton', this.options.hideBigPlayButton);
  }

  initializeZoomSlider() {
    this.zoomSlider = new ZoomSlider(this.findTarget('zoomSlider'), {
      defaultRatio: this.options.zoomRatio,
      sliderColor: this.options.zoomSliderColor,
      handleColor: this.options.zoomHandleColor,
      handleIcon: icon('player-zoom'),
      callbacks: {
        zoomChanged: (zoom) => this.callbacks.actionWithParamCallback('setZoom', zoom),
        click: (zoom) => log('VIEW', 'zoom click', zoom),
      },
    });

    if (this.options.hideZoomControls) {
      this.toggleClass('zoomControlsHidden', this.options.hideZoomControls);
    }
  }

  initializeFullscreen() {
    if (this.options.disableFullscreen) {
      this.disableFullscreen();
    }

    this.fullscreen = new Fullscreen(this.element, (active) => this.updateFullscreen(active));
  }

  initializeModals() {
    this.playlistsModal = new PlaylistsModal(this.findTarget('playlistsModal'), {
      callbacks: {
        change: (visible) => this.modalChangeCallback(visible),
        addPlaylist: (name) => this.callbacks.actionWithParamCallback('addPlaylist', name),
      },
    });

    this.bookmarksModal = new BookmarksModal(
      this.findTarget('bookmarksModal'),
      {
        icons: {
          bookmark: icon('player-bookmark'),
          edit: icon('player-edit'),
          save: icon('player-save'),
          remove: icon('player-delete'),
        },
        callbacks: {
          change: (visible) => this.modalChangeCallback(visible),
          update: (data) => this.callbacks.actionWithParamCallback('updateBookmark', data),
          remove: (id) => this.callbacks.actionWithParamCallback('removeBookmark', id),
          seekTo: (time) => this.callbacks.actionWithParamCallback('seekTo', time),
        },
      },
      this.initializeLocalization.bind(this),
      this.setNewLocalizationOnTarget.bind(this),
    );
  }

  initializePopups() {
    this.bookmarksPopup = new BookmarksPopup(this.findTarget('bookmarksPopup'), {
      callbacks: {
        change: (visible) => this.popupChangeCallback(visible),
        update: (data) => this.callbacks.actionWithParamCallback('updateBookmark', data),
        remove: (id) => this.callbacks.actionWithParamCallback('removeBookmark', id),
      },
    });

    this.reviewNotePopup = new ReviewNotePopup(this.findTarget('reviewNotePopup'), {
      callbacks: {
        change: (visible) => this.popupChangeCallback(visible),
        resolveReviewNote: (data) => this.callbacks.actionWithParamCallback('resolveReviewNote', data),
        sendReviewNoteComment: (data) => this.callbacks.actionWithParamCallback('sendReviewNoteComment', data),
      },
    });
  }

  menuChangeCallback(visible) {
    this.controls.menuOpen = visible;

    if (visible) {
      this.bookmarksPopup.close();
      this.reviewNotePopup.close();
      this.bookmarksModal.close();
      this.playlistsModal.close();
      this.progressbar.disableTooltip();
    } else {
      this.progressbar.enableTooltip();
      this.rootElement.focus();
    }
  }

  modalChangeCallback(visible) {
    this.controls.modalOpen = visible;

    if (visible) {
      this.settings.close();
      this.liveSlideVideoSettings.close();
      this.bookmarksPopup.close();
      this.reviewNotePopup.close();
      this.progressbar.disableTooltip();
    } else {
      this.progressbar.enableTooltip();
      this.rootElement.focus();
    }
  }

  popupChangeCallback(visible) {
    if (!visible) {
      this.rootElement.focus();
      return;
    }

    this.settings.close();
    this.liveSlideVideoSettings.close();
    this.bookmarksModal.close();
    this.playlistsModal.close();
  }

  toggleControls(shouldShow) {
    this.toggleClass('controlsVisible', shouldShow);

    if (!shouldShow) {
      this.settings.close();
      this.liveSlideVideoSettings.close();
    }

    this.callbacks.controlsVisibilityChanged(shouldShow);
  }

  toggleVideoControlsInSlides(set) {
    const inSlides = this.hasClass('videoControlsInSlides');
    if (set === inSlides) {
      return;
    }

    this.toggleClass('videoControlsInSlides', set);

    const videoControlsGradient = this.findTarget('videoControlsGradient');
    const videoControls = this.findTarget('videoControls');
    const settingsMenu = this.findTarget('settingsMenu');
    const playlistModal = this.findTarget('playlistsModal');
    const bookmarksModal = this.findTarget('bookmarksModal');
    const bookmarksPopup = this.findTarget('bookmarksPopup');
    const reviewNotePopup = this.findTarget('reviewNotePopup');
    const subtitles = this.findTarget('subtitles');

    if (set) {
      this.slidesElement.appendChild(videoControlsGradient);
      this.slidesElement.appendChild(videoControls);
      this.slidesElement.appendChild(settingsMenu);
      this.slidesElement.appendChild(playlistModal);
      this.slidesElement.appendChild(bookmarksModal);
      this.slidesElement.appendChild(bookmarksPopup);
      this.slidesElement.appendChild(reviewNotePopup);
      this.slidesElement.appendChild(subtitles);
    } else {
      this.videoElement.appendChild(videoControlsGradient);
      this.videoElement.appendChild(videoControls);
      this.videoElement.appendChild(settingsMenu);
      this.videoElement.appendChild(playlistModal);
      this.videoElement.appendChild(bookmarksModal);
      this.videoElement.appendChild(bookmarksPopup);
      this.videoElement.appendChild(reviewNotePopup);
      this.slidesElement.appendChild(subtitles);
    }
  }

  toggleClasses() {
    for (const className of this.props.classes) {
      this.toggleClass(className, true);
    }
  }

  toggleClass(className, on) {
    if (on) {
      this.props.classes.add(className);
    } else {
      this.props.classes.delete(className);
    }

    if (this.rootElement) {
      const fullClassName = `slp--${className}`;
      if (this.rootElement.classList.contains(fullClassName) !== on) {
        log('VIEW', className, on);

        this.rootElement.classList.toggle(fullClassName, on);
      }
    }
  }

  hasClass(className) {
    if (!this.rootElement) {
      return false;
    }

    const fullClassName = `slp--${className}`;
    return this.rootElement.classList.contains(fullClassName);
  }

  setData(key, value) {
    const dataAttribute = `data-${key}`;

    if (isVariableDefinedNotNull(value)) {
      this.rootElement.setAttribute(dataAttribute, value);
    } else {
      this.rootElement.removeAttribute(dataAttribute);
    }
  }

  setSize(size) {
    const setElementPixelSize = (element, width, height, top = 0) => {
      if (!isVariableDefinedNotNull(element)) {
        return;
      }

      const styles = {
        width: `${width}px`,
        height: `${height}px`,
      };

      if (this.isVertical) {
        styles.top = `${top}px`;
      } else if (isVariableDefinedNotNull(top)) {
        styles.top = 0;
      }

      inlineCss(element, styles);
    };

    const setSettingsMenuMaxPixelSize = (menu, width, height) => {
      const maxWidth = width - 10 * 2;
      const maxHeight = height - 53 - 10;

      if (!isVariableDefinedNotNull(menu)) {
        return;
      }

      inlineCss(menu, {
        'max-width': `${maxWidth}px`,
        'max-height': `${maxHeight}px`,
      });
    };

    this.props.size = size;
    this.isVertical = !!size.vertical;
    this.toggleOrientationClasses();

    setElementPixelSize(this.rootElement, size.width, size.height);
    setElementPixelSize(this.videoElement, size.videoWidth, size.videoHeight);
    setElementPixelSize(this.slidesElement, size.slidesWidth, size.slidesHeight, size.spacing);

    setSettingsMenuMaxPixelSize(this.findTarget('settingsMenu'), size.videoWidth, size.videoHeight);
    setSettingsMenuMaxPixelSize(this.findTarget('liveSlidesVideoSettingsMenu'), size.slidesWidth, size.slidesHeight);

    this.toggleVideoSizeClasses(size);
    this.toggleSlidesSizeClasses(size);
  }

  toggleOrientationClasses() {
    this.toggleClass('vertical', this.isVertical);
  }

  toggleVideoSizeClasses(size) {
    const sizeClassPrefix = 'videoSizeLt-';
    const videoWidth = this.hasClass('videoControlsInSlides') ? size.slidesWidth : size.videoWidth;

    for (const widthBreakpoint of VIDEO_SIZE_CLASS_BREAKPOINTS) {
      const sizeClass = sizeClassPrefix + widthBreakpoint;
      this.toggleClass(sizeClass, videoWidth < widthBreakpoint);
    }
  }

  toggleSlidesSizeClasses(size) {
    const sizeClassPrefix = 'slidesSizeLt-';

    for (const widthBreakpoint of SLIDES_SIZE_CLASS_BREAKPOINTS) {
      const sizeClass = sizeClassPrefix + widthBreakpoint;
      this.toggleClass(sizeClass, size.slidesWidth < widthBreakpoint);
    }
  }

  findTarget(target) {
    const parent = isVariableDefinedNotNull(this.rootElement) ? this.rootElement : this.element;
    return parent.querySelector(`[data-slp-target="${target}"]`);
  }

  setIconOnTarget(target, iconName) {
    target = isElement(target) ? target : this.findTarget(target);

    if (!target) return;

    target.innerHTML = icon(iconName);
  }

  setNewLocalizationOnTarget(target, titleKey) {
    target = isElement(target) ? target : this.findTarget(target);

    if (!target) return;

    let dictionary;
    if (this.options.locale === 'cs') {
      dictionary = cs;
    } else if (this.options.locale === 'de') {
      dictionary = de;
    } else {
      dictionary = en;
    }

    const title = dictionary[titleKey] || titleKey;

    if (stimulus.hasTarget(target, { [FUSE_TOOLTIP_CONTROLLER]: 'item' })) {
      stimulus.setControllerDataValue(target, { [FUSE_TOOLTIP_CONTROLLER]: { content: title } });
    }
  }

  setActionParamOnTarget(target, value) {
    target = isElement(target) ? target : this.findTarget(target);
    target.setAttribute('data-slp-action-param', value);
  }

  initializeIcons() {
    for (const el of this.rootElement.querySelectorAll('[data-slp-icon]')) {
      const iconName = el.getAttribute('data-slp-icon');
      el.innerHTML = icon(iconName);
    }
  }

  get localizationDictionary() {
    let dictionary;
    if (this.options.locale === 'cs') {
      dictionary = cs;
    } else if (this.options.locale === 'de') {
      dictionary = de;
    } else {
      dictionary = en;
    }

    return dictionary;
  }

  initializeLocalization(element = this.rootElement) {
    const dictionary = this.localizationDictionary;

    for (const el of element.querySelectorAll('[data-slp-title]')) {
      const titleKey = el.getAttribute('data-slp-title');
      const title = dictionary[titleKey] || titleKey;

      const placement = el.getAttribute('data-slp-title-placement') || 'top';
      stimulus.set(el, {
        target: { [FUSE_TOOLTIP_CONTROLLER]: 'item' },
        controllerDataValue: {
          [FUSE_TOOLTIP_CONTROLLER]: {
            content: title,
            appendTo: '[data-slp-target="rootElement"]',
            boundary: '[data-slp-target="rootElement"]',
            placement,
            hideArrow: true,
            classes: 'tw-text-md tw-bg-opacity-89 tw-rounded-sm tw-border-none',
          },
        },
      });
    }

    for (const el of element.querySelectorAll('[data-slp-localized]')) {
      const dictionaryKey = el.getAttribute('data-slp-localized');
      if (isVariableDefinedNotNull(dictionaryKey)) {
        el.innerHTML = dictionary[dictionaryKey];
      }
    }

    for (const el of element.querySelectorAll('[placeholder]')) {
      const dictionaryKey = el.getAttribute('placeholder');
      if (isVariableDefinedNotNull(dictionaryKey)) {
        el.placeholder = dictionary[dictionaryKey];
      }
    }
  }

  initializeMemoizedElements() {
    this.elements = {};

    this.elements = {
      rootElement: this.findTarget('rootElement'),
      videoElement: this.findTarget('video'),
      videoContentElement: this.findTarget('videoElement'),
      slidesElement: this.findTarget('slides'),
      slidesContentElement: this.findTarget('slidesElement'),
      videoControlsElement: this.findTarget('videoControls'),
      slidesControlsElement: this.findTarget('slidesControls'),
      liveSlidesVideoControlsElement: this.findTarget('liveSlidesVideoControls'),
    };

    this.addActionListener();
    this.addActionWithParamListener();
  }

  updateTime(currentTime, duration) {
    if (duration < currentTime) {
      duration = currentTime;
    }

    this.props.duration = duration;

    const currentTimeString = formatTime(currentTime / 1000);
    const durationString = formatTime(duration / 1000);

    const currentTimeElement = this.findTarget('currentTime');
    const durationElement = this.findTarget('duration');

    currentTimeElement.innerHTML = currentTimeString;
    durationElement.innerHTML = durationString;

    this.progressbar.setProgress(currentTime, duration);
  }

  updatePlayingIcon(playing) {
    const iconName = playing ? 'player-pause' : 'player-play';
    this.setIconOnTarget('play', iconName);

    this.controls.playing = playing;

    if (this.props.subtitlesWordsTimeout) {
      clearTimeout(this.props.subtitlesWordsTimeout);
      this.props.subtitlesWordsTimeout = null;
    }

    if (playing) {
      this.props.subtitlesWordsTimeout = setTimeout(
        () => this.renderSubtitlesWord(null),
        this.props.subtitlesWordsTimeoutDelay,
      );
    }
  }

  updateVolumeIcon(volume, muted) {
    let iconName;
    if (muted) {
      iconName = 'player-mute';
    } else if (volume === 0) {
      iconName = 'player-volume-off';
    } else if (volume > 77) {
      iconName = 'player-volume-max';
    } else {
      iconName = 'player-volume-min';
    }
    this.setIconOnTarget('volume', iconName);
  }

  updateVolumeBar(volume, muted) {
    if (muted) {
      this.volumePanel.setVolumeDisplay(0);
    } else {
      this.volumePanel.setVolumeDisplay(volume);
    }
  }

  updateSlideMutedIcon(muted) {
    const iconName = muted ? 'player-mute' : 'player-volume-max';
    this.setIconOnTarget('slideVolume', iconName);
  }

  updateSlideMutedTitle(muted) {
    const titleKey = muted ? 'unmuteSlide' : 'muteSlide';
    this.setNewLocalizationOnTarget('slideVolume', titleKey);
  }

  submenuArrayToHtmlData(data, action) {
    const submenuData = [];
    for (const entry of data) {
      if (isArray(entry)) {
        submenuData.push({
          title: entry[0],
          action,
          param: entry[1],
        });
      } else if (isObject(entry) && isVariableDefinedNotNull(entry.name) && isVariableDefinedNotNull(entry.key)) {
        submenuData.push({
          title: entry.name,
          action,
          param: entry.key,
        });
      } else {
        submenuData.push({
          title: entry,
          action,
          param: entry,
        });
      }
    }

    return submenuData;
  }

  updateAvailablePlaybackRates(playbackRates) {
    if (playbackRates.length > 0 && isObject(playbackRates[0])) {
      const submenuData = this.submenuArrayToHtmlData(playbackRates, 'setPlaybackRate');
      this.settings.setSubmenuContent('playbackRate', submenuData);
    } else {
      const submenuData = [];
      for (const playbackRate of playbackRates) {
        submenuData.push({
          title: `${playbackRate} &times;`,
          action: 'setPlaybackRate',
          param: playbackRate,
        });
      }

      if (submenuData.length > 1) {
        this.settings.setSubmenuContent('playbackRate', submenuData);
      } else {
        this.settings.disableSubmenu('playbackRate');
      }
    }
  }

  updateAvailableQualities(qualities) {
    const submenuData = this.submenuArrayToHtmlData(qualities, 'setVideoQuality');
    this.settings.setSubmenuContent('quality', submenuData);
  }

  updateAvailableServers(servers) {
    const submenuData = this.submenuArrayToHtmlData(servers, 'setPlaybackServer');
    this.settings.setSubmenuContent('server', submenuData);
  }

  updateAvailableSubtitles(tracks) {
    if (tracks.length > 0 && tracks[0].key) {
      const submenuData = this.submenuArrayToHtmlData(tracks, 'setSubtitleTrack');
      this.settings.setSubmenuContent('subtitles', submenuData);
    } else {
      const submenuData = [];
      for (let i = 0; i < tracks.length; ++i) {
        const track = tracks[i];

        submenuData.push({
          title: track.name,
          action: 'setSubtitleTrack',
          param: i,
        });
      }

      if (submenuData.length > 1) {
        this.settings.setSubmenuContent('subtitles', submenuData);
      } else {
        this.settings.disableSubmenu('subtitles');
      }
    }
  }

  updateAvailableLiveSlideVideoQualities(qualities) {
    const submenuData = this.submenuArrayToHtmlData(qualities, 'setLiveSlidesVideoQuality');
    this.liveSlideVideoSettings.setSubmenuContent('liveSlidesVideoMenuQuality', submenuData);
  }

  updateAvailableLiveSlideVideoServers(servers) {
    const submenuData = this.submenuArrayToHtmlData(servers, 'setLiveSlidesPlaybackServer');
    this.liveSlideVideoSettings.setSubmenuContent('liveSlidesVideoMenuServer', submenuData);
  }

  updateAvailableSubtitlesSizes() {
    const submenuData = this.submenuArrayToHtmlData(
      [
        { name: this.localizationDictionary.subtitlesSizeLarge, key: '0.035' },
        { name: this.localizationDictionary.subtitlesSizeMedium, key: '0.025' },
        { name: this.localizationDictionary.subtitlesSizeSmall, key: '0.02' },
      ],
      'setSubtitlesSize',
    );

    this.settings.setSubmenuContent('subtitlesSize', submenuData);
    requestAnimationFrame(() => this.settings.setActiveItem('subtitlesSize', this.props.subtitlesFontSize.toString()));
  }

  updateSlide(slideIndex, slideCount, slideData) {
    const { time } = slideData;
    const currentSlideTarget = this.findTarget('currentSlide');
    if (!currentSlideTarget) return;

    currentSlideTarget.innerHTML = slideIndex + 1;
    this.findTarget('slideCount').innerHTML = slideCount;

    const timePct = (time / this.props.duration) * 100;
    this.findTarget('progressbarSlide').style.left = `${timePct}%`;
  }

  updatePointer({ time, x, y }) {
    const slidesPointer = this.findTarget('slidesPointer');

    if (!isVariableDefinedNotNull(time)) {
      slidesPointer.style.display = 'none';
    } else {
      slidesPointer.style.display = 'block';
      slidesPointer.style.left = `${x * 100.0}%`;
      slidesPointer.style.top = `${y * 100.0}%`;
    }
  }

  toggleFullscreen() {
    this.fullscreen.toggleFullscreen();
  }

  updateFullscreen(active) {
    const fullscreenIcon = active ? 'player-minimize' : 'player-fullscreen';
    this.setIconOnTarget('fullscreenButton', fullscreenIcon);
    this.toggleClass('fullscreen', active);

    if (active) {
      this.element.style.backgroundColor = '#000';
    } else {
      this.element.style.backgroundColor = null;
    }

    this.callbacks.actionWithParamCallback('fullscreenChanged', active);
  }

  updatePlaylistsList(list, presentationPlaylists) {
    for (let i = 0; i < list.length; i++) {
      if (list[i].canonical_name) {
        let dictionary;
        if (this.options.locale === 'cs') {
          dictionary = cs;
        } else if (this.options.locale === 'de') {
          dictionary = de;
        } else {
          dictionary = en;
        }

        list[i].name = dictionary[toCamelCase(list[i].canonical_name)] || list[i].name;
      }
    }

    this.playlistsModal.updatePlaylistsList(list, presentationPlaylists);
  }

  updatePlaylist(name, added) {
    if (['favorite', 'watch-later'].includes(name)) {
      this.updatePredefinedPlaylist(name, added);
    }

    this.playlistsModal.updatePlaylist(name, added);
  }

  updatePredefinedPlaylist(name, added) {
    const targetName = `${toCamelCase(name)}Button`;
    const target = this.findTarget(targetName);
    let iconName;
    let titleKey;

    if (!target) {
      return;
    }

    if (name === 'favorite') {
      iconName = 'player-favorite';
      titleKey = added ? 'removeFavorite' : 'addFavorite';
    } else if (name === 'watch-later') {
      iconName = 'player-watch-later';
      titleKey = added ? 'removeWatchLater' : 'addWatchLater';
    }

    if (!added) {
      iconName += '-outline';
    }

    this.setActionParamOnTarget(target, !added);
    this.setIconOnTarget(target, iconName);
    this.setNewLocalizationOnTarget(target, titleKey);
  }

  togglePlaylistLoading(name, shouldShow) {
    if (['favorite', 'watch-later'].includes(name)) {
      this.togglePredefinedPlaylistLoading(name, shouldShow);
    }

    this.playlistsModal.setPlaylistLoading(name, shouldShow);
  }

  togglePredefinedPlaylistLoading(name, shouldShow) {
    this.toggleButtonLoading(`${toCamelCase(name)}Button`, shouldShow);
  }

  updateBookmarksList(list) {
    this.progressbar.setBookmarks(list);
    this.bookmarksModal.updateBookmarksList(list);
  }

  updateBookmark(data, list) {
    this.progressbar.setBookmarks(list);
    this.bookmarksModal.updateBookmark(data);
  }

  removeBookmark(id, list) {
    this.progressbar.setBookmarks(list);
    this.bookmarksModal.removeBookmark(id);
  }

  toggleBookmarkLoading(id, shouldShow) {
    this.bookmarksModal.setBookmarkLoading(id, shouldShow);
  }

  showBookmarksPopup(id) {
    this.bookmarksPopup.open(id);
  }

  updateReviewNotes(reviewNotes) {
    this.progressbar.setReviewNotes(reviewNotes);
    this.reviewNotePopup.updateOpenedReviewNote(reviewNotes);
  }

  openReviewNote(reviewNote) {
    this.reviewNotePopup.open(reviewNote);
  }

  showNextPresentationOverlay() {
    this.toggleClass('nextPresentation', true);
  }

  hideNextPresentationOverlay() {
    this.toggleClass('nextPresentation', false);
  }

  hideOpenedOverlays() {
    this.settings.close();
    this.liveSlideVideoSettings.close();
    this.playlistsModal.close();
    this.bookmarksModal.close();
    this.bookmarksPopup.close();
    this.reviewNotePopup.close();
  }

  toggleButtonLoading(buttonTargetName, loading) {
    const target = this.findTarget(buttonTargetName);
    if (!target) {
      return;
    }

    if (!loading) {
      if (isVariableDefinedNotNull(target.spinnerElement)) {
        remove(target.spinnerElement);
      }

      target.disabled = false;
      return;
    }

    if (!isVariableDefinedNotNull(target.spinnerElement)) {
      target.spinnerElement = getSpinnerInContainerAsElement({ tiny: true, absoluteWrapper: true });
    }

    target.disabled = true;
    target.insertAdjacentElement('afterbegin', target.spinnerElement);
  }

  toggleTopicsAndTranscriptLoading(loading) {
    this.toggleButtonLoading('topicsAndTranscriptButton', loading);
  }

  toggleDebugInfo() {
    const debugTarget = this.findTarget('debug');
    if (isVisible(debugTarget)) {
      hide(debugTarget);
    } else {
      show(debugTarget);
    }
  }

  updateDebugInfo(info, showDebugInfoElement) {
    if (showDebugInfoElement) show(this.findTarget('debug'));

    this.props.debugInfo = {
      ...this.props.debugInfo,
      ...info,
    };

    const debugList = this.findTarget('debugList');
    if (!debugList) return;

    const debugInfoListItems = [];

    for (const [key, value] of Object.entries(this.props.debugInfo)) {
      debugInfoListItems.push(`<li>${key}: ${value}</li>`);
    }

    debugList.innerHTML = debugInfoListItems.join('');
  }

  renderSubtitlesWord(text) {
    if (!isVariableDefinedNotNull(text)) {
      this.props.subtitlesWords = [];
    } else {
      this.props.subtitlesWords.push(text);
    }

    this._renderSubtitles();

    if (this.props.subtitlesWordsTimeout) {
      clearTimeout(this.props.subtitlesWordsTimeout);
    }

    this.props.subtitlesWordsTimeout = setTimeout(
      () => this.renderSubtitlesWord(null),
      this.props.subtitlesWordsTimeoutDelay,
    );
  }

  _renderSubtitles() {
    const inSlides = this.hasClass('videoControlsInSlides');
    const baseWidth = inSlides ? this.props.size.slidesWidth : this.props.size.videoWidth;

    const subtitlesTarget = this.findTarget('subtitles');
    subtitlesTarget.hidden = this.props.subtitlesWords.length === 0;

    const fontSize = baseWidth * this.props.subtitlesFontSize;
    const maxLineCharacterCount = Math.max(20, (this.findTarget('subtitles').clientWidth - 20) / (fontSize * 0.55));
    const backgroundColor = 'rgba(8, 8, 8, 0.75)';

    const lines = [];

    for (const word of this.props.subtitlesWords) {
      if (lines.length === 0 || lines[lines.length - 1].length + word.length > maxLineCharacterCount) {
        lines.push('');
      }

      lines[lines.length - 1] += `${word} `;
    }

    if (lines.length > this.props.maxSubtitlesLines) {
      lines.splice(0, lines.length - this.props.maxSubtitlesLines);
    }

    if (this.props.subtitlesWords > 100) {
      this.props.subtitlesWords.splice(0, this.props.subtitlesWords.length - 100);
    }

    subtitlesTarget.style = `font-size: ${fontSize}px;`;
    subtitlesTarget.innerHTML = lines
      .map(
        (line) =>
          `<div class="slp__subtitles__line"><div class="slp__subtitles__lineContent" style="font-size: ${fontSize}px; background-color: ${backgroundColor}">${line}</div></div>`,
      )
      .join('');
  }

  destroy() {
    if (this.props.actionListenerId) {
      removeListener(this.rootElement, { id: this.props.actionListenerId });
    }

    if (this.props.actionWithParamListenerId) {
      removeListener(this.rootElement, { id: this.props.actionWithParamListenerId });
    }

    this.element.innerHTML = '';
  }

  set mode(mode) {
    this.props.mode = mode;

    this.setData('mode', mode);
    this.settings.setActiveItem('mode', mode);

    const videoControlsInSlides = mode === 'slideshow' || mode === 'audio_slideshow';
    this.toggleVideoControlsInSlides(videoControlsInSlides);
  }

  set thumbnail(url) {
    this.findTarget('videoThumbnail').style.backgroundImage = `url(${url})`;
    this.findTarget('liveThumbnail').style.backgroundImage = `url(${url})`;
    this.findTarget('liveTerminatedThumbnail').style.backgroundImage = `url(${url})`;
    this.findTarget('notRecordedThumbnail').style.backgroundImage = `url(${url})`;
  }

  set clickToLoadThumbnail(url) {
    this.findTarget('clickToLoadThumbnail').style.backgroundImage = `url(${url})`;
  }

  set nextPresentationThumbnail(url) {
    this.findTarget('nextPresentationThumbnail').style.backgroundImage = `url(${url})`;
  }

  set realVideoRatio(value) {
    this.zoomSlider.realVideoRatio = value;
  }

  set displayVideoRatio(value) {
    this.zoomSlider.displayVideoRatio = value;
  }

  set slidesRatio(value) {
    this.zoomSlider.slidesRatio = value;
    this.progressbar.slideRatio = value;
  }

  get rootElement() {
    return this.elements.rootElement;
  }

  get videoElement() {
    return this.elements.videoElement;
  }

  get videoContentElement() {
    return this.elements.videoContentElement;
  }

  get slidesElement() {
    return this.elements.slidesElement;
  }

  get slidesContentElement() {
    return this.elements.slidesContentElement;
  }

  get fullscreenActive() {
    return this.fullscreen.active;
  }

  set quality(quality) {
    this.settings.setActiveItem('quality', quality);
  }

  set playbackRate(playbackRate) {
    this.settings.setActiveItem('playbackRate', playbackRate);
  }

  set playbackServerIndex(serverIndex) {
    this.settings.setActiveItem('server', serverIndex);
  }

  set activeSubtitle(track) {
    this.settings.setActiveItem('subtitles', track);
  }

  set liveSlideVideoQuality(quality) {
    this.liveSlideVideoSettings.setActiveItem('liveSlidesVideoMenuQuality', quality);
  }

  set liveSlideVideoPlaybackServerIndex(serverIndex) {
    this.liveSlideVideoSettings.setActiveItem('liveSlidesVideoMenuServer', serverIndex);
  }

  set subtitlesSize(subtitlesSize) {
    this.props.subtitlesFontSize = parseFloat(subtitlesSize);
    this._renderSubtitles();

    this.settings.setActiveItem('subtitlesSize', subtitlesSize);
  }

  set linkifyTitle(value) {
    this.props.slidesLinkifyTitle = value;
    this.updateSlidesTitle();
  }

  set linkifySlidesLiveLogo(value) {
    this.props.slidesLiveLogoLinkify = value;
    this.updateSlidesLiveLogo();
  }

  set title(title) {
    this.props.slidesTitle = title;
    this.updateSlidesTitle();
  }

  set link(link) {
    this.props.slidesTitleLink = link;
    this.updateSlidesTitle();

    this.props.slidesLiveLogoLink = link;
    this.updateSlidesLiveLogo();
  }

  set nextPresentationTitle(title) {
    this.findTarget('nextPresentationTitle').innerHTML = title;
  }

  set isVertical(isVertical) {
    this.props.isVertical = isVertical;
  }

  get isVertical() {
    return this.props.isVertical;
  }

  set slidesVideoStreamDiff(diff) {
    const target = this.findTarget('syncDiff');
    if (!target) return;

    if (Number.isFinite(diff)) {
      target.textContent = `${(diff / 1000).toFixed(3)} s`;
    } else {
      target.textContent = 'N/A';
    }
  }

  set reportIssueUrl(url) {
    const reportIssueTarget = this.findTarget('reportIssue');
    if (reportIssueTarget) reportIssueTarget.href = url;
  }

  updateSlidesTitle() {
    const el = this.findTarget('slidesTitle');

    if (this.props.slidesLinkifyTitle) {
      el.innerHTML = `<a href="${this.props.slidesTitleLink}" target="_blank">${this.props.slidesTitle}</a>`;
    } else {
      el.innerHTML = this.props.slidesTitle;
    }
  }

  updateSlidesLiveLogo() {
    const el = this.findTarget('slidesLiveLogo');

    if (this.props.slidesLiveLogoLinkify) {
      el.innerHTML = `<a href="${this.props.slidesLiveLogoLink}" target="_blank">SlidesLive</a>`;
    } else {
      el.innerHTML = 'SlidesLive';
    }
  }

  disableFullscreen() {
    this.toggleClass('fullscreenHidden', true);
  }
}
