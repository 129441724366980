import createUserMessageArray from './create_user_message_array';

function videoFormatNotSupportedUserMessage(presentationId) {
  return createUserMessageArray({
    presentationId,
    userMessage: 'Video format is not supported by your browser.',
    upgradeBrowser: true,
    report: true,
  });
}

export default videoFormatNotSupportedUserMessage;
