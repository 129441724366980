import log from 'modules/player/log';

import addListener from 'plugins/utilities/add_listener';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

export default class Modal {
  constructor(modal, options) {
    if (!isVariableDefinedNotNull(modal)) {
      return;
    }

    this.modal = modal;
    this.options = options;

    addListener(
      this.modal.parentNode.parentNode,
      'click',
      `[data-slp-open-modal="${this.modalName}"]:not([disabled])`,
      this.openFromEvent.bind(this),
    );
    addListener(this.modal, 'click', '[data-slp-close-modal="true"]:not([disabled])', this.close.bind(this));
  }

  fireChangeCallback() {
    const visible = this.modal.classList.contains('slp__modal--active');

    this.options.callbacks.change(visible);
  }

  close() {
    if (!isVariableDefinedNotNull(this.modal) || !this.modal.classList.contains('slp__modal--active')) {
      return;
    }

    log('MODAL', 'close', this.modalName);

    this.modal.classList.remove('slp__modal--active');
    this.fireChangeCallback();
  }

  openFromEvent(event) {
    event.preventDefault();

    this.open();
  }

  open() {
    if (!isVariableDefinedNotNull(this.modal)) {
      return;
    }

    log('MODAL', 'open', this.modalName);

    for (const modal of this.modal.parentNode.querySelectorAll('[data-slp-target$="Modal"]')) {
      const modalName = modal.getAttribute('data-slp-target');
      const show = this.modalName === modalName;

      modal.classList.toggle('slp__modal--active', show);
    }

    this.fireChangeCallback();
  }

  get modalName() {
    return this.modal.getAttribute('data-slp-target');
  }
}
