import createUserMessageArray from './create_user_message_array';

function videoPlaybackNotSupportedUserMessage(presentationId) {
  return createUserMessageArray({
    presentationId,
    userMessage: 'Video playback is not supported in your browser.',
    upgradeBrowser: true,
    report: true,
  });
}

export default videoPlaybackNotSupportedUserMessage;
