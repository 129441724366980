const cs = {
  play: 'Přehrát [mezerník]',
  pause: 'Pozastavit [mezerník]',
  volume: 'Hlasitost',
  live: 'Live',
  fullscreen: 'Celá obrazovka [f]',
  minimize: 'Zmenšit',
  prevSlide: 'Předchozí slide [šipka vlevo]',
  nextSlide: 'Další slide [šipka vpravo]',
  syncSlidesToVideo: 'Synchronizovat slidy s videem [s]',
  syncVideoToSlides: 'Synchronizovat video se slidy [t]',
  originalSlide: 'Zobrazit původní slide',
  settings: 'Nastavení',
  playlists: 'Playlisty',
  bookmarks: 'Záložky',
  subtitles: 'Titulky',
  playbackRate: 'Rychlost přehrávání',
  quality: 'Kvalita',
  server: 'Server',
  cancel: 'Zrušit',
  next: 'Další',
  close: 'Zavřít',
  create: 'Vytvořit',
  save: 'Uložit',
  edit: 'Upravit',
  remove: 'Smazat',
  liveStartSoon: 'Živý přenos začne již brzy!',
  liveTerminated: 'Živý přenos již skončil.',
  createPlaylist: 'Vytvořit playlist',
  addFavorite: 'Přidat do oblíbených',
  addWatchLater: 'Přidat ke zhlédnutí později',
  addToPlaylist: 'Přidat do playlistu',
  removeFavorite: 'Odebrat z oblíbených',
  removeWatchLater: 'Odebrat ze zhlédnutí později',
  removeFromPlaylist: 'Odebrat z playlistu',
  addBookmark: 'Přidat záložku [b]',
  addNote: 'Přidat poznámku',
  moreOptions: 'Více možností',
  watchLater: 'Zhlédnout později',
  favorite: 'Oblíbené',
  myPlaylists: 'Moje playlisty',
  newPlaylistName: 'Název playlistu',
  topicsAndTranscript: 'Témata a přepis',
  notRecorded: 'Prezentace ještě nebyla nahrána!',
  debugInformation: 'Debug informace',
  muteSlide: 'Ztišit video vpravo',
  unmuteSlide: 'Zapnout zvuk videa vpravo',
  resolveReviewNote: 'Označit jako vyřešené',
  addReviewNoteComment: 'Napište komentář...',
  sendReviewNoteComment: 'Odeslat komentář',
  closeReviewNotePopup: 'Zavřít okno',
  syncDiff: 'Sync diff',
  reportIssue: 'Nahlásit chybu',
  subtitlesSize: 'Velikost titulků',
  subtitlesSizeLarge: 'Velké',
  subtitlesSizeMedium: 'Střední',
  subtitlesSizeSmall: 'Malé',
  automated: 'automatické',
  cs: 'Čeština',
  de: 'Němčina',
  en: 'Angličtina',
  sk: 'Slovenština',
};

export default cs;
