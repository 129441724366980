const loader = {
  src: 'https://content.videoken.com/players/slideslive.js',
  loading: false,
  loaded: false,
  listeners: [],

  load(callback) {
    if (callback) {
      this.listeners.push(callback);
    }

    if (this.loaded) {
      setTimeout(() => this.done(), 0);
      return;
    }

    if (this.loading) {
      return;
    }

    this.loading = true;

    const ready = () => {
      this.loaded = true;
      this.done();

      window.onVideoKenPlayerReady = undefined;
      window.onVideokenAPIReady = undefined;
    };

    window.onVideoKenPlayerReady = ready;
    window.onVideoKenAPIReady = ready;

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.charset = 'ISO-8859-1';
    script.async = true;
    script.src = this.src;
    document.head.appendChild(script);
  },

  done() {
    while (this.listeners.length > 0) {
      this.listeners.shift()();
    }
  },
};

((window, name) => {
  if (typeof module !== 'undefined' && module.exports) {
    module.exports = loader;
  } else {
    window[name] = loader;
  }
})(window, 'VideoKenLoader');

export default loader;
