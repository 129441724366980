import VideoPlayer from 'modules/player/video/video_player';

import defer from 'plugins/utilities/defer';

import Slide from './slide';
import videoThumbnail from './video-thumbnail.jpg';

class VideoSlideBase extends Slide {
  constructor(options, callbacks) {
    super('video', options, callbacks);

    this._autoPlay = false;
    this._videoPlayerRequestedCurrentTime = 0;

    this.element = document.createElement('div');
    this._videoPlayer = new VideoPlayer(this.element, this._videoPlayerOptions);
    this._addVideoPlayerCallbacks(this._videoPlayer);
  }

  load() {
    if (this.isLoading) return;

    if (this.isLoaded) {
      defer(() => this.callbacks.loaded(this.element));
      return;
    }

    super.load();
    this._loadVideoPlayer();
  }

  set muted(muted) {
    if (this.alwaysMuted && !muted) {
      return;
    }

    this._videoPlayer.muted = muted;
  }

  play() {
    if (this._videoPlayerRequestedCurrentTime > this.durationMs) return;

    this._videoPlayer.play();
  }

  pause() {
    this._videoPlayer.pause();
  }

  get progressImageUrl() {
    return videoThumbnail;
  }

  get autoPlay() {
    return this._autoPlay;
  }

  set autoPlay(autoPlay) {
    this._autoPlay = autoPlay;
  }

  get alwaysMuted() {
    return true; // this.options.data.muted;
  }

  get durationMs() {
    return this.options.data.duration_ms;
  }

  get currentTime() {
    return this._videoPlayer.currentTime;
  }

  set currentTime(value) {
    this._videoPlayerRequestedCurrentTime = value;

    if (value > this.durationMs) {
      if (this.currentTime < this.durationMs) {
        this._videoPlayer.currentTime = this.durationMs;
      }
    } else {
      this._videoPlayer.currentTime = value;
    }
  }

  get ended() {
    return this._videoPlayer.ended;
  }

  get playbackRate() {
    return this._videoPlayer ? this._videoPlayer.playbackRate : 1;
  }

  set playbackRate(value) {
    this._videoPlayer.playbackRate = value;
  }

  _setLoaded() {
    this.isLoaded = true;
    this.isLoading = false;
  }

  _addVideoPlayerCallbacks(videoPlayer) {
    videoPlayer.on('ready', () => {
      this._setLoaded();
      this.callbacks.loaded(this.element);
    });
    videoPlayer.on('loadingChanged', (loading) => this.callbacks.loadingChanged(loading, this.element));
    videoPlayer.on('stateChanged', (state) => {
      if (this.durationMs !== this.currentTime) {
        this.callbacks.stateChanged(this.element, state);
      }
    });
    videoPlayer.on('volumeChanged', (volume, muted) => this.callbacks.updateVolume(this.element, volume, muted));
    videoPlayer.on('showError', (error) => {
      this._setLoaded();
      this.callbacks.error(this.element, error);
    });
    videoPlayer.on('ended', () => this.callbacks.ended(this.element));
    videoPlayer.on('playbackRateChanged', (playbackRate) =>
      this.callbacks.playbackRateChanged(this.element, playbackRate),
    );
    videoPlayer.on('playFailed', () => this.callbacks.playFailed(this.element));
    videoPlayer.on('cmcd', (...args) => this.callbacks.cmcd({ ...args, slideTime: this.time }));
  }

  get _videoResolutionWidth() {
    return this.options.data.resolution && this.options.data.resolution.width;
  }

  get _videoResolutionHeight() {
    return this.options.data.resolution && this.options.data.resolution.height;
  }

  get _aspectRatio() {
    if (!Number.isFinite(this._videoResolutionWidth) || Number.isFinite(this._videoResolutionHeight)) return 16 / 9.0;

    return this._videoResolutionWidth / this._videoResolutionHeight;
  }

  get _videoPlayerOptions() {
    return {
      presentationId: this.options.presentationId,
      presentationMediaSetId: this.options.presentationMediaSetId,
      analyticsUserUuid: this.options.analyticsUserUuid,
      analyticsSessionUuid: this.options.analyticsSessionUuid,
      embed: this.options.embed,
      accountId: this.options.accountId,
      contentType: 'one_slide_video',
      contentTypeDetails: [`slide_time = ${this.time}`],
      source: this.options.source,
      live: false,
      autoPlay: false,
      originalVideoControls: false,
      initial: {
        muted: this.alwaysMuted,
        ratio: this._aspectRatio,
      },
    };
  }
}

export default VideoSlideBase;
