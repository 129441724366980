import log from 'modules/player/log';

import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';
import now from 'plugins/utilities/now';

export default class HlsSourceSwitcher {
  constructor(player, options, callbacks) {
    this.player = player;
    this.options = options;
    this.callbacks = callbacks;

    this.props = {
      sources: [],
      playbackServer: null,
      playbackUrl: null,
      playbackQuality: null,

      waitingTimeout: null,

      reloadFromNextServerCount: 0,

      duration: null,
      lastDurationChange: null,
    };

    if (!this.options.usingHlsJs) {
      console.warn('HLS:SS', "using native HLS, we won't reload video on loading timeouts");
    }
  }

  updateDuration(duration) {
    this.props.duration = duration;
    this.props.lastDurationChange = now();
  }

  playbackUrlForServerAndQuality(server, quality, streamKeyParam) {
    const wowzaApplicationParam =
      streamKeyParam === 'slides_stream_key' ? 'slides_wowza_application' : 'wowza_application';
    const streamKey = server.server_info[streamKeyParam];

    let path;
    if (quality === 'adaptive') {
      path = `ngrp:${streamKey}_all/playlist.m3u8`;
    } else if (quality === 'smil') {
      path = `smil:${streamKey}.smil/playlist.m3u8`;
    } else {
      path = `${streamKey}_${quality}/playlist.m3u8`;
    }

    if (server.server_info.dvr) {
      path += '?DVR';
    }

    const wowzaApplication = server.server_info[wowzaApplicationParam];

    return `https://${server.server_info.cdn_domain_name}/${wowzaApplication}/${path}`;
  }

  sourcesFromServers(servers, streamKeyParam) {
    const availableQualitiesParam =
      streamKeyParam === 'slides_stream_key' ? 'slides_available_qualities' : 'available_qualities';

    const sources = [];
    for (const server of servers) {
      const source = {
        name: server.server_info.human_name,
        quality: 'auto',
        seekable: server.server_info.dvr,
        urls: {},
      };

      let availableQualities = server.server_info[availableQualitiesParam];
      if (!isVariableDefinedNotNull(availableQualities) || availableQualities.length === 0) {
        availableQualities = ['auto'];
      }

      for (const quality of availableQualities) {
        source.urls[quality] = this.playbackUrlForServerAndQuality(server, quality, streamKeyParam);
      }

      if (streamKeyParam === 'slides_stream_key') {
        if (server.server_info.abr_slides_playback_url) {
          if (server.server_info.use_abr_playback) {
            source.quality = 'auto';
          }

          source.urls.auto = server.server_info.abr_slides_playback_url;
        }
      } else if (server.server_info.abr_playback_url) {
        if (server.server_info.use_abr_playback) {
          source.quality = 'auto';
        }

        source.urls.auto = server.server_info.abr_playback_url;
      }

      sources.push(source);
    }

    return sources;
  }

  updateSources(servers, streamKeyParam) {
    this.props.sources = this.sourcesFromServers(servers, streamKeyParam);

    log('HLS:SS', 'updated sources', this.props.sources);

    const server = this.findPlaybackUrlServerIndex(this.props.playbackUrl);
    if (isVariableDefinedNotNull(server)) {
      this.props.playbackServer = server;
    } else {
      this.props.playbackServer = 0;

      if (
        !isVariableDefinedNotNull(this.props.playbackQuality) ||
        (this.props.sources.length > 0 &&
          Object.keys(this.props.sources[0].urls).indexOf(this.props.playbackQuality) < 0)
      ) {
        if (
          this.options.defaultStreamQuality &&
          this.props.sources.length > 0 &&
          Object.keys(this.props.sources[0].urls).indexOf(this.options.defaultStreamQuality) >= 0
        ) {
          this.props.playbackQuality = this.options.defaultStreamQuality;
        } else if (this.props.sources.length > 0) {
          this.props.playbackQuality = this.props.sources[0].quality;
        } else {
          this.props.playbackQuality = '720p';
        }
      }

      this.updatePlaybackUrl();
    }

    this.callbacks.availableServersChanged();
    this.callbacks.availableQualitiesChanged();
  }

  findPlaybackUrlServerIndex(url) {
    if (!isVariableDefinedNotNull(url)) {
      return null;
    }

    for (let serverIndex = 0; serverIndex < this.props.sources.length; ++serverIndex) {
      for (const quality of Object.keys(this.props.sources[serverIndex].urls)) {
        const u = this.props.sources[serverIndex].urls[quality];
        if (u === url) {
          return serverIndex;
        }
      }
    }

    return null;
  }

  startLoadingTimeout(currentTime) {
    let timeout;

    const timeBuffer = this.props.duration - currentTime;
    if (timeBuffer < 1000) {
      timeout = 30000;
    } else {
      timeout = 60000;
    }

    this.scheduleReloadVideoFromNextServer({ timeout });
  }

  stopReloadVideoFromNextServer() {
    if (!this.props.reloadVideoFromNextServerTimeout) {
      return;
    }

    log('HLS:SS', 'stop reload timeout');

    clearTimeout(this.props.reloadVideoFromNextServerTimeout);
    this.props.reloadVideoFromNextServerTimeout = null;
  }

  scheduleReloadVideoFromNextServer({
    started = true,
    timeout = null,
    reloadSameServer = false,
    allowSameServer = false,
  } = {}) {
    if (this.props.reloadVideoFromNextServerTimeout) {
      return;
    }

    if (!timeout) {
      timeout = started ? 1000 : 5000;
    }

    log('HLS:SS', 'start reload timeout', this.props.playbackQuality, timeout);

    this.props.reloadVideoFromNextServerTimeout = setTimeout(
      () => this.reloadVideoFromNextServer({ reloadSameServer, allowSameServer }),
      timeout,
    );
  }

  reloadVideoFromNextServer({ forcePlay = false, reloadSameServer = false, allowSameServer = false } = {}) {
    if (this.props.playbackServer === null) {
      this.props.playbackServer = 0;

      log('HLS:SS', 'initial load from server', this.props.playbackServer, this.props.playbackQuality);
    } else {
      let newPlaybackServer;

      if (reloadSameServer) {
        newPlaybackServer = this.props.playbackServer;
      } else {
        newPlaybackServer = (this.props.playbackServer + 1) % this.props.sources.length;
        if (!allowSameServer && newPlaybackServer === this.props.playbackServer) {
          log('HLS:SS', 'no next server, not reloading');
          this.scheduleReloadVideoFromNextServer({ timeout: 5000 });
          return;
        }
      }

      this.props.playbackServer = newPlaybackServer;
      this.props.reloadFromNextServerCount += 1;

      log(
        'HLS:SS',
        'reload from next server',
        this.props.reloadFromNextServerCount,
        this.props.playbackServer,
        this.props.playbackQuality,
      );
    }

    this.updatePlaybackUrl({ force: true, forcePlay });
  }

  reloadVideo() {
    log('HLS:SS', 'reload from current server');
    this.updatePlaybackUrl({ force: true });
  }

  updatePlaybackUrl({ force = false, forcePlay = undefined } = {}) {
    if (this.props.sources.length === 0) {
      return;
    }

    const seekable = this.props.sources[this.props.playbackServer].seekable;
    const playbackUrl = this.props.sources[this.props.playbackServer].urls[this.props.playbackQuality];

    if (force || this.props.playbackUrl !== playbackUrl) {
      this.stopReloadVideoFromNextServer();

      this.props.playbackUrl = playbackUrl;
      this.callbacks.changePlaybackUrl(this.props.playbackUrl, forcePlay, seekable);
    }
  }

  get quality() {
    return this.props.playbackQuality;
  }

  set quality(quality) {
    this.props.playbackQuality = quality;
    this.updatePlaybackUrl();
  }

  set server(serverIndex) {
    this.props.playbackServer = serverIndex;
    this.updatePlaybackUrl();
  }

  get availableQualities() {
    const availableQualities = [];

    if (
      this.props.sources.length > 0 &&
      this.props.sources[this.props.playbackServer] &&
      this.props.sources[this.props.playbackServer].urls
    ) {
      for (const [quality, url] of Object.entries(this.props.sources[this.props.playbackServer].urls)) {
        availableQualities.push({
          id: quality.toLowerCase(),
          key: quality.toLowerCase(),
          name: quality,
          bitrate: quality === 'auto' ? Infinity : parseInt(quality, 10),
          url,
        });
      }
    }

    return availableQualities.sort((a, b) => b.bitrate - a.bitrate);
  }

  get availableServers() {
    const servers = [];

    for (let serverIndex = 0; serverIndex < this.props.sources.length; ++serverIndex) {
      const source = this.props.sources[serverIndex];
      const name = source.name;

      servers.push([name, serverIndex]);
    }

    return servers;
  }

  get playbackServer() {
    return this.props.playbackServer;
  }
}
