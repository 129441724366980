import log from 'modules/player/log';

import inlineCss from 'plugins/element/inline_css';
import addListener from 'plugins/utilities/add_listener';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

const valueOrDefault = (object, key, defaultValue) =>
  isVariableDefinedNotNull(object[key]) ? object[key] : defaultValue;

export default class Mp4Service {
  constructor(element, options) {
    this.element = element;
    this.options = options;

    this.props = {
      loading: true,
      ready: false,
      currentTime: 0,
      duration: 0,

      state: '',

      volume: valueOrDefault(this.options.initial, 'volume', 100),
      muted: valueOrDefault(this.options.initial, 'muted', false),
      playbackRate: valueOrDefault(this.options.initial, 'playbackRate', 1),

      ratio: valueOrDefault(this.options.initial, 'ratio', 16 / 9.0),

      availablePlaybackRates: [],

      seeking: false,
    };
  }

  load(service, videoId) {
    log('MP4', 'load', videoId);

    this.loadOffline(videoId);
  }

  loadOffline(video) {
    this.video = document.createElement('video');
    this.video.setAttribute('playsinline', 'playsinline');
    this.video.setAttribute('webkit-playsinline', 'webkit-playsinline');
    this.video.playsinline = true;
    this.video['webkit-playsinline'] = true;

    inlineCss(this.video, {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
    });

    this.element.insertAdjacentElement('beforeend', this.video);

    this.addVideoListeners();

    this.video.src = video;
  }

  addVideoListeners() {
    addListener(this.video, 'error', (event) => log('MP4', event.type, event));

    addListener(this.video, 'loadeddata', (event) => log('MP4', event.type, event));

    addListener(this.video, 'load', (event) => log('MP4', event.type, event));
    addListener(this.video, 'loadend', (event) => log('MP4', event.type, event));
    addListener(this.video, 'loadstart', (event) => log('MP4', event.type, event));

    addListener(this.video, 'offline', (event) => log('MP4', event.type, event));
    addListener(this.video, 'online', (event) => log('MP4', event.type, event));

    addListener(this.video, 'readystatechange', (event) => log('MP4', event.type, event));

    addListener(this.video, 'stalled', (event) => log('MP4', event.type, event));
    addListener(this.video, 'suspend', (event) => log('MP4', event.type, event));

    // addListener(this.video, 'progress', event =>log('MP4', event.type, event));

    addListener(this.video, 'stalled', (event) => log('MP4', event.type, event));
    addListener(this.video, 'emptied', (event) => log('MP4', event.type, event));

    addListener(this.video, 'ended', (event) => this.onEnded(event));

    addListener(this.video, 'loadedmetadata', (event) => this.onLoadedData(event));
    addListener(this.video, 'canplay', (event) => this.onCanPlay(event));
    addListener(this.video, 'play', (event) => this.onPlay(event));
    addListener(this.video, 'pause', (event) => this.onPause(event));

    addListener(this.video, 'playing', (event) => this.onPlaying(event));
    addListener(this.video, 'waiting', (event) => this.onWaiting(event));
    addListener(this.video, 'seeking', (event) => this.onSeeking(event));
    addListener(this.video, 'seeked', (event) => this.onSeeked(event));

    addListener(this.video, 'timeupdate', (event) => this.onTimeUpdate(event));

    addListener(this.video.textTracks, 'addtrack', (event) => this.onTextTracksAdd(event));
  }

  play() {
    log('MP4', 'play request');

    const promise = this.video.play();
    if (isVariableDefinedNotNull(promise)) {
      promise.catch((error) => {
        console.warn('HLS', 'play failed', error);
        this.options.callbacks.playFailed();
      });
    }
  }

  pause() {
    log('MP4', 'pause request');

    this.video.pause();
  }

  setMuted(muted) {
    this.video.muted = muted;
    this.props.muted = muted;
    this.options.callbacks.volumeChanged(this.props.volume, this.props.muted);
  }

  seekToLivePosition() {
    console.warn('Not implemented!');
  }

  // eslint-disable-next-line no-unused-vars
  setSubtitleTrack(track) {
    console.warn('Not implemented!');
  }

  // eslint-disable-next-line no-unused-vars
  setQuality(quality) {
    console.warn('Not implemented!');
  }

  set playbackServer(serverIndex) {
    console.warn('Not implemented!');
  }

  set volume(value) {
    this.props.volume = value;

    this.video.muted = false;
    this.video.volume = this.props.volume / 100.0;

    this.options.callbacks.volumeChanged(this.props.volume, this.props.muted);
  }

  get currentStreamTime() {
    return this.props.timeSinceStreamStart;
  }

  get currentTime() {
    return this.props.currentTime;
  }

  set currentTime(value) {
    this.props.seekToLiveBeforePlay = false;

    this.props.currentTime = value;
    this.video.currentTime = value / 1000.0;

    this.props.inLivePosition = false;
    this.options.callbacks.inLivePositionChanged(this.props.inLivePosition);
  }

  get duration() {
    return this.props.duration;
  }

  set playbackRate(value) {
    this.video.playbackRate = value;

    this.props.playbackRate = value;
    this.options.callbacks.playbackRateChanged(this.props.playbackRate);
  }

  // General

  get inLivePosition() {
    return this.props.inLivePosition;
  }

  get ready() {
    return this.props.ready;
  }

  get loading() {
    return this.props.loading;
  }

  set loading(value) {
    if (value === this.props.loading) {
      return;
    }

    this.props.loading = value;
    this.options.callbacks.loadingChanged(value);
  }

  get state() {
    return this.props.state;
  }

  set state(value) {
    if (value === this.props.state) {
      return;
    }

    this.props.state = value;
    this.options.callbacks.stateChanged(value);
  }

  // Internal

  updateAvailablePlaybackRates() {
    this.props.availablePlaybackRates = [0.5, 1, 1.2, 1.45, 1.7, 2];

    if (this.options.callbacks.availablePlaybackRatesChanged) {
      this.options.callbacks.availablePlaybackRatesChanged(this.props.availablePlaybackRates);
    }

    if (this.options.callbacks.playbackRateChanged) {
      this.options.callbacks.playbackRateChanged(this.props.playbackRate);
    }
  }

  setReady() {
    if (this.ready) {
      return;
    }

    this.updateAvailablePlaybackRates();

    this.video.muted = this.props.muted;
    this.video.volume = this.props.volume / 100.0;
    this.options.callbacks.volumeChanged(this.props.volume, this.props.muted);

    this.loading = false;
    this.state = 'paused';

    this.props.ready = true;
    this.options.callbacks.ready();
  }

  onLoadedData(event) {
    log('MP4', event.type, event);

    this.props.duration = this.video.duration * 1000;

    this.setReady();
  }

  onCanPlay(event) {
    log('MP4', event.type, event);

    this.setReady();
  }

  onPlay(event) {
    log('MP4', event.type, event);

    this.loading = false;
    this.state = 'playing';
  }

  onPause(event) {
    log('MP4', event.type, event);

    this.loading = false;
    this.state = 'paused';
  }

  onPlaying(event) {
    log('MP4', event.type, event);

    this.loading = false;
    this.state = 'playing';
  }

  onWaiting(event) {
    log('MP4', event.type, this.video.readyState, event);

    this.loading = true;
  }

  onEnded(event) {
    log('MP4', event.type, event);

    this.options.callbacks.ended();
  }

  onSeeking(event) {
    log('MP4', event.type, event);

    this.seeking = true;
    this.loading = true;
  }

  onSeeked(event) {
    log('MP4', event.type, event);

    this.seeking = false;
    this.loading = false;
  }

  // eslint-disable-next-line no-unused-vars
  onTimeUpdate(event) {
    // log('MP4', event.type, event);

    this.props.duration = this.video.duration * 1000;
    this.props.currentTime = this.video.currentTime * 1000;
  }

  get seeking() {
    return this.props.seeking;
  }

  set seeking(seeking) {
    this.props.seeking = seeking;
  }
}
