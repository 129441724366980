const de = {
  play: 'Play [spacebar]',
  pause: 'Pause [spacebar]]',
  volume: 'Volume',
  live: 'Live',
  fullscreen: 'Fullscreen [f]',
  minimize: 'Minimize',
  prevSlide: 'Vorherige Seite [Pfeil links]',
  nextSlide: 'Nächste Seite [Pfeil rechts]',
  syncSlidesToVideo: 'Die Slides mit dem Video synchronisieren [s]',
  syncVideoToSlides: 'Das Video mit den Slides synchronisieren [t]',
  originalSlide: 'Show original slide',
  settings: 'Settings',
  playlists: 'Playlists',
  bookmarks: 'Bookmarks',
  subtitles: 'Subtitles',
  playbackRate: 'Playback rate',
  quality: 'Quality',
  server: 'Server',
  cancel: 'Cancel',
  next: 'Next',
  close: 'Close',
  create: 'Create',
  save: 'Save',
  edit: 'Edit',
  remove: 'Remove',
  liveStartSoon: 'Livestream will start soon!',
  liveTerminated: 'Livestream has already ended.',
  createPlaylist: 'Create playlist',
  addFavorite: 'Add to Favorite',
  addWatchLater: 'Add to Watch later',
  addToPlaylist: 'Add to playlist',
  removeFavorite: 'Remove from Favorite',
  removeWatchLater: 'Remove from Watch later',
  removeFromPlaylist: 'Remove from playlist',
  addBookmark: 'Add bookmark [b]',
  addNote: 'Add a note',
  moreOptions: 'More options',
  watchLater: 'Watch later',
  favorite: 'Favorite',
  myPlaylists: 'My playlists',
  newPlaylistName: 'Playlist name',
  topicsAndTranscript: 'Topics and transcript',
  notRecorded: 'Presentation has not been recorded yet!',
  debugInformation: 'Debug information',
  muteSlide: 'Mute right video',
  unmuteSlide: 'Unmute right video',
  resolveReviewNote: 'Resolve',
  addReviewNoteComment: 'Enter review comment...',
  sendReviewNoteComment: 'Send comment without resolving',
  closeReviewNotePopup: 'Close popup',
  syncDiff: 'Sync diff',
  reportIssue: 'Report Issue',
  subtitlesSize: 'Subtitles size',
  subtitlesSizeLarge: 'Large',
  subtitlesSizeMedium: 'Medium',
  subtitlesSizeSmall: 'Small',
  automated: 'automated',
  cs: 'Czech',
  de: 'German',
  en: 'English',
  sk: 'Slovak',
};

export default de;
