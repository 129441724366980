function getEventPageX(event) {
  if ('pageX' in event) {
    return event.pageX;
  }

  if ('touches' in event && event.touches.length) {
    return event.touches[0].pageX;
  }

  if ('changedTouches' in event && event.changedTouches.length) {
    return event.changedTouches[0].pageX;
  }

  return 0;
}

export default getEventPageX;
