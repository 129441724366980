import log from 'modules/player/log';

import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

export default class HlsSourceSwitcher {
  constructor(player, options, callbacks) {
    this.player = player;
    this.options = options;
    this.callbacks = callbacks;

    this.props = {
      sources: [],

      currentSourceUrl: null,
      currentSourceIndex: null,

      waitingTimeout: null,

      reloadFromNextSourceCount: 0,

      reloadVideoFromNextSourceTimeout: null,
    };

    if (!this.options.usingHlsJs) {
      console.warn('HLS_VOD_V1:SS', "using native HLS, we won't reload video on loading timeouts");
    }
  }

  updateSources(sources) {
    this.props.sources = sources;

    log('HLS_VOD_V1:SS', 'updated sources', this.props.sources);

    const sourceIndex = this.findSourceIndex(this.props.currentSourceUrl);
    if (isVariableDefinedNotNull(sourceIndex)) {
      this.props.currentSourceIndex = sourceIndex;
    } else {
      this.props.currentSourceIndex = 0;
      this.updateCurrentSource();
    }

    this.callbacks.sourcesChanged();
  }

  findSourceIndex(url) {
    if (!isVariableDefinedNotNull(url)) {
      return null;
    }

    for (let sourceIndex = 0; sourceIndex < this.props.sources.length; ++sourceIndex) {
      const source = this.props.sources[sourceIndex];
      if (source.hls === url || source.dash === url || source.mp4 === url) {
        return sourceIndex;
      }
    }

    return null;
  }

  // eslint-disable-next-line no-unused-vars
  startLoadingTimeout(currentTime) {
    const timeout = 60000;
    this.scheduleReloadVideoFromNextSource({ timeout });
  }

  stopReloadVideoFromNextSource() {
    if (!this.props.reloadVideoFromNextSourceTimeout) {
      return;
    }

    log('HLS_VOD_V1:SS', 'stop reload timeout');

    clearTimeout(this.props.reloadVideoFromNextSourceTimeout);
    this.props.reloadVideoFromNextSourceTimeout = null;
  }

  scheduleReloadVideoFromNextSource({ timeout = null, reloadSameSource = false, allowSameSource = false } = {}) {
    if (this.props.reloadVideoFromNextSourceTimeout) {
      return;
    }

    if (!timeout) {
      timeout = 1000;
    }

    log('HLS_VOD_V1:SS', 'start reload timeout', timeout);

    this.props.reloadVideoFromNextSourceTimeout = setTimeout(
      () => this.reloadVideoFromNextSource({ reloadSameSource, allowSameSource }),
      timeout,
    );
  }

  reloadVideoFromNextSource({ forcePlay = false, reloadSameSource = false, allowSameSource = false } = {}) {
    if (this.props.currentSourceIndex === null) {
      this.props.currentSourceIndex = 0;
      log('HLS_VOD_V1:SS', 'initial load from source', this.props.currentSourceIndex);
    } else {
      let newSourceIndex;

      if (reloadSameSource) {
        newSourceIndex = this.props.currentSourceIndex;
      } else {
        newSourceIndex = (this.props.currentSourceIndex + 1) % this.props.sources.length;
        if (!allowSameSource && newSourceIndex === this.props.currentSourceIndex) {
          log('HLS_VOD_V1:SS', 'no next source, not reloading');
          this.scheduleReloadVideoFromNextSource({ timeout: 5000 });
          return;
        }
      }

      this.props.currentSourceIndex = newSourceIndex;
      this.props.reloadFromNextSourceCount += 1;

      log(
        'HLS_VOD_V1:SS',
        'reload from next source',
        this.props.reloadFromNextSourceCount,
        this.props.currentSourceIndex,
      );
    }

    this.updateCurrentSource({ force: true, forcePlay });
  }

  reloadVideo() {
    log('HLS:SS', 'reload from current source');
    this.updateCurrentSource({ force: true });
  }

  updateCurrentSource({ force = false, forcePlay = false } = {}) {
    if (this.props.sources.length === 0) {
      return;
    }

    const sourceUrl = this.props.sources[this.props.currentSourceIndex].hls;

    if (force || this.props.currentSourceUrl !== sourceUrl) {
      this.stopReloadVideoFromNextSource();

      this.props.currentSourceUrl = sourceUrl;
      this.callbacks.changePlaybackUrl(this.props.currentSourceUrl, forcePlay);
    }
  }

  set sourceIndex(sourceIndex) {
    this.props.currentSourceIndex = sourceIndex;
    this.updatePlaybackUrl();
  }

  get sourceIndex() {
    return this.props.currentSourceIndex;
  }

  get availableSources() {
    const sources = [];

    for (let sourceIndex = 0; sourceIndex < this.props.sources.length; ++sourceIndex) {
      sources.push([sourceIndex.toString(), sourceIndex]);
    }

    return sources;
  }
}
