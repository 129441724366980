import shaka from 'shaka-player';

function createMailTo(error, presentationId) {
  const video = document.createElement('video');

  const email = 'support@slideslive.com';
  const subject = `SlidesLive Player: ${error}`;
  let body = 'Describe your issue here:%0D%0A%0D%0A%0D%0ADebug information (please keep these):%0D%0A';
  body += `Presentation ID: ${presentationId}%0D%0A`;
  body += `User-Agent: ${navigator.userAgent}%0D%0A`;
  body += `Shaka player supported: ${shaka.Player.isBrowserSupported()}%0D%0A`;
  body += `Native Dash support: ${video.canPlayType('application/dash+xml')}%0D%0A`;
  body += `Native HLS support: ${video.canPlayType('application/vnd.apple.mpegurl')}%0D%0A`;
  body += `Native MP4 support: ${video.canPlayType('video/mp4')}%0D%0A`;

  return `<a class="link--white link--underline" href="mailto:${email}?subject=${subject}&body=${body}">${email}</a>`;
}

function createUserMessageArray({
  presentationId,
  userMessage,
  upgradeBrowser = false,
  tryAgainAndReport = false,
  report = false,
  reportSubject = null,
}) {
  const message = [userMessage];

  if (upgradeBrowser) {
    message.push('Try upgrading your browser or try using a different one.');
  }

  if (tryAgainAndReport || report) {
    const mailTo = createMailTo(reportSubject || userMessage, presentationId);

    if (tryAgainAndReport) {
      message.push(`Please try again or report this error to ${mailTo} if it persists.`);
    } else if (report) {
      message.push(`Report this error to ${mailTo} if it persists.`);
    }
  }

  return message;
}

export default createUserMessageArray;
