import log from 'modules/player/log';

import ajax from 'plugins/ajax/ajax';
import slidesLiveDomain from 'plugins/utilities/slideslive_domain';

export default class Bookmarks {
  constructor(options, callbacks) {
    this.props = {
      options: {},
      bookmarksList: [],
      baseUrl: '',

      destroyed: false,
    };

    this.options = options;
    this.callbacks = callbacks;

    this.initBaseUrl();
  }

  initBaseUrl() {
    this.baseUrl = `${window.location.protocol}//${slidesLiveDomain()}`;
  }

  initBookmarksList() {
    if (this.props.destroyed) {
      return;
    }

    ajax('GET', this.fetchBookmarksListUrl, undefined, {
      withCredentials: true,
      success: (req) => {
        if (this.props.destroyed) {
          return;
        }

        const data = JSON.parse(req.response).data;

        log('BOOKMARKS', 'fetch bookmarks list success', data.length);

        this.bookmarksList = [...this.bookmarksList, ...data];

        this.callbacks.updateBookmarksList(this.bookmarksList);
      },
      error: (req) => {
        if (this.props.destroyed) {
          return;
        }

        if (req.status === 404) {
          log('BOOKMARKS', 'fetch bookmarks list success but empty');
          this.callbacks.updateBookmarksList([]);
          return;
        }

        log('BOOKMARKS', 'fetch bookmarks list error', req);
      },
    });
  }

  add(time) {
    if (this.props.destroyed) {
      return;
    }

    const reqData = {
      presentation_id: this.options.presentationId,
      current_time: Math.round(time / 1000), // bookmarks time saved in seconds
    };

    ajax('POST', `${this.url}`, JSON.stringify(reqData), {
      withCredentials: true,
      success: (req) => {
        if (this.props.destroyed) {
          return;
        }

        const data = JSON.parse(req.response).data;

        log('BOOKMARKS', 'add bookmark success', data.id);

        this.bookmarksList.push(data);

        this.callbacks.afterAdded(data.id);
        this.callbacks.updateBookmarksList(this.bookmarksList);
      },
      error: (req) => {
        if (this.props.destroyed) {
          return;
        }

        log('BOOKMARKS', 'add bookmark error', req);
      },
    });
  }

  update({ id, note }) {
    if (this.props.destroyed) {
      return;
    }

    const reqData = {
      bookmark: { note },
    };

    ajax('PUT', `${this.url}/${id}`, JSON.stringify(reqData), {
      withCredentials: true,
      success: (req) => {
        if (this.props.destroyed) {
          return;
        }

        const data = JSON.parse(req.response).data;

        log('BOOKMARKS', 'update bookmark success', data.id);

        const index = this.bookmarksList.findIndex((b) => b.id === parseInt(data.id, 10));
        this.bookmarksList[index] = data;

        this.callbacks.afterUpdate(data, this.bookmarksList);
      },
      error: (req) => {
        if (this.props.destroyed) {
          return;
        }

        log('BOOKMARKS', 'update bookmark error', req);
      },
    });
  }

  remove(id) {
    if (this.props.destroyed) {
      return;
    }

    ajax('DELETE', `${this.url}/${id}`, undefined, {
      withCredentials: true,
      success: (req) => {
        if (this.props.destroyed) {
          return;
        }

        const data = JSON.parse(req.response).data;

        log('BOOKMARKS', 'remove bookmark success', data.id);

        this.bookmarksList = this.bookmarksList.filter((b) => b.id !== parseInt(data.id, 10));

        this.callbacks.afterRemove(data.id, this.bookmarksList);
      },
      error: (req) => {
        if (this.props.destroyed) {
          return;
        }

        log('BOOKMARKS', 'remove bookmark error', req);
      },
    });
  }

  destroy() {
    this.props.destroyed = true;
  }

  set options(options) {
    this.props.options = options;
  }

  get options() {
    return this.props.options;
  }

  set bookmarksList(bookmarksList) {
    this.props.bookmarksList = bookmarksList;
  }

  get bookmarksList() {
    return this.props.bookmarksList;
  }

  get url() {
    return `${this.baseUrl}/api/v1/player_bookmarks`;
  }

  get fetchBookmarksListUrl() {
    return `${this.baseUrl}/api/v1/player_bookmarks/${this.options.presentationId}`;
  }

  set baseUrl(baseUrl) {
    this.props.baseUrl = baseUrl;
  }

  get baseUrl() {
    return this.props.baseUrl;
  }
}
