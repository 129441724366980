import remove from 'plugins/element/remove';
import getSpinnerInContainerAsElement from 'plugins/spinner/get_spinner_in_container_as_element';
import addListener from 'plugins/utilities/add_listener';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

import Modal from './modal';

export default class PlaylistsModal extends Modal {
  constructor(modal, options) {
    super(modal, options);

    if (!isVariableDefinedNotNull(modal)) {
      return;
    }

    this.content = this.modal.querySelector('[data-slp-target="modalContent"]');
    this.form = this.modal.querySelector('[data-slp-target="addPlaylistForm"]');
    this.newNameInput = this.form.querySelector('[data-slp-target="newPlaylistName"]');

    this.originalContentHTML = this.content.innerHTML;

    addListener(this.form, 'submit', this.handleAddPlaylistFormSubmit.bind(this));
  }

  open() {
    if (!isVariableDefinedNotNull(this.modal)) {
      return;
    }

    this.newNameInput.value = '';
    super.open();
  }

  updatePlaylistsList(list, presentationPlaylists) {
    this.newNameInput.value = '';

    let html = this.originalContentHTML;

    for (let i = 0; i < list.length; i++) {
      const playlist = list[i];
      const name = playlist.canonical_name || playlist.id;

      if (name !== 'history') {
        html += this.itemHTML(playlist);
      }
    }

    this.content.innerHTML = html;

    for (let i = 0; i < presentationPlaylists.length; i++) {
      const playlist = presentationPlaylists[i];
      const name = playlist.canonical_name || playlist.id;

      this.updatePlaylist(name, true);
    }
  }

  updatePlaylist(name, added) {
    const target = this.content.querySelector(`[data-slp-target="playlist-${name}"]`);

    if (!target) {
      return;
    }

    target.setAttribute('data-slp-action-param', `${name};${!added}`);

    const checkbox = this.content.querySelector(`[data-slp-target="checkbox-${name}"]`);
    checkbox.checked = added;
  }

  setPlaylistLoading(name, show) {
    const target = this.content.querySelector(`[data-slp-target="playlist-${name}"]`);

    if (!target) {
      return;
    }

    if (!show) {
      if (isVariableDefinedNotNull(target.spinnerElement)) {
        remove(target.spinnerElement);
      }

      target.disabled = false;
      return;
    }

    if (!isVariableDefinedNotNull(target.spinnerElement)) {
      const spinner = getSpinnerInContainerAsElement({ tiny: true, absoluteWrapper: true });
      target.spinnerElement = spinner;
    }

    target.disabled = true;
    target.insertAdjacentElement('afterbegin', target.spinnerElement);
  }

  handleAddPlaylistFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const name = this.newNameInput.value.trim();

    if (name === '') {
      return;
    }

    this.options.callbacks.addPlaylist(name);
  }

  itemHTML(playlist) {
    const param = playlist.canonical_name || playlist.id;

    let html = '<li>';
    html += '  <button class="slp__modal-playlists__item"';
    html += ` data-slp-target="playlist-${param}"`;
    html += ' data-slp-action-w-param="togglePlaylist"';
    html += ` data-slp-action-param="${param};true">`;
    html += '    <div class="animatedCheckbox__container animatedCheckbox__container--light">';
    html += '      <div class="animatedCheckbox">';
    html += `        <input type="checkbox" data-slp-target="checkbox-${param}">`;
    html += '        <span class="animatedCheckbox__icon"></span>';
    html += `        <span class="animatedCheckbox__label">${playlist.name}</span>`;
    html += '      </div>';
    html += '    </button>';
    html += '  </button>';
    html += '</li>';

    return html;
  }
}
