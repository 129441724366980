import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';
import shaka from 'shaka-player';

import ERROR_REPORT_DATA_VERSION from '../errors/error_report_data_version';
import nativeErrorCodeName from '../errors/native_error_code_name';
import nativeVideoNetworkStateName from '../errors/native_video_network_state_name';
import nativeVideoReadyStateName from '../errors/native_video_ready_state_name';
import shakaErrorCategoryName from './shaka_error_category_name';
import shakaErrorCodeName from './shaka_error_code_name';
import shakaErrorSeverityName from './shaka_error_severity_name';

function createErrorReportData(
  videoService,
  error,
  {
    source = 'unknown',
    warn = false,
    willTryNextSource = false,
    nativeError = false,
    duringLoad = false,
    recoverableError = false,
    reloadInProgress = false,
  } = {},
) {
  const reportData = {
    report_version: ERROR_REPORT_DATA_VERSION,
    warn,
    source,
    will_try_next_source: willTryNextSource,
    native_error: nativeError,
    during_load: duringLoad,
    recoverable_error: recoverableError,
    reload_in_progress: reloadInProgress,
  };

  if (isVariableDefinedNotNull(videoService.props.currentVideoSourceIndex)) {
    reportData.current_video_source_index = videoService.props.currentVideoSourceIndex;
  }

  if (isVariableDefinedNotNull(videoService.props.videoSources)) {
    reportData.video_source_count = videoService.props.videoSources.length;
  }

  if (isVariableDefinedNotNull(videoService.props.recoverMediaErrorCounter)) {
    reportData.recover_media_error_counter = videoService.props.recoverMediaErrorCounter;
  }

  let shakaLoadMode = '<none>';
  if (videoService.shaka) {
    if (videoService.shaka.getLoadMode() === shaka.Player.LoadMode.DESTROYED) {
      shakaLoadMode = 'DESTROYED';
    } else if (videoService.shaka.getLoadMode() === shaka.Player.LoadMode.MEDIA_SOURCE) {
      shakaLoadMode = 'MEDIA_SOURCE';
    } else if (videoService.shaka.getLoadMode() === shaka.Player.LoadMode.NOT_LOADED) {
      shakaLoadMode = 'NOT_LOADED';
    } else if (videoService.shaka.getLoadMode() === shaka.Player.LoadMode.SRC_EQUALS) {
      shakaLoadMode = 'SRC_EQUALS';
    } else {
      shakaLoadMode = `<unknown:${videoService.shaka.getLoadMode()}>`;
    }
  }

  reportData.state = {
    shaka_supported: shaka.Player.isBrowserSupported(),
    using_shaka_player: !!videoService.shaka,
    shaka_load_mode: shakaLoadMode,

    native_video_can_play_dash: videoService.video.canPlayType('application/dash+xml'),
    native_video_can_play_hls: videoService.video.canPlayType('application/vnd.apple.mpegurl'),
    native_video_can_play_mp4: videoService.video.canPlayType('video/mp4'),

    native_video_has_error: !!videoService.video.error,
    native_video_network_state: nativeVideoNetworkStateName(videoService.video.networkState),
    native_video_ready_state: nativeVideoReadyStateName(videoService.video.readyState),

    player_ready: videoService.props.ready,
    player_current_time: videoService.currentTime,
    player_duration: videoService.duration,
  };

  if (nativeError) {
    reportData.native_error = {
      error: error.constructor.name,
      code: nativeErrorCodeName(error),
      message: error.message,
    };

    try {
      reportData.raw_native_error = JSON.stringify(error);
    } catch (e) {
      reportData.raw_native_error = null;
    }
  } else if (typeof error === 'string') {
    try {
      reportData.raw_shaka_error = JSON.stringify(error);
    } catch (e) {
      reportData.raw_shaka_error = null;
    }
  } else {
    const errorCodeName = shakaErrorCodeName(error);

    reportData.shaka_error = {
      error: shakaErrorCategoryName(error),
      category: shakaErrorCategoryName(error),
      code: errorCodeName,
      severity: shakaErrorSeverityName(error),
      message: error.message,
      data: error.data,
    };

    try {
      reportData.raw_shaka_error = JSON.stringify(error);
    } catch (e) {
      reportData.raw_shaka_error = null;
    }

    if (
      errorCodeName === 'UNSUPPORTED_SCHEME' ||
      errorCodeName === 'BAD_HTTP_STATUS' ||
      errorCodeName === 'HTTP_ERROR' ||
      errorCodeName === 'TIMEOUT' ||
      errorCodeName === 'MALFORMED_DATA_URI'
    ) {
      reportData.uri = error.data[0];
    }
  }

  if (videoService.video.error) {
    reportData.video_error = {
      error: videoService.video.error.constructor.name,
      code: nativeErrorCodeName(videoService.video.error.code),
      message: videoService.video.error.message,
    };

    try {
      reportData.raw_video_error = JSON.stringify(videoService.video.error);
    } catch (e) {
      reportData.raw_video_error = null;
    }
  }

  let errorName;
  if (nativeError) {
    errorName = `N::${error.constructor.name}::${nativeErrorCodeName(error)}`;
  } else if (typeof error === 'string') {
    errorName = `S[${shakaLoadMode}]::${error.toLowerCase().substring(0, 42)}`;
  } else {
    errorName = `S[${shakaLoadMode}]::${shakaErrorCategoryName(error)}::${shakaErrorCodeName(error)}`;
  }

  return {
    errorName,
    reportData,
  };
}

export default createErrorReportData;
