import addListener from 'plugins/utilities/add_listener';
import removeListener from 'plugins/utilities/remove_listener';

export default class Keyboard {
  constructor(element, callbacks) {
    this.element = element;
    this.callbacks = callbacks;

    this.props = {
      keydownListenerId: null,
    };

    this.props.keydownListenerId = addListener(this.element, 'keydown', event => {
      if (
        event.ctrlKey ||
        event.metaKey ||
        (document.activeElement && document.activeElement.nodeName === 'INPUT')
      ) {
        return;
      }

      if (this.handleKey(event.keyCode, event.altKey || event.shiftKey)) {
        event.preventDefault();
      }
    });
  }

  handleKey(key, functionKey) {
    if (functionKey) {
      return this.handleFunctionKey(key);
    }

    return this.handleNormalKey(key);
  }

  handleFunctionKey(key) {
    switch (key) {
      case 37:
        this.callbacks.skipBack();
        return true;
      case 39:
        this.callbacks.skipForward();
        return true;
      default:
        return false;
    }
  }

  handleNormalKey(key) {
    switch (key) {
      case 27:
        this.callbacks.hideOpenedOverlays();
        return true;
      case 32:
        this.callbacks.togglePlayback();
        return true;
      case 37:
        this.callbacks.prevSlide();
        return true;
      case 39:
        this.callbacks.nextSlide();
        return true;
      case 66:
        this.callbacks.addBookmark();
        return true;
      case 70:
        this.callbacks.toggleFullscreen();
        return true;
      case 77:
        this.callbacks.toggleMute();
        return true;
      case 83:
        this.callbacks.syncSlidesToVideo();
        return true;
      case 84:
        this.callbacks.syncVideoToSlides();
        return true;
      default:
        return false;
    }
  }

  destroy() {
    if (this.props.keydownListenerId) {
      removeListener(this.element, { id: this.props.keydownListenerId });
      this.props.keydownListenerId = null;
    }
  }
}
