import HlsJsPlayerLoader from 'hls-js-player-loader';
import log from 'modules/player/log';
import mux from 'mux-embed';

import inlineCss from 'plugins/element/inline_css';
import formatTimeString from 'plugins/number/format_time_string';
import addListener from 'plugins/utilities/add_listener';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';
import now from 'plugins/utilities/now';
import urlParam from 'plugins/utilities/url_param';
import valueOrDefault from 'plugins/utilities/value_or_default';

import createErrorReportData from './hls_live_v2/create_error_report_data';
import HlsSourceSwitcher from './hls_live_v2/hls_source_switcher';
import * as ID3 from './hls_v3/id3.ts';

const HLS_JS_VERSION = '1.5.4';

const HLS_JS_MAX_LOADING_RETRIES = 1000000;
const HLS_JS_MAX_LOADING_RETRY_TIMEOUT = 2000;
const HLS_JS_LOADING_RETRY_DELAY = 100;

export default class HlsV2Service {
  constructor(element, options) {
    this.element = element;
    this.options = options;

    this.props = {
      loadingInProgress: false,
      loaded: false,
      pendingServers: [],
      streamKeyParam: null,

      playerInitTime: null,
      loading: false,
      ready: false,
      currentTime: 0,
      duration: 0,

      state: '',

      volume: valueOrDefault(this.options.initial, 'volume', 100),
      muted: valueOrDefault(this.options.initial, 'muted', false),
      playbackRate: valueOrDefault(this.options.initial, 'playbackRate', 1),
      quality: valueOrDefault(this.options.initial, 'quality', null),
      subtitlesEnabled: valueOrDefault(this.options.initial, 'subtitlesEnabled', true),
      subtitlesTrack: valueOrDefault(this.options.initial, 'subtitlesTrack', null),
      liveSubtitlesDelayMs: valueOrDefault(this.options.initial, 'liveSubtitlesDelayMs', 0),
      liveFinished: valueOrDefault(this.options.initial, 'liveFinished', false),

      ratio: valueOrDefault(this.options.initial, 'ratio', 16 / 9.0),

      inLivePosition: true,
      inLivePositionInitialCallbackCalled: false,

      seekToLiveBeforePlay: true,
      streamStartSet: false,
      streamStart: 0,
      streamStartRaw: 0,
      streamStartStartTime: 0,
      streamStartEndTime: 0,
      timeSinceStreamStartSet: false,
      timeSinceStreamStart: 0,
      programDateTime: 0,

      availablePlaybackRates: [],
      availableServers: [],

      availableQualitiesData: {
        auto: {
          level: '-1',
          key: 'auto',
          name: 'Auto',
          bitrate: Infinity,
        },
      },
      currentQuality: 'auto',
      currentQualityName: 'Auto',

      seekToFromEndOnCanPlay: null,
      playOnCanPlay: false,
      useHlsJs: false,
      seeking: false,

      started: null,
      ended: false,

      injectedSubtitleTimes: [],
      subtitleTextTrackMode: 'showing',

      firstPlay: true,

      seekable: true,

      hlsJsRecoverMediaErrorCounter: 0,
      hlsJsLastRecoverMediaErrorCallAt: null,
      hlsJsAudioCodecsSwapped: false,
      hlsJsRecoverMediaBufferAppendErrorCounter: 0,
      hlsJsRecoverMediaErrDecodeCounter: 0,

      loadedAt: null,
    };

    this.props.seekToLiveBeforePlay = !this.props.liveFinished;
  }

  loadLive(servers, streamKeyParam) {
    this.loading = true;

    this.props.loadedAt = new Date();
    this.props.loadingInProgress = true;
    this.props.pendingServers = servers;
    this.props.streamKeyParam = streamKeyParam;

    HlsJsPlayerLoader.load((Hls) => this.doLoadLive(Hls), HLS_JS_VERSION);
  }

  createHls() {
    this.hls = new this.Hls({
      fragLoadingMaxRetry: HLS_JS_MAX_LOADING_RETRIES,
      fragLoadingRetryDelay: HLS_JS_LOADING_RETRY_DELAY,
      fragLoadingMaxRetryTimeout: HLS_JS_MAX_LOADING_RETRY_TIMEOUT,

      levelLoadingMaxRetry: HLS_JS_MAX_LOADING_RETRIES,
      levelLoadingRetryDelay: HLS_JS_LOADING_RETRY_DELAY,
      levelLoadingMaxRetryTimeout: HLS_JS_MAX_LOADING_RETRY_TIMEOUT,

      manifestLoadingMaxRetry: 0,
      manifestLoadingRetryDelay: HLS_JS_LOADING_RETRY_DELAY,
      manifestLoadingMaxRetryTimeout: HLS_JS_MAX_LOADING_RETRY_TIMEOUT,

      startFragPrefetch: true,
      liveSyncDurationCount: 3,
      debug: !!urlParam('debug_hls_player'),

      maxBufferSize: 60 * 1024 * 1024,
      maxBufferLength: 30,
      maxMaxBufferLength: 120,
      backBufferLength: 60,
    });

    this.addHlsJsListeners();
    this.setupDebugInfoCallback();
  }

  sendCmcd({ request }) {
    this.options.callbacks.cmcd({ request });
  }

  doLoadLive(Hls) {
    this.props.playerInitTime = mux.utils.now();

    this.Hls = Hls;

    const emptyBlob = new Blob([''], { type: 'text/plain' });
    const emptyBlobUrl = URL.createObjectURL(emptyBlob);

    this.video = document.createElement('video');
    this.video.setAttribute('playsinline', 'playsinline');
    this.video.setAttribute('webkit-playsinline', 'webkit-playsinline');
    this.video.playsinline = true;
    this.video['webkit-playsinline'] = true;
    this.video.innerHTML = `<track kind="captions" src="${emptyBlobUrl}" srclang="en" default>`;

    if (window.gon.mux_data_live_env_key) {
      let muxVideoTitleDetails = [`media_set_id = ${this.options.presentationMediaSetId}`];

      if (this.options.videoId) {
        muxVideoTitleDetails.push(`video_id = ${this.options.videoId}`);
      }

      if (this.options.contentTypeDetails) {
        muxVideoTitleDetails = muxVideoTitleDetails.concat(this.options.contentTypeDetails);
      }

      const muxVideoTitlePrefix = `${this.options.presentationId}/${this.options.contentType}`;
      const muxVideoTitle = `${muxVideoTitlePrefix}: ${muxVideoTitleDetails.join(', ')}`;

      mux.monitor(this.video, {
        debug: false,
        data: {
          env_key: window.gon.mux_data_live_env_key,
          video_id: this.options.presentationId.toString(),
          video_title: muxVideoTitle,
          viewer_user_id: this.options.analyticsUserUuid,
          experiment_name: this.options.source,
          page_type: this.options.embed ? 'iframe' : 'watchpage',
          player_init_time: this.props.playerInitTime,
          player_name: 'hls_live_v2',
          sub_property_id: this.options.accountId.toString(),
          video_content_type: this.options.contentType,
          video_stream_type: 'live',
          video_variant_id: this.options.presentationMediaSetId.toString(),
          view_session_id: this.options.analyticsSessionUuid,

          custom_1: this.options.presentationId,
        },
      });
    }

    inlineCss(this.video, {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      opacity: 1,
    });

    this.element.insertAdjacentElement('beforeend', this.video);

    if (!urlParam('native_hls_player') && this.Hls.isSupported()) {
      log('HLSv2', 'Using HLS.js.');

      this.props.useHlsJs = true;
    } else if (this.video.canPlayType('application/vnd.apple.mpegurl')) {
      log('HLSv2', 'HLS.js is not supported, using native HLS playback.');

      this.props.useHlsJs = false;
    } else {
      console.warn('HLSv2', 'not supported');

      const errorMessage = [
        'Livestream playback is not supported in your browser.',
        'Try upgrading your browser or try using Google Chrome or Firefox.',
        'Report this error to support@slideslive.com if it persists.',
      ];
      this.options.callbacks.showError(`${errorMessage.join('<br>')}`);

      const { errorName, reportData } = createErrorReportData(this, null, {
        source: 'load',
        customErrorName: 'NOT_SUPPORTED',
      });

      this.options.callbacks.reportError('HLS_PLAYER', errorName, reportData);

      return;
    }

    this.addVideoListeners();
    this.sourceSwitcher = new HlsSourceSwitcher(
      this,
      {
        usingHlsJs: this.props.useHlsJs,
        defaultStreamQuality: this.options.defaultStreamQuality,
      },
      {
        changePlaybackUrl: (url, forcePlay, seekable) => this.changePlaybackUrl(url, forcePlay, seekable),
        availableQualitiesChanged: () => this.updateAvailableQualities(),
        availableServersChanged: () => this.updateAvailableServers(),
      },
    );
    this.props.loaded = true;

    if (this.props.pendingServers.length === 0) {
      this.updateStarted(false);
    } else {
      this.sourceSwitcher.updateSources(this.props.pendingServers, this.props.streamKeyParam);
    }
  }

  updateServers(servers, streamKeyParam) {
    if (this.props.loaded) {
      this.sourceSwitcher.updateSources(servers, streamKeyParam);
    } else if (this.props.loadingInProgress) {
      this.props.pendingServers = servers;
      this.props.streamKeyParam = streamKeyParam;
    } else {
      this.loadLive(servers, streamKeyParam);
    }
  }

  reportError(error, { source, nativeError, customErrorName = null } = {}) {
    const { errorName, reportData } = createErrorReportData(this, error, {
      source,
      nativeError,
      customErrorName,
    });

    if (this.options.callbacks.reportError) {
      this.options.callbacks.reportError('HLS_PLAYER', errorName, reportData);
    } else {
      console.warn('HLS_PLAYER', 'report error', errorName, reportData);
    }
  }

  handleHlsJsError(error) {
    if (error.fatal) {
      if (error.type !== Hls.ErrorTypes.NETWORK_ERROR || this.props.started) {
        console.warn('HLSv2', 'js', 'fatal error', error);
      }
    } else {
      console.warn('HLSv2', 'js', 'error', error);
    }

    if (error.type === Hls.ErrorTypes.MEDIA_ERROR) {
      if (error.details === Hls.ErrorDetails.BUFFER_APPEND_ERROR && error.err && error.err.code === 11) {
        // workaround for videos getting stuck in Google Chrome
        console.warn('HLSv2', 'js', 'attempting to recover from media buffer append error by reloading video');
        this.sourceSwitcher.reloadVideo();
        this.props.hlsJsRecoverMediaBufferAppendErrorCounter += 1;
        return;
      }

      if (error.fatal) {
        const lastErrorLongAgo =
          !this.props.hlsJsLastRecoverMediaErrorCallAt || now() - this.props.hlsJsLastRecoverMediaErrorCallAt >= 5000;

        if (lastErrorLongAgo) {
          console.warn('HLSv2', 'js', 'attempting to recover from fatal media error');
          this.hls.recoverMediaError();

          this.props.hlsJsRecoverMediaErrorCounter += 1;
          this.props.hlsJsLastRecoverMediaErrorCallAt = now();

          return;
        }

        if (!this.props.hlsJsAudioCodecsSwapped) {
          console.warn('HLSv2', 'js', 'attempting to recover from fatal media error with audio codec swap');

          this.hls.swapAudioCodec();
          this.hls.recoverMediaError();

          this.props.hlsJsRecoverMediaErrorCounter += 1;
          this.props.hlsJsAudioCodecsSwapped = true;
          this.props.hlsJsLastRecoverMediaErrorCallAt = now();

          return;
        }
      }
    }

    if (error.type === Hls.ErrorTypes.NETWORK_ERROR) {
      if (this.props.started === null) {
        this.updateStarted(false);
      }

      if (error.fatal) {
        if (
          error.details === Hls.ErrorDetails.MANIFEST_LOAD_ERROR ||
          error.details === Hls.ErrorDetails.MANIFEST_LOAD_TIMEOUT ||
          error.details === Hls.ErrorDetails.MANIFEST_PARSING_ERROR
        ) {
          this.sourceSwitcher.scheduleReloadVideoFromNextServer({
            started: this.props.started,
            reloadSameServer: !this.props.started,
          });

          return;
        }

        log('HLSv2', 'js', 'attempting to recover from fatal network error');
        this.hls.startLoad();

        return;
      }
    }

    if (error.fatal) {
      this.reportError(error, {
        source: 'hls_js_listener',
        nativeError: false,
      });
    }
  }

  handleNativeError(error, errorEvent) {
    log('HLSv2', 'native', 'error', error, errorEvent);

    if (!error) {
      return;
    }

    // MediaError - https://developer.mozilla.org/en-US/docs/Web/API/MediaError
    // error.code === 1: MEDIA_ERR_ABORTED
    // error.code === 2: MEDIA_ERR_NETWORK
    // error.code === 3: MEDIA_ERR_DECODE
    // error.code === 4: MEDIA_ERR_SRC_NOT_SUPPORTED

    // networkState - https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/networkState
    // video.networkState === 0: NETWORK_EMPTY
    // video.networkState === 1: NETWORK_IDLE
    // video.networkState === 2: NETWORK_LOADING
    // video.networkState === 3: NETWORK_NO_SOURCE

    // workaround for videos getting stuck in Google Chrome
    if (this.props.useHlsJs && error.code === 3) {
      console.warn('HLSv2', 'native', 'attempting to recover from MEDIA_ERR_DECODE by reloading video');

      setTimeout(() => {
        this.loading = true;

        this.currentTime += 2000;
        this.sourceSwitcher.reloadVideo();
      }, 0);

      this.props.hlsJsRecoverMediaErrDecodeCounter += 1;
      return;
    }

    if (this.props.useHlsJs) {
      return;
    }

    if (this.video.networkState === 3) {
      if (this.props.started === null) {
        this.updateStarted(false);
      }

      this.sourceSwitcher.scheduleReloadVideoFromNextServer({
        started: this.props.started,
        reloadSameServer: !this.props.started,
      });

      return;
    }

    console.warn('HLSv2', 'native', 'error', error);

    this.reportError(error, {
      source: 'native_listener',
      nativeError: true,
    });
  }

  addVideoListeners() {
    addListener(this.video, 'error', (event) => this.handleNativeError(this.video.error, event));

    addListener(this.video, 'load', (event) => log('HLSv2', event.type, event));
    addListener(this.video, 'loadend', (event) => log('HLSv2', event.type, event));
    addListener(this.video, 'loadstart', (event) => log('HLSv2', event.type, event));

    addListener(this.video, 'offline', (event) => log('HLSv2', event.type, event));
    addListener(this.video, 'online', (event) => log('HLSv2', event.type, event));

    addListener(this.video, 'readystatechange', (event) => log('HLSv2', event.type, event));

    addListener(this.video, 'stalled', (event) => this.onStalled(event));
    addListener(this.video, 'suspend', (event) => this.onSuspend(event));

    // addListener(this.video, 'progress', event => log('HLSv2', event.type, event));

    addListener(this.video, 'emptied', (event) => log('HLSv2', event.type, event));

    addListener(this.video, 'ended', (event) => this.onEnded(event));

    addListener(this.video, 'loadedmetadata', (event) => this.onLoadedMetadata(event));
    addListener(this.video, 'loadeddata', (event) => this.onLoadedData(event));

    addListener(this.video, 'canplay', (event) => this.onCanPlay(event));
    addListener(this.video, 'play', (event) => this.onPlay(event));
    addListener(this.video, 'pause', (event) => this.onPause(event));

    addListener(this.video, 'playing', (event) => this.onPlaying(event));
    addListener(this.video, 'waiting', (event) => this.onWaiting(event));
    addListener(this.video, 'seeking', (event) => this.onSeeking(event));
    addListener(this.video, 'seeked', (event) => this.onSeeked(event));

    addListener(this.video, 'timeupdate', (event) => this.onTimeUpdate(event));

    addListener(this.video.textTracks, 'addtrack', (event) => this.onTextTracksAdd(event));
  }

  addHlsJsListeners() {
    this.hls.on(Hls.Events.ERROR, (event, data) => this.handleHlsJsError(data));

    this.hls.on(Hls.Events.FRAG_PARSING_METADATA, (event, data) => {
      for (const sample of data.samples) {
        const id3Data = ID3.getID3Data(sample.data, 0);
        const id3Frames = ID3.getID3Frames(id3Data);

        for (const frame of id3Frames) {
          if (frame.info === 'SL_SUBT') {
            if (!this.isSubtitleInjected(sample.pts)) {
              const subtitle = ID3.utf8ArrayToStr(new Uint8Array(frame.data));
              this.injectSubtitle(sample.pts, subtitle);
            }
          }
        }
      }
    });

    this.hls.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
      if (this.props.currentQuality === 'auto') {
        let currentQualityName = 'Auto';
        if (this.hls.levels[data.level] && this.hls.levels[data.level].height > 0) {
          currentQualityName += ` (${this.hls.levels[data.level].height}p)`;
        }

        // eslint-disable-next-line dot-notation
        this.props.availableQualitiesData['auto'].name = currentQualityName;
        this.props.currentQualityName = currentQualityName;

        if (this.options.callbacks.availableQualitiesChanged) {
          this.options.callbacks.availableQualitiesChanged(this._availableQualitiesOptions());
        }
      }
    });
  }

  setupDebugInfoCallback() {
    const dateToISOString = (date) => {
      if (!isVariableDefinedNotNull(date)) return 'N/A';
      if (date instanceof Date) return date.toISOString();
      if (typeof date === 'number' && !Number.isFinite(date)) return 'N/A';

      const dateObject = new Date(date);
      return `${dateObject.toISOString()} (${date})`;
    };

    const timeToString = (time) => {
      const timeString = formatTimeString(time, {
        inMs: true,
        useColons: true,
      });

      return `${timeString} (${time})`;
    };

    setInterval(() => {
      const debugInfo = {
        date: dateToISOString(this.props.loadedAt),
        'use Hls.js': this.props.useHlsJs,
        SL_STAR: dateToISOString(this.props.streamStartRaw),
        SL_PRDT: dateToISOString(this.programDateTime),
        SL_CURR: timeToString(this.timeSinceStreamStart),
        'calculated time since stream start': timeToString(this.programDateTime - this.props.streamStartRaw),
        'calculated stream start': dateToISOString(this.programDateTime - this.timeSinceStreamStart),
        'in live position': this.props.inLivePosition,
        'current quality': `${this.props.currentQualityName} ${this.props.currentQuality}`,
        'Hls.js: audio codec swapped': this.props.hlsJsAudioCodecsSwapped,
        'Hls.js: recover media error counter': this.props.hlsJsRecoverMediaErrorCounter,
        'Hls.js: recover media err decode counter': this.props.hlsJsRecoverMediaErrDecodeCounter,
        'Hls.js: recover media bugger append error counter': this.props.hlsJsRecoverMediaBufferAppendErrorCounter,
      };

      this.options.callbacks.debugInfo(debugInfo);
    }, 1000);
  }

  isSubtitleInjected(time) {
    return this.props.injectedSubtitleTimes.indexOf(time) >= 0;
  }

  injectSubtitle(time, text, withoutDelay) {
    this.props.injectedSubtitleTimes.push(time);

    let timeWithDelay = time;
    if (!withoutDelay) {
      timeWithDelay += (this.props.liveSubtitlesDelayMs || 0) / 1000.0;
    }

    let subtitleTime;
    if (this.props.previousSubtitleEndTime && Math.abs(timeWithDelay - this.props.previousSubtitleEndTime) < 1) {
      subtitleTime = Math.max(timeWithDelay, this.props.previousSubtitleEndTime);
    } else {
      subtitleTime = timeWithDelay;
    }

    const subtitleEndTime = subtitleTime + 2;

    log(
      'SUBS',
      this.currentTime / 1000.0,
      time,
      subtitleTime,
      subtitleTime - time,
      this.props.liveSubtitlesDelayMs,
      text,
    );

    this.props.previousSubtitleEndTime = subtitleEndTime;

    const subtitleCue = new VTTCue(subtitleTime, subtitleEndTime, text);
    if (!this.subtitleTextTrack) {
      console.warn('Cannot inject subtitles. Subtitles track not found. Cue:', subtitleCue);
      return;
    }

    this.subtitleTextTrack.addCue(subtitleCue);
  }

  play() {
    log('HLSv2', 'play request', this.props.seekToLiveBeforePlay);

    const seekToLiveBeforePlay = this.props.seekToLiveBeforePlay;

    if (!this.props.seekable || (!this.props.liveFinished && seekToLiveBeforePlay)) {
      this.props.seekToLiveBeforePlay = false;
      this.seekToLivePosition();
    }

    const promise = this.video.play();
    if (isVariableDefinedNotNull(promise)) {
      promise
        .then(() => {
          this.props.lastPlayFailed = false;
        })
        .catch((error) => {
          console.warn('HLSv2', 'play failed', error);

          this.options.callbacks.playFailed();
          this.props.lastPlayFailed = true;
          this.props.seekToLiveBeforePlay = seekToLiveBeforePlay;

          this.loading = false;
          this.state = 'paused';
        });
    }
  }

  pause() {
    log('HLSv2', 'pause request');

    this.video.pause();
  }

  setMuted(muted) {
    this.video.muted = muted;
    this.props.muted = muted;
    this.options.callbacks.volumeChanged(this.props.volume, this.props.muted);
  }

  seekToLivePosition() {
    log('HLSv2', 'seek to live position', this.liveSyncPosition);

    this.currentTime = this.liveSyncPosition;

    this.props.inLivePosition = true;
    this.options.callbacks.inLivePositionChanged(this.props.inLivePosition);
  }

  setQuality(quality) {
    if (this.props.currentQuality === 'auto') {
      // eslint-disable-next-line dot-notation
      this.props.availableQualitiesData['auto'].name = 'Auto';

      if (this.options.callbacks.availableQualitiesChanged) {
        this.options.callbacks.availableQualitiesChanged(this._availableQualitiesOptions());
      }
    }

    this.sourceSwitcher.quality = quality;
  }

  destroy() {
    if (this.props.useHlsJs) {
      if (this.hls) {
        this.hls.detachMedia();
        this.hls.destroy();
      }
    }
  }

  set playbackServer(serverIndex) {
    this.sourceSwitcher.server = serverIndex;
  }

  set volume(value) {
    this.props.volume = value;

    this.video.muted = false;
    this.video.volume = this.props.volume / 100.0;

    this.options.callbacks.volumeChanged(this.props.volume, this.props.muted);
  }

  get currentStreamTime() {
    if (this.props.timeSinceStreamStartSet) {
      return this.props.timeSinceStreamStart;
    }

    return this.currentTime;
  }

  get currentTime() {
    return this.video ? this.video.currentTime * 1000.0 : 0;
  }

  set currentTime(value) {
    this.props.seekToFromEndOnCanPlay = null;
    this.props.seekToLiveBeforePlay = false;
    this.props.previousSubtitleEndTime = null;

    const currentTimeBefore = this.video.currentTime * 1000.0;

    this.props.currentTime = value;
    this.video.currentTime = value / 1000.0;

    this.updateInLivePosition();

    if (this.options.callbacks.seekRequest) {
      this.options.callbacks.seekRequest(currentTimeBefore, value);
    }
  }

  get duration() {
    return this.props.duration;
  }

  set playbackRate(value) {
    if (this.video) {
      this.video.playbackRate = value;
    }

    this.props.playbackRate = value;
    this.options.callbacks.playbackRateChanged(this.props.playbackRate);
  }

  // General

  get inLivePosition() {
    return this.props.inLivePosition;
  }

  get ready() {
    return this.props.ready;
  }

  get loading() {
    return this.props.loading;
  }

  set loading(value) {
    if (value === this.props.loading) {
      return;
    }

    this.props.loading = value;
    this.options.callbacks.loadingChanged(value);
  }

  get state() {
    return this.props.state;
  }

  set state(value) {
    if (value === this.props.state) {
      return;
    }

    this.props.state = value;
    this.options.callbacks.stateChanged(value);
  }

  // Internal

  changePlaybackUrl(url, forcePlay, seekable) {
    this.props.playbackUrl = url;

    log('HLSv2', 'loading video from', this.props.playbackUrl);

    if (isVariableDefinedNotNull(forcePlay)) {
      this.props.playOnCanPlay = forcePlay;
    } else if (this.props.state === 'playing') {
      this.props.playOnCanPlay = true;
    }

    if (!this.props.liveFinished && (!this.ready || this.inLivePosition)) {
      this.props.seekToFromEndOnCanPlay = 'live';
    } else {
      this.props.seekToFromEndOnCanPlay = this.duration - this.currentTime;
    }

    this.options.callbacks.qualityChanged(this.sourceSwitcher.quality);
    this.options.callbacks.playbackServerChanged(this.sourceSwitcher.playbackServer);

    this.props.seekable = seekable;
    this.options.callbacks.seekableChanged(seekable);

    if (this.props.started) {
      this.loading = true;
    }

    this.loadPlaybackUrl();
  }

  loadPlaybackUrl() {
    let subtitleTextTrackMode;

    if (this.subtitleTextTrack) {
      subtitleTextTrackMode = this.subtitleTextTrack.mode;
      this.subtitleTextTrack.mode = 'hidden';

      for (const cue of this.subtitleTextTrack.cues) {
        this.subtitleTextTrack.removeCue(cue);
      }
    }

    this.props.injectedSubtitleTimes = [];

    if (this.props.useHlsJs) {
      if (this.hls) {
        this.hls.detachMedia();
        this.hls.destroy();

        this.props.hlsJsLastRecoverMediaErrorCallAt = null;
        this.props.hlsJsAudioCodecsSwapped = false;
      }

      this.createHls();
      this.hls.loadSource(this.props.playbackUrl);
      this.hls.attachMedia(this.video);

      if (this.video.mux) {
        this.video.mux.removeHLSJS();
        this.video.mux.addHLSJS({
          Hls: this.Hls,
          hlsjs: this.hls,
        });
      }
    } else {
      this.video.src = this.props.playbackUrl;
    }

    if (this.video.mux) {
      mux.emit(this.video, 'videochange', {
        custom_2: this.props.playbackUrl,
      });
    }

    if (this.subtitleTextTrack) {
      this.subtitleTextTrack.mode = subtitleTextTrackMode;
    }
  }

  updateAvailablePlaybackRates() {
    if (!this.props.liveFinished) {
      this.props.availablePlaybackRates = [1];
    } else {
      this.props.availablePlaybackRates = [0.5, 1, 1.2, 1.45, 1.7, 2];
    }

    if (this.options.callbacks.availablePlaybackRatesChanged) {
      this.options.callbacks.availablePlaybackRatesChanged(this.props.availablePlaybackRates);
    }

    if (this.options.callbacks.playbackRateChanged) {
      this.options.callbacks.playbackRateChanged(this.props.playbackRate);
    }
  }

  updateAvailableQualities() {
    this.updateAvailableQualitiesNative();

    if (this.options.callbacks.availableQualitiesChanged) {
      this.options.callbacks.availableQualitiesChanged(this._availableQualitiesOptions());
    }

    if (this.options.callbacks.qualityChanged) {
      this.options.callbacks.qualityChanged(this.props.currentQuality);
    }
  }

  updateAvailableQualitiesNative() {
    const previousAutoQualityName =
      this.props.availableQualitiesData.auto && this.props.availableQualitiesData.auto.name;

    let currentQuality = 'auto';
    const availableQualitiesData = {};
    for (const qualityData of this.sourceSwitcher.availableQualities) {
      availableQualitiesData[qualityData.key] = {
        key: qualityData.key,
        name: qualityData.name === 'auto' ? previousAutoQualityName : qualityData.name,
        bitrate: qualityData.bitrate,
        url: qualityData.url,
      };

      if (qualityData.url === this.video.src) {
        currentQuality = qualityData.key;
      }
    }

    if (this.props.currentQuality !== 'auto') {
      this.props.currentQuality = currentQuality;
    }

    this.props.currentQualityName = availableQualitiesData[currentQuality]?.name || 'N/A';
    this.props.availableQualitiesData = availableQualitiesData;
  }

  updateAvailableServers() {
    this.props.availableServers = this.sourceSwitcher.availableServers;
    this.options.callbacks.availableServersChanged(this.props.availableServers);
  }

  onLoadedMetadata(event) {
    log('HLSv2', event.type, event);

    if (!this.props.useHlsJs) {
      this.customCanPlay();
    }
  }

  onLoadedData(event) {
    log('HLSv2', event.type, event);

    if (!this.props.useHlsJs) {
      this.customCanPlay();
    }
  }

  onCanPlay(event) {
    log('HLSv2', event.type, event);

    this.customCanPlay();
  }

  customCanPlay() {
    log('HLSv2', 'customCanPlay');

    this.sourceSwitcher.stopReloadVideoFromNextServer();

    let timesChanged = false;
    if (!this.ready || this.props.duration === 0) {
      timesChanged = true;

      this.props.duration = this.calcDuration();

      if (!this.props.liveFinished) {
        this.props.currentTime = this.props.duration;
      }
    }

    if (!this.ready && this.props.liveFinished) {
      timesChanged = true;

      // setTimeout(() => (this.currentTime = 1000), 5000);
    } else if (this.props.seekToFromEndOnCanPlay === 'live') {
      timesChanged = true;

      if (!this.inLivePosition) {
        this.seekToLivePosition();
      }

      this.props.currentTime = this.props.duration;
      this.props.seekToLiveBeforePlay = true;
    } else if (isVariableDefinedNotNull(this.props.seekToFromEndOnCanPlay)) {
      timesChanged = true;

      this.currentTime = Math.min(this.duration, this.duration - this.props.seekToFromEndOnCanPlay);
    }

    if (timesChanged) {
      this.updateInLivePosition();
      this.options.callbacks.timesChanged(this.currentTime, this.duration);
    }

    this.props.seekToFromEndOnCanPlay = null;

    if (this.props.playOnCanPlay) {
      this.play();
    } else if (this.ready) {
      if (this.canStopLoading) {
        this.loading = false;
      }

      if (this.video.paused) {
        this.state = 'paused';
      } else {
        this.state = 'playing';
      }
    }

    this.props.playOnCanPlay = false;

    if (!this.ready) {
      this.setReady();
    }
  }

  setReady() {
    this.updateStarted(true);

    this.updateAvailableQualities();
    this.updateAvailableServers();
    this.updateAvailablePlaybackRates();

    this.video.muted = this.props.muted;
    this.video.volume = this.props.volume / 100.0;
    this.options.callbacks.volumeChanged(this.props.volume, this.props.muted);

    this.loading = false;
    this.state = 'paused';

    this.props.ready = true;
    this.options.callbacks.ready();
  }

  onPlay(event) {
    log('HLSv2', event.type, event);

    if (this.canStopLoading) {
      this.sourceSwitcher.stopReloadVideoFromNextServer();
      this.loading = false;
    }

    this.state = 'playing';

    this.props.ended = false;
    this.props.firstPlay = false;
  }

  onPause(event) {
    log('HLSv2', event.type, event);

    if (this.canStopLoading) {
      this.sourceSwitcher.stopReloadVideoFromNextServer();
      this.loading = false;
    }

    this.state = 'paused';
  }

  onPlaying(event) {
    log('HLSv2', event.type, event);

    if (this.canStopLoading) {
      this.sourceSwitcher.stopReloadVideoFromNextServer();
      this.loading = false;
    }

    this.state = 'playing';
    this.props.ended = false;
  }

  onWaiting(event) {
    log('HLSv2', event.type, this.video.readyState, event);

    this.sourceSwitcher.startLoadingTimeout(this.currentTime);
    this.loading = true;
  }

  onStalled(event) {
    log('HLSv2', 'native', event.type, event);

    if (this.canStopLoading) {
      this.sourceSwitcher.stopReloadVideoFromNextServer();
      this.loading = false;
    }
  }

  onSuspend(event) {
    log('HLSv2', 'native', event.type, event);

    if (this.canStopLoading) {
      this.sourceSwitcher.stopReloadVideoFromNextServer();
      this.loading = false;
    }
  }

  onEnded(event) {
    log('HLSv2', event.type, event);

    if (this.props.liveFinished) {
      log('HLSv2', 'ended by event');

      this.endFinishedLivestreamPlayback();
    } else {
      this.sourceSwitcher.scheduleReloadVideoFromNextServer({ allowSameServer: true });

      this.loading = true;
      this.state = 'playing';
    }
  }

  onSeeking(event) {
    log('HLSv2', event.type, event);

    this.loading = true;
    this.seeking = true;

    this.props.ended = false;

    if (this.options.callbacks.seeking) {
      this.options.callbacks.seeking();
    }
  }

  onSeeked(event) {
    log('HLSv2', event.type, event);

    this.seeking = false;

    if (this.canStopLoading) {
      this.sourceSwitcher.stopReloadVideoFromNextServer();
      this.loading = false;
    }

    this.processTextTracks();

    if (this.options.callbacks.seeked) {
      this.options.callbacks.seeked();
    }
  }

  endFinishedLivestreamPlayback() {
    if (!this.props.ended) {
      this.sourceSwitcher.stopReloadVideoFromNextServer();

      this.props.currentTime = this.props.duration;
      this.props.ended = true;

      this.options.callbacks.ended();
    }
  }

  updateEnded() {
    if (this.props.duration === 0 || !this.props.liveFinished) {
      return false;
    }

    if (Math.abs(this.props.duration - this.props.currentTime) > 500) {
      return false;
    }

    log('HLSv2', 'ended by time');

    this.pause();
    this.endFinishedLivestreamPlayback();

    return true;
  }

  // eslint-disable-next-line no-unused-vars
  onTimeUpdate(event) {
    // log('HLSv2', event.type, event);

    if (this.state === 'playing' && !this.loading && !this.seeking && this.props.programDateTime) {
      this.props.programDateTime += now() - this.props.programDateTimeSetAt;
      this.props.programDateTimeSetAt = now();
    }

    const duration = this.calcDuration();
    const durationChanged = duration !== this.props.duration;

    this.props.duration = duration;
    this.props.currentTime = this.video.currentTime * 1000;

    if (this.updateEnded()) {
      return;
    }

    if (this.ready) {
      this.updateInLivePosition();
    }

    if (durationChanged) {
      this.sourceSwitcher.updateDuration(this.props.duration);
    }
  }

  updateInLivePosition() {
    const inLivePosition = !this.props.liveFinished && this.calcInLivePosition();
    if (inLivePosition !== this.props.inLivePosition || !this.props.inLivePositionInitialCallbackCalled) {
      this.props.inLivePosition = inLivePosition;
      this.props.inLivePositionInitialCallbackCalled = true;
      this.options.callbacks.inLivePositionChanged(this.props.inLivePosition);
    }
  }

  calcInLivePosition() {
    if (this.props.liveFinished) {
      return false;
    }

    return this.props.duration - this.props.currentTime <= this.liveSyncDelta;
  }

  get liveSyncDelta() {
    if (this.hls) {
      return (this.props.duration - this.liveSyncPosition) * 2;
    }

    return 30000;
  }

  onTextTracksAdd(event) {
    log('HLSv2', event.type, event);

    const track = event.track;

    if (track.kind !== 'metadata') {
      if (this.subtitleTextTrack) {
        this.subtitleTextTrack.mode = this.props.subtitleTextTrackMode;
      }

      return;
    }

    track.mode = 'hidden';
    addListener(track, 'cuechange', (e) => this.onTextTrackCueChange(e));

    setInterval(() => {
      if (track.mode !== 'hidden') {
        track.mode = 'hidden';
      }
    }, 1000);
  }

  processTextTracks() {
    for (const track of this.video.textTracks) {
      if (track.kind !== 'metadata') continue;

      for (const cue of track.activeCues) {
        this.processTextTrackCue(cue);
      }
    }
  }

  onTextTrackCueChange(event) {
    // log('HLSv2', event.type, event);

    const track = event.target;
    for (const cue of track.activeCues) {
      this.processTextTrackCue(cue);
    }
  }

  processTextTrackCue(cue) {
    const parsedStringValue = (c) => {
      const array = new Uint8Array(c.value.data);
      return ID3.utf8ArrayToStr(array);
    };

    const parsedIntValue = (c) => parseInt(parsedStringValue(c), 10);

    if (this.seeking) {
      return;
    }

    if (urlParam('debug_hls_id3')) {
      console.log(cue.value.info, cue);
    }

    switch (cue.value.info) {
      case 'com.slideslive.streamStart':
      case 'SL_STAR':
        if (cue.startTime < this.props.streamStartStartTime || cue.endTime > this.props.streamStartEndTime) {
          this.props.streamStartStartTime = cue.startTime;
          this.props.streamStartEndTime = cue.endTime;

          this.props.streamStartRaw = parsedIntValue(cue);
          this.streamStart = parsedIntValue(cue);
        }
        break;
      case 'com.slideslive.currentTime':
      case 'SL_CURR':
        this.timeSinceStreamStart = parsedIntValue(cue);
        break;
      case 'SL_SUBT':
        if (!this.props.useHlsJs) {
          if (!this.isSubtitleInjected(cue.startTime)) {
            this.injectSubtitle(cue.startTime, parsedStringValue(cue), true);
          }
        }
        break;
      case 'SL_PRDT':
        if (cue.endTime - cue.startTime < 1000) {
          this.programDateTime = parsedIntValue(cue);
        }
        break;
      case 'com.slideslive.currentTime2':
      case 'com.slideslive.currentTime3':
      case 'com.slideslive.streamStart2':
        break;
      case 'SlidesLiveStreamMetadata':
        break;
      default:
        console.warn('HLS:', 'Unknown ID3 metadata:', cue);
        break;
    }
  }

  set subtitlesEnabled(enabled) {
    this.props.subtitlesEnabled = enabled;
    this.updateSubtitleTracks();

    if (!enabled) {
      if (this.subtitleTextTrack) {
        this.subtitleTextTrack.mode = 'hidden';
      }
    }
  }

  updateSubtitleTracks() {
    this.props.ccTracks = [];

    if (this.props.subtitlesEnabled) {
      this.props.ccTracks.push({
        name: 'Off',
        track: 'off',
      });
      this.props.ccTracks.push({
        name: 'On',
        track: 'on',
      });

      this.options.callbacks.subtitlesChanged(this.props.ccTracks);

      if (this.props.subtitleTextTrackMode === 'showing') {
        this.setSubtitleTrack(1);
      } else {
        this.setSubtitleTrack(0);
      }
    } else {
      this.options.callbacks.subtitlesChanged(this.props.ccTracks);
    }
  }

  setSubtitleTrack(trackIndex) {
    const track = this.props.ccTracks[trackIndex];
    if (track) {
      if (track.track === 'on') {
        this.props.subtitleTextTrackMode = 'showing';

        if (this.subtitleTextTrack) {
          this.subtitleTextTrack.mode = this.props.subtitleTextTrackMode;
        }
      } else if (track.track === 'off') {
        this.props.subtitleTextTrackMode = 'hidden';

        if (this.subtitleTextTrack) {
          this.subtitleTextTrack.mode = this.props.subtitleTextTrackMode;
        }
      }
    }

    this.props.activeCcTrack = trackIndex;
    this.options.callbacks.activeSubtitlesChanged(this.props.activeCcTrack);
  }

  get subtitleTextTrack() {
    if (!this.video) {
      return null;
    }

    if (!this.props.subtitleTextTrack) {
      let subtitleTextTrack;
      const allTracks = this.video.textTracks;
      for (const track of allTracks) {
        if (track.kind === 'captions') {
          subtitleTextTrack = track;
        }
      }

      if (subtitleTextTrack) {
        log('HLSv2', 'found text track for subtitles');
      } else {
        log('HLSv2', 'creating text track for subtitles');

        subtitleTextTrack = this.video.addTextTrack('captions', 'English', 'en');
      }

      subtitleTextTrack.mode = this.props.subtitleTextTrackMode;

      setInterval(() => {
        subtitleTextTrack.mode = this.props.subtitlesEnabled ? this.props.subtitleTextTrackMode : 'hidden';
      }, 1000);

      this.props.subtitleTextTrack = subtitleTextTrack;
      this.updateSubtitleTracks();
    }

    return this.props.subtitleTextTrack;
  }

  calcDuration() {
    if (this.video.seekable) {
      if (this.video.seekable.length >= 1) {
        const end = this.video.seekable.end(0);
        if (Number.isFinite(end)) {
          return Math.floor(this.video.seekable.end(0) * 1000);
        }
      }
    }

    return Math.floor(this.video.currentTime * 1000);
  }

  get liveSyncPosition() {
    if (this.hls) {
      const hlsPosition = this.hls.liveSyncPosition * 1000;
      if (isVariableDefinedNotNull(hlsPosition) && Number.isFinite(hlsPosition)) {
        return hlsPosition;
      }
    }

    return Math.max(0, this.calcDuration() - 10000);
  }

  get streamStart() {
    return this.props.streamStart;
  }

  set streamStart(time) {
    if (this.props.streamStart !== time) {
      log('HLSv2', 'streamStart changed from', this.streamStart, 'to', time);

      this.props.streamStartSet = true;
      this.props.streamStart = time;
      this.options.callbacks.streamStartChanged(this.props.streamStart);
    }
  }

  get timeSinceStreamStart() {
    return this.props.timeSinceStreamStart;
  }

  set timeSinceStreamStart(time) {
    const previousTimeSinceStreamStartSet = this.props.timeSinceStreamStartSet;

    this.props.timeSinceStreamStartSet = true;
    this.props.timeSinceStreamStart = time;

    this.options.callbacks.timeSinceStreamStartChanged(this.props.timeSinceStreamStart);

    if (!previousTimeSinceStreamStartSet) {
      this.options.callbacks.timesChanged(this.currentTime, this.duration);
    }
  }

  get programDateTime() {
    if (!this.props.programDateTimeSet) return undefined;

    return this.props.programDateTime;
  }

  set programDateTime(time) {
    this.props.programDateTimeSet = true;
    this.props.programDateTime = time;
    this.props.programDateTimeSetAt = now();

    this.options.callbacks.programDateTimeChanged(time);
  }

  get seeking() {
    return this.props.seeking;
  }

  set seeking(seeking) {
    this.props.seeking = seeking;
  }

  set liveFinished(liveFinished) {
    this.props.seekToLiveBeforePlay = false;
    this.props.liveFinished = liveFinished;

    this.updateEnded();
    this.updateInLivePosition();
    this.updateAvailablePlaybackRates();
  }

  set liveSubtitlesDelayMs(liveSubtitlesDelayMs) {
    this.props.liveSubtitlesDelayMs = liveSubtitlesDelayMs;
  }

  get playbackServerIndex() {
    return this.sourceSwitcher.playbackServer;
  }

  updateStarted(started) {
    if (this.props.started === started) {
      return;
    }

    if (this.props.started) {
      return;
    }

    this.loading = false;
    this.props.started = started;
    this.options.callbacks.livestreamStartChanged(this.props.started);
  }

  _availableQualitiesOptions() {
    return Object.values(this.props.availableQualitiesData)
      .sort((a, b) => b.bitrate - a.bitrate)
      .map((q) => [q.name, q.key]);
  }

  get canStopLoading() {
    if (!this.hls) {
      return !this.seeking;
    }

    return !this.seeking;
  }

  get useHlsJs() {
    return this.props.useHlsJs;
  }

  get activeVideoSourceUrl() {
    if (!this.video) return null;
    if (!this.hls) return this.video.src;

    return this.hls.url;
  }

  get activeQualityName() {
    return this.props.currentQualityName;
  }

  get activePlaybackRateValue() {
    return this.video ? this.video.playbackRate : null;
  }
}
