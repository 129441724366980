import log from 'modules/player/log';

import isArray from 'plugins/utilities/is_array';
import isObject from 'plugins/utilities/is_object';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

import LiveImage from './slide/live_image';

const ON_THE_FLY_SLIDES_QUALITIES = [432, 540, 720, 1080, 1440, 2160];

export default class Live {
  constructor(options, callbacks) {
    this.options = options;
    this.callbacks = callbacks;

    this.props = {
      timedSlideIndex: null,
      manualSlideIndex: null,
      ready: false,
      inSync: true,
      quality: 540,
      streamStart: 0,
      delay: 0,
      lastUpdateTime: 0,
      lastUpdateProgramDateTime: 0,
    };

    this.slides = [];
  }

  load() {
    this.callbacks.loadingChanged(false);

    this.props.ready = true;
    this.callbacks.ready();
  }

  updateSlide(time, programDateTime, force = false) {
    if (this.slides.length === 0) {
      return;
    }

    if (!isVariableDefinedNotNull(time) && !isVariableDefinedNotNull(programDateTime)) {
      return;
    }

    this.props.lastUpdateTime = time;
    this.props.lastUpdateProgramDateTime = programDateTime;

    const newSlideIndex = programDateTime
      ? this.slideIndexForProgramDateTime(programDateTime)
      : this.slideIndexForTime(time);

    if (force || newSlideIndex !== this.props.timedSlideIndex) {
      this.props.timedSlideIndex = newSlideIndex;
      this.fireCurrentSlideChanged();
    }
  }

  // eslint-disable-next-line no-unused-vars
  timeForSlideIndex(slideIndex) {
    return 0;
  }

  slideIndexForProgramDateTime(programDateTime) {
    let i = 1;
    while (i < this.slides.length && this.slides[i].programDateTime <= programDateTime) {
      i += 1;
    }

    return i - 1;
  }

  slideIndexForTime(time) {
    let i = 1;
    while (i < this.slides.length && this.slides[i].calcTime(this.props.streamStart, this.props.delay) <= time) {
      i += 1;
    }

    return i - 1;
  }

  // eslint-disable-next-line no-unused-vars
  progressImageUrl(time) {
    return null;
  }

  dataForSlideIndex(index) {
    const slide = this.slides[index];

    return {
      url: slide.url(this.props.quality),
      type: slide.type,
      time: slide.calcTime(this.props.streamStart, this.props.delay),
    };
  }

  fireCurrentSlideChanged() {
    if (!isVariableDefinedNotNull(this.currentSlide)) {
      return;
    }

    log('SLIDES', 'changed', this.currentSlideIndex, this.currentSlideUrl, this.currentSlideTime);

    const currentSlideData = {
      url: this.currentSlideUrl,
      type: 'image',
      time: this.currentSlideTime,
    };

    this.callbacks.currentSlideChanged(this.currentSlideIndex, this.slideCount, currentSlideData);
  }

  setInSync() {
    this.inSync = true;
  }

  set inSync(value) {
    if (this.props.inSync !== value) {
      this.props.inSync = value;

      if (this.props.inSync) {
        this.props.manualSlideIndex = null;
      }

      this.callbacks.inSyncChanged(this.props.inSync);
    }
  }

  get inSync() {
    return this.props.inSync;
  }

  get presentationId() {
    return this.options.presentationId;
  }

  get cacheTimestamp() {
    return this.options.cacheTimestamp || 0;
  }

  get ready() {
    return this.props.ready;
  }

  get slideCount() {
    return this.slides.length;
  }

  get currentSlide() {
    return this.slides[this.currentSlideIndex];
  }

  get currentSlideIndex() {
    if (this.props.inSync || !isVariableDefinedNotNull(this.props.manualSlideIndex)) {
      return this.props.timedSlideIndex;
    }

    return this.props.manualSlideIndex;
  }

  get currentSlideTime() {
    return this.currentSlide ? this.currentSlide.calcTime(this.props.streamStart, this.props.delay) : null;
  }

  get currentSlideType() {
    return this.currentSlide ? this.currentSlide.type : null;
  }

  get currentSlideUrl() {
    return this.currentSlide ? this.currentSlide.url(this.props.quality) : null;
  }

  //

  set streamStart(streamStart) {
    log('SLIDES', 'set stream start', streamStart);

    this.props.streamStart = streamStart;

    this.updateSlide(this.props.lastUpdateTime, this.props.lastUpdateProgramDateTime);
  }

  set delay(delay) {
    log('SLIDES', 'set delay', delay);

    this.props.delay = delay;

    this.updateSlide(this.props.lastUpdateTime, this.props.lastUpdateProgramDateTime);
  }

  newSlidesArray(slides) {
    const result = [];

    for (const slideData of slides) {
      if (!isObject(slideData)) {
        console.warn('slides response array entry is not object!', slideData);
      } else {
        const time = isVariableDefinedNotNull(slideData.time) ? slideData.time : slideData.timestamp;
        const appStart = isVariableDefinedNotNull(slideData.streamStart) ? slideData.streamStart : slideData.app_start;

        const slide = new LiveImage(
          {
            presentationId: this.presentationId,
            cacheTimestamp: this.cacheTimestamp,
            useWebP: this.options.useWebP,
            path: slideData.url || slideData.path,
            time: result.length === 0 ? 0 : time,
            appStart,
            version: slideData.version,
            name: slideData.name,
            bucket: slideData.bucket,
            originalPath: slideData.originalPath,
          },
          {
            loaded: this.callbacks.slideLoaded,
            error: this.callbacks.slideLoaded,
          },
        );

        result.push(slide);
      }
    }

    return result;
  }

  addLivestreamSlides(slides) {
    if (!isArray(slides)) {
      console.warn('SLIDES', 'livestream slides data not an Array', slides);
      return;
    }

    const newSlides = this.newSlidesArray(slides, this.slideCount);

    log('SLIDES', 'new slides', newSlides.length);

    const newSlidesAdded = this.slides.length !== newSlides.length;
    this.slides = newSlides;

    if (newSlidesAdded) {
      this.updateSlide(this.props.lastUpdateTime, this.props.lastUpdateProgramDateTime, true);
    }

    this.callbacks.preloadSlides(this.currentSlide);
  }

  set size(size) {
    const quality = ON_THE_FLY_SLIDES_QUALITIES.find((qualityHeight) => qualityHeight >= size.slidesHeight);
    this.quality = quality.toString();
  }

  get quality() {
    return this.props.quality;
  }

  set quality(quality) {
    if (this.quality !== quality) {
      this.props.quality = quality;
      this.fireCurrentSlideChanged();
    }
  }
}
