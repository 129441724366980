import VideoSlideBase from './video_slide_base';

class OnDemandVideo extends VideoSlideBase {
  constructor(options, callbacks) {
    super(options, callbacks);

    this._videoServers = [];
  }

  _loadVideoPlayer() {
    this._videoPlayer.load(this.options.data.service, this.options.data.id, {
      videoCdnServers: this._videoServers,
    });
  }

  set videoServers(videoServers) {
    this._videoServers = videoServers;
  }

  get videoService() {
    return this.options.data.service;
  }

  get videoId() {
    return this.options.data.id;
  }
}

export default OnDemandVideo;
