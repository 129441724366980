import shaka from 'shaka-player';

import nativeErrorCodeName from '../errors/native_error_code_name';
import videoFormatNotSupportedUserMessage from '../errors/video_format_not_supported_user_message';
import videoLoadingFailedUserMessage from '../errors/video_loading_failed_user_message';
import videoNotFoundUserMessage from '../errors/video_not_found_user_message';
import shakaErrorCategoryName from './shaka_error_category_name';
import shakaErrorCodeName from './shaka_error_code_name';

function createUserMessageArrayForShakaError(error, videoElementError, presentationId) {
  if (
    error.category === shaka.util.Error.Category.NETWORK &&
    error.code === shaka.util.Error.Code.BAD_HTTP_STATUS &&
    error.data[1] === 404
  ) {
    return { userMessageArray: videoNotFoundUserMessage(presentationId), debugDetails: [] };
  }

  let userMessageArray;
  if (
    error.category === shaka.util.Error.Category.MEDIA &&
    error.code === shaka.util.Error.Code.VIDEO_ERROR &&
    error.data[0] === 4
  ) {
    userMessageArray = videoFormatNotSupportedUserMessage(presentationId);
  } else {
    userMessageArray = videoLoadingFailedUserMessage(presentationId);
  }

  const debugDetails = [];

  if (typeof error === 'string') {
    debugDetails.push(error);
  } else {
    const errorCategoryName = shakaErrorCategoryName(error);
    const errorCodeName = shakaErrorCodeName(error);

    debugDetails.push(`${errorCategoryName} (${error.category})`);
    debugDetails.push(`${errorCodeName} (${error.code})`);
  }

  if (videoElementError) {
    debugDetails.push(`${videoElementError.constructor.name}`);

    if (videoElementError.code) {
      debugDetails.push(`${nativeErrorCodeName(videoElementError.code)} (${videoElementError.code})`);
    }

    if (videoElementError.message) {
      debugDetails.push(`${videoElementError.message}`);
    }
  }

  return {
    userMessageArray,
    debugDetails,
  };
}

export default createUserMessageArrayForShakaError;
