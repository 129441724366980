import log from 'modules/player/log';
import VimeoPlayerApiLoader from 'vimeo-player-api-loader';

import inlineCss from 'plugins/element/inline_css';
import defer from 'plugins/utilities/defer';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

const valueOrDefault = (object, key, defaultValue) =>
  isVariableDefinedNotNull(object[key]) ? object[key] : defaultValue;

export default class Vimeo {
  constructor(element, options) {
    this.element = element;
    this.options = options;

    this.props = {
      videoId: null,

      loading: false,
      ready: false,
      currentTime: 0,
      duration: 0,

      state: 'none',

      volume: valueOrDefault(this.options.initial, 'volume', 100),
      muted: valueOrDefault(this.options.initial, 'muted', false),
      playbackRate: valueOrDefault(this.options.initial, 'playbackRate', 1),
      activeCcTrack: valueOrDefault(this.options.initial, 'subtitlesTrack', 0),
      subtitlesLanguage: valueOrDefault(this.options.initial, 'subtitlesLanguage', null),

      ratio: valueOrDefault(this.options.initial, 'ratio', 16 / 9.0),

      availablePlaybackRates: [],
      ccTracks: [],

      finallyLoaded: false,
    };

    this.loadingTimeout = null;
  }

  load(service, videoId) {
    this.loading = true;

    VimeoPlayerApiLoader.load((VimeoSDK) => this.doLoad(VimeoSDK, videoId));
  }

  doLoad(VimeoSDK, videoId) {
    this.loading = true;
    this.props.videoId = videoId;

    const vimeoOptions = {
      loop: false,
      autoplay: false,
      byline: false,
      portrait: false,
      title: false,
      responsive: true,
      speed: true,
      dnt: true,
      controls: true,
    };

    const vimeoOptionsString = Object.entries(vimeoOptions)
      .map((pair) => pair.join('='))
      .join('&');

    const url = `https://player.vimeo.com/video/${videoId}?${vimeoOptionsString}`;

    const iframe = document.createElement('iframe');

    inlineCss(iframe, {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    });

    iframe.setAttribute('allow', 'autoplay');
    iframe.setAttribute('referrerpolicy', 'origin');
    iframe.src = url;

    this.element.insertAdjacentElement('beforeend', iframe);

    this.vimeo = new VimeoSDK.Player(iframe);

    this.vimeo.on('error', (data) => this.onVimeoError(data));
    this.vimeo.on('play', (data) => this.onVimeoPlay(data));
    this.vimeo.on('pause', (data) => this.onVimeoPause(data));
    this.vimeo.on('ended', (data) => this.onVimeoEnded(data));
    this.vimeo.on('loaded', (data) => this.onVimeoLoaded(data));
    this.vimeo.on('seeked', (data) => this.onVimeoSeeked(data));
    this.vimeo.on('timeupdate', (data) => this.onVimeoTimeupdate(data));
    this.vimeo.on('texttrackchange', (data) => this.onVimeoTextTrackChange(data));
    this.vimeo.on('bufferend', () => {
      this.loading = false;
    });
    this.vimeo.on('bufferstart', () => {
      this.loading = true;
    });
    this.vimeo.on('playbackratechange', (data) => {
      this.props.playbackRate = data.playbackRate;
      this.options.callbacks.playbackRateChanged(this.props.playbackRate);
    });

    this.vimeo.ready().catch((error) => {
      console.warn('VIMEO', 'ready error', error);

      this.clearLoadingTimeout();

      this.showError(error);
      this.reportError(error, { source: 'ready_promise' });
    });

    this.loadingTimeout = setTimeout(() => this.showLoadingTimeoutError(), 120000);

    Promise.all([this.vimeo.getVideoWidth(), this.vimeo.getVideoHeight()]).then((dimensions) => {
      const width = dimensions[0];
      const height = dimensions[1];
      const ratio = width / height;

      this.options.callbacks.ratioChanged(ratio);
    });
  }

  showLoadingTimeoutError() {
    const error = { name: 'LoadingTimeout', message: 'Loading timed out after 2 minutes.' };
    this.showError(error);
    this.reportError(error, { source: 'timeout_callback' });
  }

  play() {
    log('VIMEO', 'play request');

    this.vimeo.play().catch((error) => {
      if (error.name === 'PrivacyError') {
        this.showError(error);
        this.reportError(error, { source: 'play' });
      }

      console.warn('VIMEO', 'play error', error);
      this.options.callbacks.playFailed();
    });
  }

  pause() {
    log('VIMEO', 'pause request');

    this.vimeo.pause().catch((error) => {
      if (error.name === 'PrivacyError') {
        this.showError(error);
        this.reportError(error, { source: 'pause' });
      }

      console.warn('VIMEO', 'pause error', error);
    });
  }

  setMuted(muted) {
    if (muted) {
      this.vimeo.setVolume(0);
    } else {
      this.vimeo.setVolume(this.props.volume / 100.0);
    }

    this.props.muted = muted;
    this.options.callbacks.volumeChanged(this.props.volume, this.props.muted);
  }

  seekToLivePosition() {
    console.warn('Not implemented!');
  }

  setSubtitleTrack(track) {
    const ccTrack = this.props.ccTracks[track];

    this.props.activeCcTrack = track;

    if (!ccTrack.track) {
      this.vimeo.disableTextTrack();
    } else {
      this.vimeo.enableTextTrack(ccTrack.track.language);
    }

    this.options.callbacks.activeSubtitlesChanged(this.props.activeCcTrack);
  }

  set volume(value) {
    this.props.volume = value;
    this.props.muted = false;
    this.vimeo.setVolume(this.props.volume / 100.0);

    this.options.callbacks.volumeChanged(this.props.volume, this.props.muted);
  }

  get currentTime() {
    return this.props.currentTime;
  }

  set currentTime(value) {
    this.vimeo.setCurrentTime(value / 1000.0).then((seconds) => {
      this.props.currentTime = seconds * 1000;
    });
  }

  get duration() {
    return this.props.duration;
  }

  set playbackRate(value) {
    this.vimeo.setPlaybackRate(value);
    this.props.playbackRate = value;
    this.options.callbacks.playbackRateChanged(this.props.playbackRate);
  }

  // General

  get inLivePosition() {
    return false;
  }

  get ready() {
    return this.props.ready;
  }

  get loading() {
    return this.props.loading;
  }

  set loading(value) {
    if (value === this.props.loading) {
      return;
    }

    this.props.loading = value;
    this.options.callbacks.loadingChanged(value);
  }

  get state() {
    return this.props.state;
  }

  set state(value) {
    if (value === this.props.state) {
      return;
    }

    this.props.state = value;
    this.options.callbacks.stateChanged(value);
  }

  // Internal

  updateAvailablePlaybackRates() {
    this.vimeo
      .setPlaybackRate(1)
      .then(() => {
        this.props.availablePlaybackRates = [0.5, 1, 1.25, 1.5, 2];
        this.options.callbacks.availablePlaybackRatesChanged(this.props.availablePlaybackRates);

        if (this.props.playbackRate !== 1) {
          this.setPlaybackRate = this.props.playbackRate;
        } else {
          this.options.callbacks.playbackRateChanged(this.props.playbackRate);
        }
      })
      .catch(() => {
        this.options.callbacks.playbackRateChanged(1);
      });
  }

  onVimeoError(data) {
    log('VIMEO', 'error', data);

    if (data.name === 'PrivacyError') {
      this.showError(data);
      this.reportError(data, { source: 'error_callback' });
    }

    if (this.loadingTimeout) {
      clearTimeout(this.loadingTimeout);
      this.loadingTimeout = null;
    }
  }

  onVimeoPlay(data) {
    log('VIMEO', 'play', data);

    this.loading = false;
    this.state = 'playing';
  }

  onVimeoPause(data) {
    log('VIMEO', 'pause', data);

    this.loading = false;
    this.state = 'paused';
  }

  onVimeoEnded(data) {
    log('VIMEO', 'ended', data);

    this.loading = false;
    this.state = 'paused';

    this.options.callbacks.ended();
  }

  onVimeoLoaded(data) {
    log('VIMEO', 'loaded', data);

    this.clearLoadingTimeout();

    this.vimeo.getTextTracks().then((tracks) => {
      let newActiveCcTrack = 0;

      this.props.ccTracks.push({ name: 'Off', track: null });

      for (let i = 0; i < tracks.length; ++i) {
        const track = tracks[i];

        if (this.props.subtitlesLanguage && track.language.split('-')[0] === this.props.subtitlesLanguage) {
          newActiveCcTrack = i + 1;
        }

        this.props.ccTracks.push({ name: track.label, track });
      }

      this.options.callbacks.subtitlesChanged(this.props.ccTracks);
      this.setSubtitleTrack(newActiveCcTrack);
    });

    this.vimeo.getDuration().then((seconds) => {
      this.props.duration = Math.floor(seconds * 1000);

      this.finallyLoaded();
    });
  }

  onVimeoSeeked(data) {
    log('VIMEO', 'seeked', data);

    this.loading = false;
  }

  onVimeoTimeupdate(data) {
    this.props.currentTime = Math.floor(data.seconds * 1000);
    this.props.duration = Math.floor(data.duration * 1000);
  }

  onVimeoTextTrackChange(data) {
    log('VIMEO', 'text track change', data);
  }

  clearLoadingTimeout() {
    if (this.loadingTimeout) {
      clearTimeout(this.loadingTimeout);
      this.loadingTimeout = null;
    }
  }

  finallyLoaded() {
    if (this.props.finallyLoaded) {
      return;
    }

    this.props.finallyLoaded = true;

    this.updateAvailablePlaybackRates();

    if (this.props.muted) {
      this.vimeo.setVolume(0);
    } else {
      this.vimeo.setVolume(this.props.volume / 100.0);
    }

    this.options.callbacks.volumeChanged(this.props.volume, this.props.muted);

    this.options.callbacks.firstPlayHackChanged(true);

    this.loading = false;
    this.state = 'paused';

    defer(() => {
      this.props.ready = true;
      this.options.callbacks.ready();
    });
  }

  showError(error) {
    let errorMessage;

    if (error.name === 'PrivacyError') {
      errorMessage = ['Video is not allowed to be played here.', 'Report this error to support@slideslive.com.'];
    } else if (error.name === 'NotFoundError') {
      errorMessage = ['Video not found.', 'Report this error to support@slideslive.com.'];
    } else if (error.name === 'LoadingTimeout') {
      errorMessage = [
        'Video loading timeout.',
        'Please try again or report this error to support@slideslive.com if it persists.',
      ];
    } else {
      errorMessage = [
        'Video loading failed.',
        'Please try again or report this error to support@slideslive.com if it persists.',
      ];
    }

    errorMessage.push('');
    errorMessage.push(`<span class="text__paragraph--tiniest">User-Agent: ${navigator.userAgent}</span>`);

    errorMessage.push('');
    errorMessage.push(`<span class="text__paragraph--tiniest">${error.name}</span>`);
    errorMessage.push(`<span class="text__paragraph--tiniest">${error.message}</span>`);

    this.options.callbacks.showError(`${errorMessage.join('<br>')}`);
  }

  reportError(error, { source = 'unknown', warn = false } = {}) {
    const reportData = {};

    reportData.warn = warn;
    reportData.source = source;

    reportData.state = {
      video_id: this.props.videoId,
      ready: this.props.ready,
      currentTime: this.currentTime,
      duration: this.duration,
    };

    reportData.error = {
      name: error.name,
      message: error.message,
    };

    if (error.name === 'PrivacyError') {
      this.options.callbacks.reportError('VIMEO_PLAYER', 'PRIVACY_ERROR', reportData);
    } else if (error.name === 'NotFoundError') {
      this.options.callbacks.reportError('VIMEO_PLAYER', 'VIDEO_NOT_FOUND', reportData);
    } else if (error.name === 'LoadingTimeout') {
      this.options.callbacks.reportError('VIMEO_PLAYER', 'SL_LOADING_TIMEOUT', reportData);
    } else {
      this.options.callbacks.reportError('VIMEO_PLAYER', 'OTHER_ERROR', reportData);
    }
  }

  set size(size) {
    this.element.style.width = `${size.w}px`;
    this.element.style.height = `${size.h}px`;
  }
}
