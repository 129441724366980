import log from 'modules/player/log';
import YouTubeIframeLoader from 'youtube-iframe';

import defer from 'plugins/utilities/defer';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

const valueOrDefault = (object, key, defaultValue) =>
  isVariableDefinedNotNull(object[key]) ? object[key] : defaultValue;

export default class Youtube {
  constructor(element, options) {
    this.element = element;
    this.options = options;

    this.props = {
      loading: false,
      ready: false,

      state: '',

      volume: valueOrDefault(this.options.initial, 'volume', 100),
      muted: valueOrDefault(this.options.initial, 'muted', false),
      playbackRate: valueOrDefault(this.options.initial, 'playbackRate', 1),
      quality: valueOrDefault(this.options.initial, 'quality', null),
      activeCcTrack: valueOrDefault(this.options.initial, 'subtitlesTrack', 0),
      subtitlesLanguage: valueOrDefault(this.options.initial, 'subtitlesLanguage', null),

      liveFinished: valueOrDefault(this.options.initial, 'liveFinished', false),

      ratio: valueOrDefault(this.options.initial, 'ratio', 16 / 9.0),

      inLivePosition: true,

      availablePlaybackRates: [1],
      ccTracks: [],

      ccLoaded: false,
      ccModule: '',

      youTubeCcTracks: null,
      slidesLiveSubtitles: null,

      firstPlay: true,

      livePositionOffset: 0,

      videoElementId: this.element.getAttribute('id'),
    };
  }

  load(service, videoId) {
    this.loading = true;

    YouTubeIframeLoader.load((YT) => this.doLoad(YT, videoId));
  }

  loadLive(servers) {
    this.loading = true;

    if (servers.length > 1) {
      console.warn('YOUTUBE', 'more than one servers not supported for YouTube live stream');
    }

    const server = servers[0];

    YouTubeIframeLoader.load((YT) => this.doLoad(YT, server.server_info.video_id));
  }

  doLoad(YT, videoId) {
    this.yt = new YT.Player(this.props.videoElementId, {
      host: 'https://www.youtube-nocookie.com',
      videoId,
      playerVars: {
        enablejsapi: 1,
        origin: window.origin,
        autoplay: 0,
        controls: this.options.originalVideoControls ? 1 : 0,
        disablekb: 1,
        fs: 0,
        iv_load_policy: 3,
        modestbranding: 1,
        playsinline: 1,
        rel: 0,
        start: 0,
        cc_load_policy: 1,
      },
      events: {
        onReady: () => this.ytEventReady(),
        onApiChange: () => this.ytApiChange(),
        onStateChange: (state) => this.ytStateChange(state.data),
        onPlaybackQualityChange: (quality) => this.ytPlaybackQualityChange(quality.data),
        onPlaybackRateChange: (rate) => this.ytPlaybackRateChange(rate.data),
        onError: (error) => {
          log('YOUTUBE', 'error', error);

          this.showError(error, videoId);
          this.reportError(error, videoId);
        },
      },
    });

    this.element = document.getElementById(this.props.videoElementId);
    this.element.setAttribute('allow', 'autoplay');
  }

  play() {
    this.yt.playVideo();
  }

  pause() {
    if (this.props.inLivePosition) {
      this.props.livePositionOffset = 0;

      this.props.inLivePosition = false;
      this.options.callbacks.inLivePositionChanged(this.props.inLivePosition);
    }

    this.yt.pauseVideo();
  }

  setMuted(muted) {
    if (muted) {
      this.yt.setVolume(0);
    } else {
      this.yt.setVolume(this.props.volume);
    }

    this.props.muted = muted;
    this.options.callbacks.volumeChanged(this.props.volume, this.props.muted);
  }

  seekToLivePosition() {
    let seekToDuration;
    const ytDuration = this.yt.getDuration();
    if (ytDuration === null) {
      seekToDuration = (this.currentTime + this.props.livePositionOffset) / 1000.0;
    } else {
      seekToDuration = ytDuration;
    }

    log('YOUTUBE', 'seek to live', ytDuration, seekToDuration);

    this.yt.seekTo(seekToDuration, true);
    this.props.livePositionOffset = 0;
    this.props.inLivePosition = true;
    this.options.callbacks.inLivePositionChanged(this.props.inLivePosition);
  }

  setSubtitleTrack(track) {
    this.props.activeCcTrack = track;
    this.yt.setOption(this.props.ccModule, 'track', this.props.ccTracks[track].track);

    this.options.callbacks.activeSubtitlesChanged(this.props.activeCcTrack);
  }

  set volume(value) {
    this.props.volume = value;
    this.props.muted = false;
    this.yt.setVolume(this.props.volume);

    this.options.callbacks.volumeChanged(this.props.volume, this.props.muted);
  }

  get currentTime() {
    return this.yt && this.yt.getCurrentTime ? this.yt.getCurrentTime() * 1000 : 0;
  }

  set currentTime(value) {
    this.props.livePositionOffset = this.duration - value;
    this.yt.seekTo(value / 1000.0, true);

    this.props.inLivePosition = false;
    this.options.callbacks.inLivePositionChanged(this.props.inLivePosition);
  }

  get duration() {
    if (this.options.live && !this.props.liveFinished) {
      if (this.props.inLivePosition) {
        return this.currentTime;
      }

      return this.currentTime + this.props.livePositionOffset;
    }

    return this.yt.getDuration() * 1000;
  }

  set playbackRate(value) {
    this.yt.setPlaybackRate(value);
    this.props.playbackRate = value;
    this.options.callbacks.playbackRateChanged(this.props.playbackRate);
  }

  // General

  get inLivePosition() {
    return this.props.inLivePosition;
  }

  get ready() {
    return this.props.ready;
  }

  get loading() {
    return this.props.loading;
  }

  set loading(value) {
    if (value === this.props.loading) {
      return;
    }

    this.props.loading = value;
    this.options.callbacks.loadingChanged(value);
  }

  get state() {
    return this.props.state;
  }

  set state(value) {
    if (value === this.props.state) {
      return;
    }

    this.props.state = value;
    this.options.callbacks.stateChanged(value);
  }

  setQuality(quality) {
    log('YOUTUBE', 'request quality', quality);

    this.yt.setPlaybackQuality(quality);

    this.props.quality = quality;
    this.options.callbacks.qualityChanged(quality);
  }

  // Internal

  ytEventReady() {
    this.options.callbacks.inLivePositionChanged(this.props.inLivePosition);

    this.yt.loadModule('captions');

    this.updateAvailablePlaybackRates();
    this.updateQuality();

    this.playbackRate = 1;

    if (this.props.muted) {
      this.yt.setVolume(0);
    } else {
      this.yt.setVolume(this.props.volume);
    }

    this.options.callbacks.volumeChanged(this.props.volume, this.props.muted);

    this.options.callbacks.firstPlayHackChanged(true);

    this.ytStateChange(this.yt.getPlayerState());

    defer(() => {
      this.props.ready = true;
      this.options.callbacks.ready();
    });
  }

  loadSubtitles(subtitles) {
    this.props.slidesLiveSubtitles = subtitles;

    this.tryLoadAllSubtitles();
  }

  ytApiChange() {
    const options = this.yt.getOptions();
    if (options.indexOf('captions') >= 0) {
      this.props.ccModule = 'captions';
    } else if (options.indexOf('cc') >= 0) {
      this.props.ccModule = 'cc';
    }

    if (!this.props.ccModule || this.props.ccLoaded) {
      return;
    }

    const ccOptions = this.yt.getOptions(this.props.ccModule);
    if (!ccOptions || ccOptions.indexOf('tracklist') < 0) {
      return;
    }

    this.props.ccLoaded = true;
    this.props.youTubeCcTracks = this.yt.getOption(this.props.ccModule, 'tracklist');

    this.tryLoadAllSubtitles();
  }

  tryLoadAllSubtitles() {
    if (!this.props.slidesLiveSubtitles) return;
    if (!this.props.youTubeCcTracks) return;

    this.props.ccTracks = [{ name: 'Off', track: {} }];

    for (let i = 0; i < this.props.youTubeCcTracks.length; ++i) {
      const ccTrack = this.props.youTubeCcTracks[i];

      let name = ccTrack.displayName;

      const parts = name.split('-').map((part) => part.trim());
      if (parts.length === 2) {
        name = parts[1];
      }

      this.props.ccTracks.push({ name, track: ccTrack });
    }

    for (let i = 0; i < this.props.slidesLiveSubtitles.length; ++i) {
      const subtitles = this.props.slidesLiveSubtitles[i];

      this.props.ccTracks.push({ name: subtitles.name, track: { languageCode: subtitles.language } });
    }

    let newActiveCcTrack = 0;
    if (this.props.subtitlesLanguage) {
      for (let i = 0; i < this.props.ccTracks.length; ++i) {
        if (
          this.props.ccTracks[i].track.languageCode &&
          this.props.ccTracks[i].track.languageCode.split('-')[0] === this.props.subtitlesLanguage
        ) {
          newActiveCcTrack = i;
        }
      }
    }

    this.options.callbacks.subtitlesChanged(this.props.ccTracks);
    this.setSubtitleTrack(newActiveCcTrack);
  }

  ytStateChange(state) {
    // -1 - unstarted
    //  0 - ended
    //  1 - playing
    //  2 - paused
    //  3 - loading
    //  5 - cued

    log('YOUTUBE', 'state changed', state);

    if (state === 3) {
      this.loading = true;
      return;
    }

    if (state === 1 && this.props.firstPlay) {
      this.props.firstPlay = false;
      this.updateAvailableQualities();
    }

    switch (state) {
      case -1:
        break;
      case 1:
        this.state = 'playing';
        break;
      case 0:
      case 2:
        this.state = 'paused';
        break;
      case 5:
        this.state = 'paused';
        break;
      default:
    }

    this.loading = false;

    if (state === 0) {
      this.options.callbacks.ended();
    }
  }

  ytPlaybackQualityChange(quality) {
    log('YOUTUBE', 'quality changed', quality);

    this.props.quality = quality;
    this.options.callbacks.qualityChanged(quality);
  }

  ytPlaybackRateChange(rate) {
    log('YOUTUBE', 'playback rate changed', rate);
  }

  updateAvailablePlaybackRates() {
    const rates = this.yt.getAvailablePlaybackRates();
    const positiveRates = rates.filter((rate) => rate >= 1);

    this.props.availablePlaybackRates = positiveRates;
    this.options.callbacks.availablePlaybackRatesChanged(this.props.availablePlaybackRates);
  }

  updateAvailableQualities() {
    const ytQualities = this.yt.getAvailableQualityLevels();

    this.props.availableQualities = [];
    for (const ytQuality of ytQualities) {
      const title = this.ytQualityTitle(ytQuality);
      if (title !== null) {
        this.props.availableQualities.push([title, ytQuality]);
      }
    }

    this.options.callbacks.availableQualitiesChanged(this.props.availableQualities);
  }

  ytQualityTitle(quality) {
    switch (quality) {
      case 'hd1080':
        return '1080p';
      case 'hd720':
        return '720p';
      case 'large':
        return '480p';
      case 'medium':
        return '360p';
      case 'small':
        return '240p';
      case 'auto':
        return 'Auto';
      default:
        return null;
    }
  }

  updateQuality() {
    this.yt.setPlaybackQuality('default');
  }

  // eslint-disable-next-line no-unused-vars
  showError(error, videoId) {
    let errorMessage;

    if (error.data === 2) {
      errorMessage = ['Invalid YouTube video ID.', 'Report this error to support@slideslive.com.'];
    } else if (error.data === 5) {
      errorMessage = [
        'Unknown error in YouTube HTML5 player.',
        'Please try again or report this error to support@slideslive.com if it persists.',
      ];
    } else if (error.data === 100) {
      errorMessage = [
        'YouTube video does not exist or its privacy is set to Private.',
        'Report this error to support@slideslive.com.',
      ];
    } else if (error.data === 101 || error.data === 150) {
      errorMessage = [
        'YouTube video does not exist, it is private or its playback is disabled for embeds.',
        'Report this error to support@slideslive.com.',
      ];
    } else {
      errorMessage = [
        `Unknown YouTube error: ${error.data}`,
        'Please try again or report this error to support@slideslive.com if it persists.',
      ];
    }

    errorMessage.push('');
    errorMessage.push(`<span class="text__paragraph--tiniest">User-Agent: ${navigator.userAgent}</span>`);

    this.options.callbacks.showError(`${errorMessage.join('<br>')}`);
  }

  reportError(error, videoId, { source = 'youtube_callback', warn = false } = {}) {
    const reportData = {};

    reportData.warn = warn;
    reportData.source = source;

    reportData.state = {
      video_id: videoId,
      ready: this.props.ready,
      currentTime: this.currentTime,
      duration: this.duration,
    };

    reportData.error = {
      data: error.data,
    };

    if (error.data === 2) {
      this.options.callbacks.reportError('YOUTUBE_PLAYER', 'INVALID_VIDEO', reportData);
    } else if (error.data === 5) {
      this.options.callbacks.reportError('YOUTUBE_PLAYER', 'HTML5_ERROR', reportData);
    } else if (error.data === 100) {
      this.options.callbacks.reportError('YOUTUBE_PLAYER', 'NOT_AVAILABLE', reportData);
    } else if (error.data === 101 || error.data === 150) {
      this.options.callbacks.reportError('YOUTUBE_PLAYER', 'EMBED_NOT_ALLOWED', reportData);
    } else {
      this.options.callbacks.reportError('YOUTUBE_PLAYER', 'OTHER_ERROR', reportData);
    }
  }

  set liveFinished(liveFinished) {
    this.props.liveFinished = liveFinished;

    if (this.props.inLivePosition) {
      this.props.livePositionOffset = 0;
      this.props.inLivePosition = false;
      this.options.callbacks.inLivePositionChanged(this.props.inLivePosition);
    }
  }
}
