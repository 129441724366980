export default class SourceSwitcher {
  constructor(hls, options) {
    this._hls = hls;
    this._options = options;

    this._activeSourceIndex = -1;
    this._sources = [];
    this._reloadTimeout = undefined;

    this._callbacks = {
      activeSourceIndexChanged: new Set(),
      availableSourcesChanged: new Set(),

      sourceUrlChanged: new Set(),
    };
  }

  on(event, callback) {
    if (!this._callbacks[event]) return;

    this._callbacks[event].add(callback);
  }

  reload() {
    if (this._reloadTimeout) {
      clearTimeout(this._reloadTimeout);
      this._reloadTimeout = null;
    }

    this._reloadTimeout = setTimeout(() => {
      if (this.activeSource) this._runCallbacks('sourceUrlChanged', this.activeSource.format, this.activeSource.url);
    }, 5000);
  }

  _runCallbacks(event, ...args) {
    if (!this._callbacks[event]) return;

    for (const callback of this._callbacks[event]) {
      callback(...args);
    }
  }

  get activeSource() {
    return this._sources.find((source) => source.key === this._activeSourceIndex);
  }

  get activeSourceIndex() {
    return this._activeSourceIndex;
  }

  set activeSourceIndex(index) {
    if (index !== this._activeSourceIndex) {
      const previousSource = this.activeSource;

      this._activeSourceIndex = index;
      this._runCallbacks('activeSourceIndexChanged', this._activeSourceIndex);

      const currentSource = this.activeSource;

      if (!currentSource) return;
      if (previousSource && previousSource.key === currentSource.key) return;

      this._runCallbacks('sourceUrlChanged', currentSource.format, currentSource.url);
    }
  }

  get availableSources() {
    return this._sources;
  }

  setSources(sources) {
    const activeSourceKey = this.activeSourceIndex;

    this._sources = sources;
    this._runCallbacks('availableSourcesChanged', this._sources);

    if (this._sources.length > 0) {
      this.activeSourceIndex =
        this._sources.findIndex((source) => source.key === activeSourceKey) >= 0
          ? activeSourceKey
          : this._sources[0].key;
    }
  }
}
