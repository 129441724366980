function createUserMessage(userMessageArray, debugDetails, error) {
  let message = [];

  if (userMessageArray && userMessageArray.length > 0) {
    message = message.concat(userMessageArray);
    message.push('');
  }

  message.push(`<span class="text__paragraph--tiniest">User-Agent: ${navigator.userAgent}</span>`);
  message.push('');

  if (debugDetails && debugDetails.length > 0) {
    message = message.concat(
      debugDetails.map(debugDetail => `<span class="text__paragraph--tiniest">${debugDetail}</span>`),
    );
    message.push('');
  }

  if (error) {
    message.push(`<span class="text__paragraph--tiniest">${JSON.stringify(error)}</span>`);
  }

  return `${message.join('<br>')}`;
}

export default createUserMessage;
