import HlsJsPlayerLoader from 'hls-js-player-loader';
import log from 'modules/player/log';

import inlineCss from 'plugins/element/inline_css';
import addListener from 'plugins/utilities/add_listener';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';
import now from 'plugins/utilities/now';
import urlParam from 'plugins/utilities/url_param';
import valueOrDefault from 'plugins/utilities/value_or_default';

import createUserMessage from './errors/create_user_message';
import createUserMessageArrayForNativeError from './errors/create_user_message_array_for_native_error';
import videoCdnLoadingFailedUserMessage from './errors/video_cdn_loading_failed_user_message';
import videoLoadingFailedUserMessage from './errors/video_loading_failed_user_message';
import videoPlaybackNotSupportedUserMessage from './errors/video_playback_not_supported_user_message';
import createErrorReportData from './hls_live_v2/create_error_report_data';
import SourceSwitcher from './hls_vod_v1/source_switcher';
import createUserMessageArrayForShakaError from './shaka_player/create_user_message_array_for_shaka_error';

const HLS_JS_VERSION = '1.4.0';

const HLS_VOD_JS_MAX_LOADING_RETRIES = 9;
const HLS_VOD_JS_MAX_LOADING_RETRY_TIMEOUT = 2000;
const HLS_VOD_JS_LOADING_RETRY_DELAY = 100;

function createVideoElement() {
  const video = document.createElement('video');
  video.setAttribute('playsinline', 'playsinline');
  video.setAttribute('webkit-playsinline', 'webkit-playsinline');
  video.setAttribute('preload', 'metadata');
  video.setAttribute('crossorigin', 'anonymous');
  video.playsinline = true;
  video['webkit-playsinline'] = true;

  inlineCss(video, {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  });

  return video;
}

function createYodaVideoSourceWithHost(videoId, host) {
  return {
    hls: `https://${host}/${videoId}/master.m3u8`,
    dash: `https://${host}/${videoId}/master.mpd`,
  };
}

const AUTO_QUALITY_ID = -1;
const AUTO_QUALITY = {
  id: AUTO_QUALITY_ID,
  name: 'Auto',
  bitrate: Infinity,
  track: null,
};

export default class HlsVodV1Service {
  constructor(element, options) {
    this.element = element;
    this.options = options;

    this.props = {
      videoSources: [],
      currentVideoSourceIndex: 0,

      loading: false,
      ready: false,
      currentTime: 0,
      duration: 0,

      state: '',

      volume: valueOrDefault(this.options.initial, 'volume', 100),
      muted: valueOrDefault(this.options.initial, 'muted', false),
      playbackRate: valueOrDefault(this.options.initial, 'playbackRate', 1),
      quality: valueOrDefault(this.options.initial, 'quality', null),
      subtitlesTrack: valueOrDefault(this.options.initial, 'subtitlesTrack', null),

      ratio: valueOrDefault(this.options.initial, 'ratio', 16 / 9.0),

      availablePlaybackRates: [1],
      availableQualitiesData: {
        [AUTO_QUALITY_ID]: { ...AUTO_QUALITY },
      },
      currentQuality: AUTO_QUALITY_ID,
      currentQualityName: 'Auto',

      useHlsJs: false,
      seeking: false,
      playOnCanPlay: false,
      seekToFromEndOnCanPlay: null,

      playbackUrl: null,
      availableSources: [],

      hlsJsRecoverMediaErrorCounter: 0,
      hlsJsLastRecoverMediaErrorCallAt: null,
      hlsJsAudioCodecsSwapped: false,
      hlsJsRecoverMediaBufferAppendErrorCounter: 0,
      hlsJsRecoverMediaErrDecodeCounter: 0,
    };
  }

  createHls() {
    this.hls = new this.Hls({
      fragLoadingMaxRetry: HLS_VOD_JS_MAX_LOADING_RETRIES,
      fragLoadingRetryDelay: HLS_VOD_JS_LOADING_RETRY_DELAY,
      fragLoadingMaxRetryTimeout: HLS_VOD_JS_MAX_LOADING_RETRY_TIMEOUT,

      levelLoadingMaxRetry: HLS_VOD_JS_MAX_LOADING_RETRIES,
      levelLoadingRetryDelay: HLS_VOD_JS_LOADING_RETRY_DELAY,
      levelLoadingMaxRetryTimeout: HLS_VOD_JS_MAX_LOADING_RETRY_TIMEOUT,

      manifestLoadingMaxRetry: HLS_VOD_JS_MAX_LOADING_RETRIES,
      manifestLoadingRetryDelay: HLS_VOD_JS_LOADING_RETRY_DELAY,
      manifestLoadingMaxRetryTimeout: HLS_VOD_JS_MAX_LOADING_RETRY_TIMEOUT,

      startFragPrefetch: true,
      debug: !!urlParam('debug_hls_player'),
    });

    this.addHlsJsListeners();
  }

  load(service, videoIdOrVideoSources, videoServiceData) {
    this.loading = true;

    if (service === 'yoda') {
      const cdnServers = videoServiceData && videoServiceData.videoCdnServers;
      if (!cdnServers) {
        this.showError(videoCdnLoadingFailedUserMessage(this.options.presentationId));
        return;
      }

      this.props.videoSources = cdnServers.map((host) => createYodaVideoSourceWithHost(videoIdOrVideoSources, host));
    } else {
      try {
        this.props.videoSources = JSON.parse(videoIdOrVideoSources);
      } catch (_e) {
        this.showError(videoLoadingFailedUserMessage(this.options.presentationId));
        return;
      }
    }

    this.props.currentVideoSourceIndex = 0;

    HlsJsPlayerLoader.load((Hls) => this.doLoad(Hls), HLS_JS_VERSION);
  }

  doLoad(Hls) {
    this.Hls = Hls;

    this.video = createVideoElement();

    this.element.insertAdjacentElement('beforeend', this.video);

    const userAgent = navigator.userAgent.toLowerCase();
    let isSafari = false;
    if (userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') === -1) {
      isSafari = true;
    }

    const canPlayNatively = this.video.canPlayType('application/vnd.apple.mpegurl');
    const forceNative = canPlayNatively && isSafari;

    if (forceNative) {
      log('HLS_VOD_V1', 'force-using native HLS playback.', userAgent, isSafari, canPlayNatively, forceNative);

      this.props.useHlsJs = false;
    } else if (window.Hls.isSupported()) {
      log('HLS_VOD_V1', 'Using HLS.js.', userAgent, isSafari, canPlayNatively, forceNative);

      this.props.useHlsJs = true;
    } else if (this.video.canPlayType('application/vnd.apple.mpegurl')) {
      log(
        'HLS_VOD_V1',
        'HLS.js is not supported, using native HLS playback.',
        userAgent,
        isSafari,
        canPlayNatively,
        forceNative,
      );

      this.props.useHlsJs = false;
    } else {
      console.warn('HLS_VOD_V1', 'not supported');

      this.showError(videoPlaybackNotSupportedUserMessage(this.options.presentationId));
      this.reportError(null, { source: 'load', customErrorName: 'PLAYBACK_NOT_SUPPORTED' });

      return;
    }

    this.addVideoListeners();
    this.sourceSwitcher = new SourceSwitcher(
      this,
      {
        usingHlsJs: this.props.useHlsJs,
      },
      {
        changePlaybackUrl: (url, forcePlay) => this.changePlaybackUrl(url, forcePlay),
        sourcesChanged: () => this.updateAvailableSources(),
      },
    );

    this.sourceSwitcher.updateSources(this.props.videoSources);
  }

  showError(userMessageArray, debugDetails, error) {
    this.options.callbacks.showError(createUserMessage(userMessageArray, debugDetails, error));
  }

  showNativeError(error) {
    console.warn('SHAKA', this.props.debugId, 'native error', error);

    const { userMessageArray, debugDetails } = createUserMessageArrayForNativeError(error, this.options.presentationId);
    this.showError(userMessageArray, debugDetails, error);
  }

  showShakaError(error) {
    const { userMessageArray, debugDetails } = createUserMessageArrayForShakaError(
      error,
      this.video.error,
      this.options.presentationId,
    );
    this.showError(userMessageArray, debugDetails, error);
  }

  reportError(error, { source, nativeError, customErrorName = null } = {}) {
    const { errorName, reportData } = createErrorReportData(this, error, {
      source,
      nativeError,
      customErrorName,
    });

    if (this.options.callbacks.reportError) {
      this.options.callbacks.reportError('HLS_VOD_V1_PLAYER', errorName, reportData);
    } else {
      console.warn('HLS_VOD_V1_PLAYER', 'report error', errorName, reportData);
    }
  }

  handleHlsJsError(error) {
    if (error.fatal) {
      if (error.type !== Hls.ErrorTypes.NETWORK_ERROR) {
        console.warn('HLS_VOD_V1', 'js', 'fatal error', error);
      }
    } else {
      console.warn('HLS_VOD_V1', 'js', 'error', error);
    }

    if (error.type === Hls.ErrorTypes.MEDIA_ERROR) {
      if (error.details === Hls.ErrorDetails.BUFFER_APPEND_ERROR && error.err && error.err.code === 11) {
        // workaround for videos getting stuck in Google Chrome
        console.warn('HLS_VOD_V1', 'js', 'attempting to recover from media buffer append error by reloading video');
        this.sourceSwitcher.reloadVideo();
        this.props.hlsJsRecoverMediaBufferAppendErrorCounter += 1;
        return;
      }

      if (error.fatal) {
        const lastErrorLongAgo =
          !this.props.hlsJsLastRecoverMediaErrorCallAt || now() - this.props.hlsJsLastRecoverMediaErrorCallAt >= 5000;

        if (lastErrorLongAgo) {
          console.warn('HLS_VOD_V1', 'js', 'attempting to recover from fatal media error');
          this.hls.recoverMediaError();

          this.props.hlsJsRecoverMediaErrorCounter += 1;
          this.props.hlsJsLastRecoverMediaErrorCallAt = now();

          return;
        }

        if (!this.props.hlsJsAudioCodecsSwapped) {
          console.warn('HLS_VOD_V1', 'js', 'attempting to recover from fatal media error with audio codec swap');

          this.hls.swapAudioCodec();
          this.hls.recoverMediaError();

          this.props.hlsJsRecoverMediaErrorCounter += 1;
          this.props.hlsJsAudioCodecsSwapped = true;
          this.props.hlsJsLastRecoverMediaErrorCallAt = now();

          return;
        }
      }
    }

    if (error.type === Hls.ErrorTypes.NETWORK_ERROR) {
      if (error.fatal) {
        if (
          error.details === Hls.ErrorDetails.MANIFEST_LOAD_ERROR ||
          error.details === Hls.ErrorDetails.MANIFEST_LOAD_TIMEOUT ||
          error.details === Hls.ErrorDetails.MANIFEST_PARSING_ERROR
        ) {
          this.sourceSwitcher.scheduleReloadVideoFromNextSource();
          return;
        }

        log('HLS_VOD_V1', 'js', 'attempting to recover from fatal network error');
        this.hls.startLoad();

        return;
      }
    }

    if (error.fatal) {
      this.reportError(error, {
        source: 'hls_js_listener',
        nativeError: false,
      });
    }
  }

  handleNativeError(error) {
    log('HLS_VOD_V1', 'native', 'error', error);

    if (!error) {
      return;
    }

    // MediaError - https://developer.mozilla.org/en-US/docs/Web/API/MediaError
    // error.code === 1: MEDIA_ERR_ABORTED
    // error.code === 2: MEDIA_ERR_NETWORK
    // error.code === 3: MEDIA_ERR_DECODE
    // error.code === 4: MEDIA_ERR_SRC_NOT_SUPPORTED

    // networkState - https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/networkState
    // video.networkState === 0: NETWORK_EMPTY
    // video.networkState === 1: NETWORK_IDLE
    // video.networkState === 2: NETWORK_LOADING
    // video.networkState === 3: NETWORK_NO_SOURCE

    // workaround for videos getting stuck in Google Chrome
    if (this.props.useHlsJs && error.code === 3) {
      console.warn('HLS_VOD_V1', 'native', 'attempting to recover from MEDIA_ERR_DECODE by reloading video');

      setTimeout(() => {
        this.loading = true;

        this.currentTime += 2000;
        this.sourceSwitcher.reloadVideo();
      }, 0);

      this.props.hlsJsRecoverMediaErrDecodeCounter += 1;
      return;
    }

    if (this.props.useHlsJs) {
      return;
    }

    if (this.video.networkState === 3) {
      this.sourceSwitcher.scheduleReloadVideoFromNextSource();
      return;
    }

    console.warn('HLS_VOD_V1', 'native', 'error', error);

    this.reportError(error, { source: 'native_listener', nativeError: true });
  }

  addVideoListeners() {
    addListener(this.video, 'error', () => this.handleNativeError(this.video.error));

    addListener(this.video, 'load', (event) => log('HLS_VOD_V1', event.type, event));
    addListener(this.video, 'loadend', (event) => log('HLS_VOD_V1', event.type, event));
    addListener(this.video, 'loadstart', (event) => log('HLS_VOD_V1', event.type, event));

    addListener(this.video, 'offline', (event) => log('HLS_VOD_V1', event.type, event));
    addListener(this.video, 'online', (event) => log('HLS_VOD_V1', event.type, event));

    addListener(this.video, 'readystatechange', (event) => log('HLS_VOD_V1', event.type, event));

    addListener(this.video, 'stalled', (event) => this.onStalled(event));
    addListener(this.video, 'suspend', (event) => this.onSuspend(event));

    // addListener(this.video, 'progress', event => log('HLS_VOD_V1', event.type, event));

    addListener(this.video, 'emptied', (event) => log('HLS_VOD_V1', event.type, event));

    addListener(this.video, 'ended', (event) => this.onEnded(event));

    addListener(this.video, 'loadedmetadata', (event) => this.onLoadedMetadata(event));
    addListener(this.video, 'loadeddata', (event) => this.onLoadedData(event));

    addListener(this.video, 'canplay', (event) => this.onCanPlay(event));
    addListener(this.video, 'play', (event) => this.onPlay(event));
    addListener(this.video, 'pause', (event) => this.onPause(event));

    addListener(this.video, 'playing', (event) => this.onPlaying(event));
    addListener(this.video, 'waiting', (event) => this.onWaiting(event));
    addListener(this.video, 'seeking', (event) => this.onSeeking(event));
    addListener(this.video, 'seeked', (event) => this.onSeeked(event));

    addListener(this.video, 'timeupdate', (event) => this.onTimeUpdate(event));

    addListener(this.video.textTracks, 'addtrack', (event) => this.onTextTracksAdd(event));
  }

  addHlsJsListeners() {
    this.hls.on(Hls.Events.ERROR, (event, data) => this.handleHlsJsError(data));

    this.hls.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
      log('HLS_VOD_V1', 'level switched', event, data);

      if (this.props.currentQuality === AUTO_QUALITY_ID) {
        let currentQualityName = 'Auto';
        if (this.hls.levels[data.level] && this.hls.levels[data.level].height > 0) {
          currentQualityName += ` (${this.hls.levels[data.level].height}p)`;
        }

        // eslint-disable-next-line dot-notation
        this.props.availableQualitiesData[AUTO_QUALITY_ID].name = currentQualityName;
        this.props.currentQualityName = currentQualityName;

        if (this.options.callbacks.availableQualitiesChanged) {
          this.options.callbacks.availableQualitiesChanged(this._availableQualities());
        }
      } else {
        const qualityData = this.props.availableQualitiesData[data.level];
        if (qualityData) {
          this.props.currentQuality = qualityData.id;
          this.props.currentQualityName = qualityData.name;

          if (this.options.callbacks.qualityChanged) {
            this.options.callbacks.qualityChanged(this.props.currentQuality);
          }
        }
      }
    });
  }

  play() {
    log('HLS_VOD_V1', 'play request');

    const promise = this.video.play();
    if (isVariableDefinedNotNull(promise)) {
      promise
        .then(() => {
          this.props.lastPlayFailed = false;
        })
        .catch((error) => {
          console.warn('HLS_VOD_V1', 'play failed', error);

          this.options.callbacks.playFailed();
          this.props.lastPlayFailed = true;

          this.loading = false;
          this.state = 'paused';
        });
    }
  }

  pause() {
    log('HLS_VOD_V1', 'pause request');

    this.video.pause();
  }

  setMuted(muted) {
    this.video.muted = muted;
    this.props.muted = muted;
    this.options.callbacks.volumeChanged(this.props.volume, this.props.muted);
  }

  seekToLivePosition() {
    console.warn('HLS_VOD_V1', 'seek to live position is not implemented');
  }

  setQuality(quality) {
    if (this.props.currentQuality === AUTO_QUALITY_ID) {
      // eslint-disable-next-line dot-notation
      this.props.availableQualitiesData[AUTO_QUALITY_ID].name = 'Auto';

      if (this.options.callbacks.availableQualitiesChanged) {
        this.options.callbacks.availableQualitiesChanged(this._availableQualities());
      }
    }

    let qualityData = this.props.availableQualitiesData[quality];
    if (!qualityData) {
      // eslint-disable-next-line dot-notation
      qualityData = this.props.availableQualitiesData[AUTO_QUALITY_ID];
    }

    this.hls.nextLevel = qualityData.id;
    this.props.currentQuality = qualityData.id;
    this.props.currentQualityName = qualityData.name;

    if (this.options.callbacks.qualityChanged) {
      this.options.callbacks.qualityChanged(this.props.currentQuality);
    }
  }

  destroy() {
    if (this.props.useHlsJs) {
      if (this.hls) {
        this.hls.detachMedia();
        this.hls.destroy();
      }
    }
  }

  set volume(value) {
    this.props.volume = value;

    this.video.muted = false;
    this.video.volume = this.props.volume / 100.0;

    this.options.callbacks.volumeChanged(this.props.volume, this.props.muted);
  }

  get currentTime() {
    return this.props.currentTime;
  }

  set currentTime(value) {
    this.props.currentTime = value;
    this.video.currentTime = value / 1000.0;
  }

  get currentStreamTime() {
    return this.currentTime;
  }

  get duration() {
    return this.props.duration;
  }

  set playbackRate(value) {
    this.video.playbackRate = value;
    this.props.playbackRate = value;
    this.options.callbacks.playbackRateChanged(this.props.playbackRate);
  }

  // General

  get inLivePosition() {
    return false;
  }

  get ready() {
    return this.props.ready;
  }

  get loading() {
    return this.props.loading;
  }

  set loading(value) {
    if (value === this.props.loading) {
      return;
    }

    this.props.loading = value;
    this.options.callbacks.loadingChanged(value);
  }

  get state() {
    return this.props.state;
  }

  set state(value) {
    if (value === this.props.state) {
      return;
    }

    this.props.state = value;
    this.options.callbacks.stateChanged(value);
  }

  get seeking() {
    return this.props.seeking;
  }

  set seeking(seeking) {
    this.props.seeking = seeking;
  }

  // Internal

  changePlaybackUrl(url, forcePlay) {
    this.props.playbackUrl = url;

    log('HLS_VOD_V1', 'loading video from', this.props.playbackUrl);

    if (isVariableDefinedNotNull(forcePlay)) {
      this.props.playOnCanPlay = forcePlay;
    } else if (this.props.state === 'playing') {
      this.props.playOnCanPlay = true;
    }

    this.loading = true;
    // this.props.seekToFromEndOnCanPlay = this.duration - this.currentTime;

    if (this.options.callbacks.sourceChanged) {
      this.options.callbacks.sourceChanged(this.sourceSwitcher.sourceIndex);
    }

    this.loadPlaybackUrl();
  }

  loadPlaybackUrl() {
    if (this.props.useHlsJs) {
      if (this.hls) {
        this.hls.detachMedia();
        this.hls.destroy();
      }

      this.props.hlsJsLastRecoverMediaErrorCallAt = null;
      this.props.hlsJsAudioCodecsSwapped = false;

      this.props.currentQualityName = 'Auto';
      this.props.currentQuality = AUTO_QUALITY_ID;

      if (this.options.callbacks.qualityChanged) {
        this.options.callbacks.qualityChanged(this.props.currentQuality);
      }

      this.createHls();
      this.hls.loadSource(this.props.playbackUrl);
      this.hls.attachMedia(this.video);
    } else {
      this.video.src = this.props.playbackUrl;
    }
  }

  updateAvailablePlaybackRates() {
    this.props.availablePlaybackRates = [0.5, 1, 1.2, 1.45, 1.7, 2];

    if (this.options.callbacks.availablePlaybackRatesChanged) {
      this.options.callbacks.availablePlaybackRatesChanged(this.props.availablePlaybackRates);
    }

    if (this.options.callbacks.playbackRateChanged) {
      this.options.callbacks.playbackRateChanged(this.props.playbackRate);
    }
  }

  updateAvailableQualities() {
    if (!this.hls) {
      this.props.currentQualityName = 'Auto';
      this.props.currentQuality = AUTO_QUALITY_ID;
      this.props.availableQualitiesData = {};

      if (this.options.callbacks.availableQualitiesChanged) {
        this.options.callbacks.availableQualitiesChanged(this._availableQualities());
      }

      if (this.options.callbacks.qualityChanged) {
        this.options.callbacks.qualityChanged(this.props.currentQuality);
      }

      return;
    }

    let quality = AUTO_QUALITY_ID;
    const availableQualitiesData = {
      [AUTO_QUALITY_ID]: { ...AUTO_QUALITY },
    };

    for (const [id, track] of Object.entries(this.hls.levels)) {
      const name = `${track.height}p`;
      // const id = track.id;
      const bitrate = track.bitrate;

      availableQualitiesData[id] = {
        id,
        name,
        bitrate,
        track,
      };

      if (track.active) {
        quality = name;
      }
    }

    this.props.currentQualityName = quality;

    if (this.props.currentQuality !== AUTO_QUALITY_ID) {
      this.props.currentQuality = quality;
    }

    this.props.availableQualitiesData = availableQualitiesData;

    if (this.options.callbacks.availableQualitiesChanged) {
      this.options.callbacks.availableQualitiesChanged(this._availableQualities());
    }

    if (this.options.callbacks.qualityChanged) {
      this.options.callbacks.qualityChanged(this.props.currentQuality);
    }
  }

  updateAvailableSources() {
    this.props.availableSources = this.sourceSwitcher.availableSources;

    if (this.options.callbacks.availableSourcesChanged) {
      this.options.callbacks.availableSourcesChanged(this.props.availableSources);
    }
  }

  onLoadedMetadata(event) {
    log('HLS_VOD_V1', event.type, event);

    if (!this.props.useHlsJs) {
      this.customCanPlay();
    }
  }

  onLoadedData(event) {
    log('HLS_VOD_V1', event.type, event);

    if (!this.props.useHlsJs) {
      this.customCanPlay();
    }
  }

  onCanPlay(event) {
    log('HLS_VOD_V1', event.type, event);

    this.customCanPlay();
  }

  customCanPlay() {
    log('HLS_VOD_V1', 'customCanPlay');

    this.sourceSwitcher.stopReloadVideoFromNextSource();

    let timesChanged = false;
    if (!this.ready || this.props.duration === 0) {
      timesChanged = true;
      this.props.duration = this.calcDuration();
      this.currentTime = 0;
    }

    if (isVariableDefinedNotNull(this.props.seekToFromEndOnCanPlay)) {
      timesChanged = true;
      this.currentTime = Math.min(this.duration, this.duration - this.props.seekToFromEndOnCanPlay);
    }

    if (timesChanged) {
      this.options.callbacks.timesChanged(this.currentTime, this.duration);
    }

    this.props.seekToFromEndOnCanPlay = null;

    if (this.props.playOnCanPlay) {
      this.play();
    } else if (this.ready) {
      if (this.canStopLoading) {
        this.loading = false;
      }

      if (this.video.paused) {
        this.state = 'paused';
      } else {
        this.state = 'playing';
      }
    }

    this.props.playOnCanPlay = false;

    if (!this.ready) {
      this.setReady();
    }
  }

  setReady() {
    this.updateAvailableQualities();
    this.updateAvailablePlaybackRates();

    this.video.muted = this.props.muted;
    this.video.volume = this.props.volume / 100.0;
    this.options.callbacks.volumeChanged(this.props.volume, this.props.muted);

    this.loading = false;
    this.state = 'paused';

    this.props.ready = true;
    this.options.callbacks.ready();
  }

  onPlay(event) {
    log('HLS_VOD_V1', event.type, event);

    if (this.canStopLoading) {
      this.sourceSwitcher.stopReloadVideoFromNextSource();
      this.loading = false;
    }

    this.state = 'playing';

    this.props.ended = false;
    this.props.firstPlay = false;
  }

  onPause(event) {
    log('HLS_VOD_V1', event.type, event);

    if (this.canStopLoading) {
      this.sourceSwitcher.stopReloadVideoFromNextSource();
      this.loading = false;
    }

    this.state = 'paused';
  }

  onPlaying(event) {
    log('HLS_VOD_V1', event.type, event);

    if (this.canStopLoading) {
      this.sourceSwitcher.stopReloadVideoFromNextSource();
      this.loading = false;
    }

    this.state = 'playing';
    this.props.ended = false;
  }

  onWaiting(event) {
    log('HLS_VOD_V1', event.type, this.video.readyState, event);

    this.sourceSwitcher.startLoadingTimeout(this.currentTime);
    this.loading = true;
  }

  onStalled(event) {
    log('HLS_VOD_V1', 'native', event.type, event);

    if (this.canStopLoading) {
      this.sourceSwitcher.stopReloadVideoFromNextSource();
      this.loading = false;
    }
  }

  onSuspend(event) {
    log('HLS_VOD_V1', 'native', event.type, event);

    if (this.canStopLoading) {
      this.sourceSwitcher.stopReloadVideoFromNextSource();
      this.loading = false;
    }
  }

  onEnded(event) {
    log('HLS_VOD_V1', event.type, event);
  }

  onSeeking(event) {
    log('HLS_VOD_V1', event.type, event);

    this.loading = true;
    this.seeking = true;

    this.props.ended = false;
  }

  onSeeked(event) {
    log('HLS_VOD_V1', event.type, event);

    this.seeking = false;

    if (this.canStopLoading) {
      this.sourceSwitcher.stopReloadVideoFromNextSource();
      this.loading = false;
    }
  }

  // eslint-disable-next-line no-unused-vars
  onTimeUpdate(event) {
    // log('HLS_VOD_V1', event.type, event);

    this.props.duration = this.calcDuration();
    this.props.currentTime = this.video.currentTime * 1000;
  }

  onTextTracksAdd(event) {
    log('HLS_VOD_V1', event.type, event);

    const track = event.track;

    if (track.kind !== 'metadata') {
      this.subtitleTextTrack.mode = this.props.subtitleTextTrackMode;
      return;
    }

    track.mode = 'hidden';
    addListener(track, 'cuechange', (e) => this.onTextTrackCueChange(e));

    setInterval(() => {
      if (track.mode !== 'hidden') {
        track.mode = 'hidden';
      }
    }, 1000);
  }

  set subtitlesEnabled(enabled) {
    this.props.subtitlesEnabled = enabled;
    this.updateSubtitleTracks();

    if (!enabled) {
      if (this.subtitleTextTrack) {
        this.subtitleTextTrack.mode = 'hidden';
      }
    }
  }

  updateSubtitleTracks() {
    this.props.ccTracks = [];

    if (this.props.subtitlesEnabled) {
      this.props.ccTracks.push({
        name: 'Off',
        track: 'off',
      });
      this.props.ccTracks.push({
        name: 'On',
        track: 'on',
      });

      this.options.callbacks.subtitlesChanged(this.props.ccTracks);

      if (this.props.subtitleTextTrackMode === 'showing') {
        this.setSubtitleTrack(1);
      } else {
        this.setSubtitleTrack(0);
      }
    } else {
      this.options.callbacks.subtitlesChanged(this.props.ccTracks);
    }
  }

  setSubtitleTrack(trackIndex) {
    const track = this.props.ccTracks[trackIndex];
    if (track) {
      if (track.track === 'on') {
        this.props.subtitleTextTrackMode = 'showing';
        this.subtitleTextTrack.mode = this.props.subtitleTextTrackMode;
      } else if (track.track === 'off') {
        this.props.subtitleTextTrackMode = 'hidden';
        this.subtitleTextTrack.mode = this.props.subtitleTextTrackMode;
      }
    }

    this.props.activeCcTrack = trackIndex;
    this.options.callbacks.activeSubtitlesChanged(this.props.activeCcTrack);
  }

  get subtitleTextTrack() {
    if (!this.video) {
      return null;
    }

    if (!this.props.subtitleTextTrack) {
      let subtitleTextTrack;
      const allTracks = this.video.textTracks;
      for (const track of allTracks) {
        if (track.kind === 'captions') {
          subtitleTextTrack = track;
        }
      }

      if (subtitleTextTrack) {
        log('HLS_VOD_V1', 'found text track for subtitles');
      } else {
        log('HLS_VOD_V1', 'creating text track for subtitles');

        subtitleTextTrack = this.video.addTextTrack('captions', 'English', 'en');
      }

      subtitleTextTrack.mode = this.props.subtitleTextTrackMode;

      setInterval(() => {
        subtitleTextTrack.mode = this.props.subtitlesEnabled ? this.props.subtitleTextTrackMode : 'hidden';
      }, 1000);

      this.props.subtitleTextTrack = subtitleTextTrack;
      this.updateSubtitleTracks();
    }

    return this.props.subtitleTextTrack;
  }

  calcDuration() {
    return this.video.duration * 1000;
  }

  setStartTime(time) {
    this.props.startTime = time;
  }

  get canStopLoading() {
    if (!this.hls) {
      return !this.seeking;
    }

    return !this.seeking;
  }

  _availableQualities() {
    return Object.values(this.props.availableQualitiesData)
      .sort((a, b) => b.bitrate - a.bitrate)
      .map((q) => [q.name, q.id]);
  }
}
