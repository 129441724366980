import defer from 'plugins/utilities/defer';

import Slide from './slide';

class LiveImage extends Slide {
  constructor(options, callbacks) {
    super('image', options, callbacks);
  }

  calcOffset(streamStart, delay) {
    return this.appStart - streamStart + delay;
  }

  calcTime(streamStart, delay) {
    const offset = this.calcOffset(streamStart, delay);

    return this.time + offset;
  }

  slideHost() {
    return gon.hosts.slideslive_on_the_fly_resized_slides_host;
  }

  format() {
    return this.options.useWebP ? 'webp' : 'jpeg';
  }

  url(size) {
    if (!this.options.bucket || this.options.bucket === '') {
      return `${this.options.path}?${this.options.cacheTimestamp}`;
    }

    const presentationIdFromOriginalPath = this.options.originalPath?.split('/')[0] || this.options.presentationId;
    const path = [presentationIdFromOriginalPath, 'slides', this.options.name];
    const params = [`h=${size}`, `f=${this.format()}`, 's=lambda', 'accelerate_s3=1'];

    return `https://${this.slideHost()}/${path.join('/')}?${params.join('&')}`;
  }

  beforeCallbackCalled() {
    this.isLoaded = true;
    this.isLoading = false;

    this.element.onload = null;
    this.element.onerror = null;
  }

  load(quality) {
    if (this.isLoading) return;

    if (this.isLoaded) {
      defer(() => this.callbacks.loaded(this.element));
      return;
    }

    super.load(quality);

    this.element = new Image();

    this.element.onload = () => {
      this.beforeCallbackCalled();
      this.callbacks.loaded(this.element);
    };

    this.element.onerror = () => {
      this.beforeCallbackCalled();
      this.callbacks.error(this.element, 'Something went wrong.');
    };

    this.element.src = this.url(quality);
  }

  get time() {
    return this.options.time;
  }

  get appStart() {
    return this.options.appStart;
  }

  get version() {
    return this.options.version;
  }

  get programDateTime() {
    return this.appStart + this.time;
  }
}

export default LiveImage;
