import shaka from 'shaka-player';

function shakaErrorSeverityName(error) {
  for (const k in shaka.util.Error.Severity) {
    if (shaka.util.Error.Severity[k] === error.severity) {
      return k;
    }
  }

  return `UNKNOWN (${error.severity})`;
}

export default shakaErrorSeverityName;
