import defer from 'plugins/utilities/defer';

import Slide from './slide';

class OnDemandImage extends Slide {
  constructor(options, callbacks) {
    super('image', options, callbacks);
  }

  // eslint-disable-next-line no-unused-vars
  format(size) {
    if (this.options.local) {
      return 'png';
    }

    return this.options.useWebP ? 'webp' : 'jpeg';
  }

  url(size = 'medium') {
    if (this.options.local) {
      return `data/${this.options.presentationId}/slides/${this.options.data.name}.${this.format(size)}`;
    }

    const extname = this.options.data.extname || '.png';
    const path = [this.options.presentationId, 'slides', `${this.options.data.name}${extname}`];
    const params = [`h=${size}`, `f=${this.format(size)}`, 's=lambda', 'accelerate_s3=1'];

    return `https://${this.options.slideHost}/${path.join('/')}?${params.join('&')}`;
  }

  progressImageUrl(size = 'medium') {
    return this.url(size);
  }

  beforeCallbackCalled() {
    this.isLoaded = true;
    this.isLoading = false;

    this.element.onload = null;
    this.element.onerror = null;
  }

  load(quality) {
    if (this.loadingQuality(quality)) return;

    if (this.loadedQuality(quality)) {
      defer(() => this.callbacks.loaded(this.element));
      return;
    }

    super.load(quality);

    this.element = new Image();
    this.element.alt = 'Image slide';

    if (!this.options.local) {
      this.element.crossOrigin = 'anonymous';
    }

    const onLoad = () => {
      if (this.width + this.height === 0) {
        setTimeout(onLoad, 25);
        return;
      }

      this.beforeCallbackCalled();
      this.callbacks.loaded(this.element);
    };

    this.element.onload = onLoad;

    this.element.onerror = () => {
      this.beforeCallbackCalled();
      this.callbacks.error(this.element, 'Something went wrong.');
    };

    this.element.src = this.url(quality);
  }
}

export default OnDemandImage;
