import initShakaPlayerMux from '@mux/mux-data-shakaplayer';
import log from 'modules/player/log';

import inlineCss from 'plugins/element/inline_css';
import addListener from 'plugins/utilities/add_listener';
import generateRandomId from 'plugins/utilities/generate_random_id';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';
import urlParam from 'plugins/utilities/url_param';
import valueOrDefault from 'plugins/utilities/value_or_default';
import shaka from 'shaka-player';

import createUserMessage from './errors/create_user_message';
import createUserMessageArrayForNativeError from './errors/create_user_message_array_for_native_error';
import nativeVideoReadyStateName from './errors/native_video_ready_state_name';
import noSuitableVideoFormatUserMessage from './errors/no_suitable_video_format_user_message';
import videoCdnLoadingFailedUserMessage from './errors/video_cdn_loading_failed_user_message';
import videoLoadingFailedUserMessage from './errors/video_loading_failed_user_message';
import videoPlaybackNotSupportedUserMessage from './errors/video_playback_not_supported_user_message';
import createErrorReportData from './shaka_player/create_error_report_data';
import createSegmentStatsFromNetworkResponse from './shaka_player/create_segment_stats_from_network_response';
import createUserMessageArrayForShakaError from './shaka_player/create_user_message_array_for_shaka_error';
import debugInfoFromShakaStats from './shaka_player/debug_info_from_shaka_stats';

function createVideoElement() {
  const video = document.createElement('video');
  video.setAttribute('playsinline', 'playsinline');
  video.setAttribute('webkit-playsinline', 'webkit-playsinline');
  video.setAttribute('preload', 'metadata');
  video.setAttribute('crossorigin', 'anonymous');
  video.playsinline = true;
  video['webkit-playsinline'] = true;

  inlineCss(video, {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  });

  return video;
}

function createYodaVideoSourceWithHost(videoId, host) {
  return {
    hls: `https://${host}/${videoId}/master.m3u8`,
    dash: `https://${host}/${videoId}/master.mpd`,
  };
}

export default class ShakaService {
  constructor(element, options) {
    this.element = element;
    this.options = options;

    this.shaka = null;
    this.shakaPlayerMux = null;

    this.props = {
      preferDash: false,

      videoSources: [],
      currentVideoSourceIndex: 0,

      playerInitTime: null,
      loading: true,
      ready: false,
      currentTime: 0,
      duration: 0,

      state: '',

      volume: valueOrDefault(this.options.initial, 'volume', 100),
      muted: valueOrDefault(this.options.initial, 'muted', false),
      playbackRate: valueOrDefault(this.options.initial, 'playbackRate', 1),
      currentQuality: valueOrDefault(this.options.initial, 'quality', 'auto'),
      activeCcTrack: valueOrDefault(this.options.initial, 'subtitlesTrack', 0),
      subtitlesLanguage: valueOrDefault(this.options.initial, 'subtitlesLanguage', null),

      ratio: valueOrDefault(this.options.initial, 'ratio', 16 / 9.0),

      availablePlaybackRates: [1],
      availableQualitiesData: {
        auto: {
          id: 0,
          key: 'auto',
          name: 'Auto',
          bitrate: 0,
        },
      },
      currentQualityName: 'Auto',
      ccTracks: [],

      seeking: false,

      lastPlayFailed: false,

      segmentStats: {},
      preventCdnCaching: !!urlParam('prevent_cdn_caching'),
      cdnCachingBuster: urlParam('cdn_caching_buster') || generateRandomId(12),
      currentDateString: new Date().toString(),

      pendingSubtitles: null,

      loadingStart: null,
      recoverMediaErrorCounter: 0,
      reloadInProgress: false,
      shakaErrorListener: null,
      debugId: generateRandomId(6),
      reloadVideoElementTimeout: null,
    };

    if (this.props.preventCdnCaching) {
      console.warn('SHAKA', this.props.debugId, 'CDN caching will be prevented!', this.props.cdnCachingBuster);
    }
  }

  load(service, videoIdOrVideoSources, videoServiceData) {
    if (service === 'yoda') {
      const cdnServers = videoServiceData && videoServiceData.videoCdnServers;
      if (!cdnServers) {
        this.showError(videoCdnLoadingFailedUserMessage(this.options.presentationId));
        return;
      }

      this.props.videoSources = cdnServers.map((host) => createYodaVideoSourceWithHost(videoIdOrVideoSources, host));
    } else {
      try {
        this.props.videoSources = JSON.parse(videoIdOrVideoSources);
      } catch (_e) {
        this.showError(videoLoadingFailedUserMessage(this.options.presentationId));
        return;
      }
    }

    this.props.currentVideoSourceIndex = 0;

    this.reloadVideoElement();
  }

  reloadVideoElement() {
    if (this.props.reloadInProgress) {
      console.warn('SHAKA', this.props.debugId, 'reload already in progress when reloadVideoElement called');
      return;
    }

    const load = () => {
      this.video = createVideoElement();
      this.element.insertAdjacentElement('beforeend', this.video);

      this.addVideoListeners();
      this.loadFromVideoSource(this.props.currentVideoSourceIndex, {
        preferDash: this.props.preferDash,
      });

      this.props.reloadInProgress = false;
    };

    this.loading = true;
    this.props.reloadInProgress = true;

    if (this.video) {
      if (this.shaka) {
        this.removeShakaErrorListener();
        if (this.shakaPlayerMux) {
          this.shaka.mux.destroy();
        }
        this.shaka.destroy().then(() => {
          this.video.parentNode.removeChild(this.video);
          load();
        });
      } else {
        this.video.parentNode.removeChild(this.video);
        load();
      }
    } else {
      load();
    }
  }

  loadFromVideoSource(index, { preferDash = false } = {}) {
    if (index > 0) {
      // eslint-disable-next-line no-console
      console.info('SHAKA', this.props.debugId, 'loading video source', index);
    }

    this.loading = true;

    this.props.currentVideoSourceIndex = index;

    const videoSource = this.props.videoSources[this.props.currentVideoSourceIndex];
    if (!videoSource) {
      console.warn(
        'SHAKA',
        this.props.debugId,
        'video source not found',
        this.props.currentVideoSourceIndex,
        this.props.videoSources,
      );
    }

    if ((videoSource.dash || videoSource.hls) && shaka.Player.isBrowserSupported()) {
      this.props.playerInitTime = initShakaPlayerMux.utils.now();
      this.shaka = new shaka.Player(this.video);
      this.shaka.configure({
        manifest: {
          retryParameters: {
            backoffFactor: 1,
            baseDelay: 1000,
            fuzzFactor: 0.5,
            maxAttempts: 5,
            timeout: 10000,
          },
        },
        streaming: {
          retryParameters: {
            backoffFactor: 1,
            baseDelay: 1000,
            fuzzFactor: 0.5,
            maxAttempts: 5,
            timeout: 12000,
          },
        },
        cmcd: {
          enabled: false,
          useHeaders: false,
          sessionId: this.options.analyticsSessionUuid,
          contentId: `${this.options.presentationId}/${this.options.contentType}/${this.options.videoId}`,
        },
      });

      this.shaka.getNetworkingEngine().registerResponseFilter((type, response) => {
        try {
          const request = {
            url: response.originalUri,
            bytes: response.data.byteLength,
            time_to_first_byte: null,
            time_total: response.timeMs,
            http_status: response.status,
            cdn_age: response.headers['x-77-age'],
            cdn_status: response.headers['x-77-cache'],
            cdn_pop: response.headers['x-77-pop'],
            cdn_x_77_nzt: response.headers['x-77-nzt'],
            cdn_x_77_nzt_ray: response.headers['x-77-nzt-ray'],
            cdn_x_accel_expires: response.headers['x-accel-expires'],
            cdn_x_cache_lb: response.headers['x-cache-lb'],
          };

          setTimeout(() => this.sendCmcd({ request }), 0);
        } catch (error) {
          console.warn(error);
        }
      });

      let videoSourceUrl;
      let videoSourceUrlType;
      if (videoSource.dash && (preferDash || !videoSource.hls)) {
        videoSourceUrlType = 'dash';
        videoSourceUrl = videoSource.dash;
      } else if (videoSource.hls) {
        videoSourceUrlType = 'hls';
        videoSourceUrl = videoSource.hls;
      }

      if (window.gon.mux_data_on_demand_env_key) {
        let muxVideoTitleDetails = [`media_set_id = ${this.options.presentationMediaSetId}`];

        if (this.options.videoId) {
          muxVideoTitleDetails.push(`video_id = ${this.options.videoId}`);
        }

        if (this.options.contentTypeDetails) {
          muxVideoTitleDetails = muxVideoTitleDetails.concat(this.options.contentTypeDetails);
        }

        const muxVideoTitlePrefix = `${this.options.presentationId}/${this.options.contentType}`;
        const muxVideoTitle = `${muxVideoTitlePrefix}: ${muxVideoTitleDetails.join(', ')}`;

        this.shakaPlayerMux = initShakaPlayerMux(
          this.shaka,
          {
            debug: false,
            data: {
              env_key: window.gon.mux_data_on_demand_env_key,
              video_id: this.options.videoId,
              video_title: muxVideoTitle,
              viewer_user_id: this.options.analyticsUserUuid,
              experiment_name: this.options.source,
              page_type: this.options.embed ? 'iframe' : 'watchpage',
              player_init_time: this.props.playerInitTime,
              player_name: 'shaka_player',
              sub_property_id: this.options.accountId,
              video_content_type: this.options.contentType,
              video_stream_type: 'vod',
              video_variant_id: this.options.presentationMediaSetId,
              view_session_id: this.options.analyticsSessionUuid,

              custom_1: this.options.presentationId,
              custom_2: videoSourceUrl,
            },
          },
          shaka,
        );
      }

      if (this.options.initial.size) {
        this.size = this.options.initial.size;
      }

      this.addShakaListeners();

      if (videoSourceUrl) {
        log('SHAKA', this.props.debugId, 'loading', videoSourceUrlType);

        this.shaka
          .load(videoSourceUrl)
          .then(() => this.addShakaErrorListener())
          .catch((error) => {
            if (this.shakaPlayerMux) {
              this.shakaPlayerMux.loadErrorHandler(error);
            }

            this.handleShakaError(error, { source: 'load' });
          });
      } else {
        this.loadFromNextVideoSource(() => {
          this.showError(noSuitableVideoFormatUserMessage(this.options.presentationId));
        });
      }
    } else if (
      videoSource.dash &&
      this.video.canPlayType('application/dash+xml') &&
      (preferDash || !videoSource.hls || !this.video.canPlayType('application/vnd.apple.mpegurl'))
    ) {
      log('SHAKA', this.props.debugId, 'loading native Dash');
      this.video.src = videoSource.dash;
    } else if (videoSource.hls && this.video.canPlayType('application/vnd.apple.mpegurl')) {
      log('SHAKA', this.props.debugId, 'loading native HLS');
      this.video.src = videoSource.hls;
    } else if (videoSource.mp4 && this.video.canPlayType('video/mp4')) {
      log('SHAKA', this.props.debugId, 'loading native MP4');
      this.video.src = videoSource.mp4;
    } else {
      this.loadFromNextVideoSource(() => {
        this.showError(videoPlaybackNotSupportedUserMessage(this.options.presentationId));
      });
    }
  }

  sendCmcd({ request }) {
    this.options.callbacks.cmcd({ request });
  }

  get hasNextVideoSource() {
    return this.props.currentVideoSourceIndex < this.props.videoSources.length - 1;
  }

  showError(userMessageArray, debugDetails, error) {
    this.options.callbacks.showError(createUserMessage(userMessageArray, debugDetails, error));
  }

  showNativeError(error) {
    console.warn('SHAKA', this.props.debugId, 'native error', error);

    const { userMessageArray, debugDetails } = createUserMessageArrayForNativeError(error, this.options.presentationId);
    this.showError(userMessageArray, debugDetails, error);
  }

  showShakaError(error) {
    const { userMessageArray, debugDetails } = createUserMessageArrayForShakaError(
      error,
      this.video.error,
      this.options.presentationId,
    );
    this.showError(userMessageArray, debugDetails, error);
  }

  reportError(
    error,
    {
      source = 'unknown',
      warn = false,
      willTryNextSource = false,
      nativeError = false,
      duringLoad = false,
      recoverableError = false,
      reloadInProgress = false,
    } = {},
  ) {
    const { errorName, reportData } = createErrorReportData(this, error, {
      source,
      warn,
      willTryNextSource,
      nativeError,
      duringLoad,
      recoverableError,
      reloadInProgress,
    });

    if (this.options.callbacks.reportError) {
      this.options.callbacks.reportError('SHAKA_PLAYER', errorName, reportData);
    } else {
      console.warn('SHAKA', this.props.debugId, 'report error', errorName, reportData);
    }
  }

  handleShakaError(error, { source }) {
    console.warn('SHAKA', this.props.debugId, 'js', 'error', source, error);

    const duringLoad = source === 'load' || this.currentTime < 1000 || !this.ready;

    if (error.toLowerCase && error.toLowerCase() === 'out of memory') {
      this.reportError(error, {
        source,
        duringLoad,
        reloadInProgress: !!this.props.reloadInProgress,
      });

      this.showShakaError(error);
      return;
    }

    let recoverableError = false;

    if (error.constructor.name === 'DOMException') {
      recoverableError = true;
    }

    if (
      error.category === shaka.util.Error.Category.MEDIA &&
      (error.code === shaka.util.Error.Code.VIDEO_ERROR ||
        error.code === shaka.util.Error.Code.MEDIA_SOURCE_OPERATION_FAILED ||
        error.code === shaka.util.Error.Code.MEDIA_SOURCE_OPERATION_THREW)
    ) {
      recoverableError = true;
    }

    if (
      error.category === shaka.util.Error.Category.MANIFEST &&
      (error.code === shaka.util.Error.Code.HLS_PLAYLIST_HEADER_MISSING ||
        error.code === shaka.util.Error.Code.INVALID_HLS_TAG ||
        error.code === shaka.util.Error.Code.HLS_MASTER_PLAYLIST_NOT_PROVIDED ||
        error.code === shaka.util.Error.Code.HLS_COULD_NOT_PARSE_SEGMENT_START_TIME)
    ) {
      recoverableError = true;
    }

    if (duringLoad && recoverableError && this.props.recoverMediaErrorCounter < 20) {
      if (this.props.reloadVideoElementTimeout) {
        console.warn(
          'SHAKA',
          this.props.debugId,
          'attempting to recover from error: reloading video element is already scheduled',
          this.props.recoverMediaErrorCounter,
        );

        return;
      }

      this.props.recoverMediaErrorCounter += 1;

      console.warn(
        'SHAKA',
        this.props.debugId,
        'attempting to recover from error: reloading video element',
        this.props.recoverMediaErrorCounter,
      );

      this.props.reloadVideoElementTimeout = setTimeout(
        () => {
          this.props.reloadVideoElementTimeout = null;
          this.reloadVideoElement();
        },
        Math.floor(Math.random() * 1000 + 500),
      );

      return;
    }

    let loadFromNextVideoSource = duringLoad;

    if (recoverableError) {
      loadFromNextVideoSource = false;
    }

    if (
      error.category === shaka.util.Error.Category.MANIFEST &&
      error.code === shaka.util.Error.Code.CONTENT_UNSUPPORTED_BY_BROWSER
    ) {
      loadFromNextVideoSource = false;
    }

    this.reportError(error, {
      source,
      willTryNextSource: loadFromNextVideoSource,
      warn: false,
      duringLoad,
      recoverableMediaError: recoverableError,
      reloadInProgress: !!this.props.reloadInProgress,
    });

    if (loadFromNextVideoSource) {
      this.loadFromNextVideoSource(() => {
        this.showShakaError(error);
      });
    } else if (duringLoad) {
      this.showShakaError(error);
    }
  }

  handleNativeError(error, { source }) {
    if (this.shaka) {
      return;
    }

    console.warn('SHAKA', this.props.debugId, 'native', 'error', source, this.video.error);

    const duringLoad = !this.ready;
    const loadNextVideoSource = duringLoad;

    this.reportError(this.video.error, {
      source,
      warn: false,
      willTryNextSource: loadNextVideoSource,
      nativeError: true,
      duringLoad,
      recoverableMediaError: false,
    });

    if (loadNextVideoSource) {
      this.loadFromNextVideoSource(() => {
        this.showNativeError(this.video.error);
      });
    }
  }

  loadFromNextVideoSource(noNextSourceCallback) {
    if (this.props.reloadInProgress) {
      console.warn('SHAKA', this.props.debugId, 'reload already in progress when loadFromNextVideoSource called');
      return;
    }

    if (!this.hasNextVideoSource) {
      if (noNextSourceCallback) {
        noNextSourceCallback();
      }

      return;
    }

    this.loading = true;
    this.props.reloadInProgress = true;

    const load = () => {
      this.loadFromVideoSource(this.props.currentVideoSourceIndex + 1, {
        preferDash: this.props.preferDash,
      });

      this.props.reloadInProgress = false;
    };

    if (this.shaka) {
      this.removeShakaErrorListener();
      if (this.shakaPlayerMux) {
        this.shaka.mux.destroy();
      }
      this.shaka.destroy().then(load);
    } else {
      load();
    }
  }

  loadSubtitles(subtitles) {
    if (!this.shaka) {
      return;
    }

    if (!this.ready) {
      this.props.pendingSubtitles = subtitles;
      return;
    }

    const trackPromises = [];

    for (let i = 0; i < subtitles.length; ++i) {
      const subtitlesTrack = subtitles[i];

      if (
        this.shaka.getLoadMode() !== shaka.Player.LoadMode.MEDIA_SOURCE &&
        this.shaka.getLoadMode() !== shaka.Player.LoadMode.SRC_EQUALS
      ) {
        console.warn('SHAKA', this.props.debugId, 'invalid loadMode when loading subtitles', this.shaka.getLoadMode());
        continue;
      }

      const promise = this.shaka.addTextTrackAsync(
        subtitlesTrack.webvtt_url,
        subtitlesTrack.language,
        'subtitles',
        'text/vtt',
        undefined,
        subtitlesTrack.name,
      );

      trackPromises.push(promise);
    }

    Promise.all(trackPromises).then((results) => {
      const ccTracks = [
        {
          name: 'Off',
          track: null,
        },
      ];
      let newActiveCcTrack = 0;

      for (let i = 0; i < results.length; ++i) {
        const loadedTrack = results[i];

        ccTracks.push({
          name: loadedTrack.label,
          track: loadedTrack,
        });

        if (this.props.subtitlesLanguage && loadedTrack.language.split('-')[0] === this.props.subtitlesLanguage) {
          newActiveCcTrack = i + 1;
        }
      }

      this.props.ccTracks = ccTracks;
      this.options.callbacks.subtitlesChanged(this.props.ccTracks);
      this.setSubtitleTrack(newActiveCcTrack);
    });
  }

  addVideoListeners() {
    addListener(this.video, 'error', () => this.handleNativeError(this.video.error, { source: 'native_listener' }));

    addListener(this.video, 'loadeddata', (event) => log('SHAKA', this.props.debugId, 'native', event.type, event));

    addListener(this.video, 'load', (event) => log('SHAKA', this.props.debugId, 'native', event.type, event));
    addListener(this.video, 'loadend', (event) => log('SHAKA', this.props.debugId, 'native', event.type, event));
    addListener(this.video, 'loadstart', (event) => log('SHAKA', this.props.debugId, 'native', event.type, event));

    addListener(this.video, 'offline', (event) => log('SHAKA', this.props.debugId, 'native', event.type, event));
    addListener(this.video, 'online', (event) => log('SHAKA', this.props.debugId, 'native', event.type, event));

    addListener(this.video, 'readystatechange', (event) =>
      log('SHAKA', this.props.debugId, 'native', event.type, event),
    );

    addListener(this.video, 'stalled', (event) => this.onStalled(event));
    addListener(this.video, 'suspend', (event) => this.onSuspend(event));

    // addListener(this.video, 'progress', event =>log('SHAKA', this.props.debugId, event.type, event));

    addListener(this.video, 'emptied', (event) => log('SHAKA', this.props.debugId, 'native', event.type, event));

    addListener(this.video, 'ended', (event) => this.onEnded(event));

    addListener(this.video, 'loadedmetadata', (event) => this.onLoadedMetaData(event));
    addListener(this.video, 'canplay', (event) => this.onCanPlay(event));
    addListener(this.video, 'play', (event) => this.onPlay(event));
    addListener(this.video, 'pause', (event) => this.onPause(event));

    addListener(this.video, 'playing', (event) => this.onPlaying(event));
    addListener(this.video, 'waiting', (event) => this.onWaiting(event));
    addListener(this.video, 'seeking', (event) => this.onSeeking(event));
    addListener(this.video, 'seeked', (event) => this.onSeeked(event));

    addListener(this.video, 'timeupdate', (event) => this.onTimeUpdate(event));

    addListener(this.video.textTracks, 'addtrack', (event) => this.onTextTracksAdd(event));
  }

  addShakaErrorListener() {
    this.props.shakaErrorListener = (event) => this.handleShakaError(event.detail, { source: 'shaka_listener' });
    this.shaka.addEventListener('error', this.props.shakaErrorListener);
  }

  removeShakaErrorListener() {
    if (!this.props.shakaErrorListener) {
      return;
    }

    this.shaka.removeEventListener('error', this.props.shakaErrorListener);
    this.props.shakaErrorListener = null;
  }

  addShakaListeners() {
    const shakaEvents = [
      'abrstatuschanged',
      'adaptation',
      'buffering',
      'drmsessionupdate',
      'emsg',
      'expirationupdated',
      'largegap',
      'loaded',
      'loading',
      'manifestparsed',
      'metadata',
      'ratechange',
      'onstatechange',
      'onstateidle',
      'streaming',
      'textchanged',
      'texttrackvisibility',
      'timelineregionadded',
      'timelineregionenter',
      'timelineregionexit',
      'trackschanged',
      'unloading',
      'variantchanged',
    ];

    for (const eventType of shakaEvents) {
      this.shaka.addEventListener(eventType, (event) => {
        log('SHAKA', this.props.debugId, 'js', event.type, event);
      });
    }

    this.shaka.addEventListener('buffering', this.onShakaBuffering.bind(this));
    this.shaka.addEventListener('adaptation', this.onShakaAdaptation.bind(this));
    this.shaka.addEventListener('variantchanged', this.onShakaAdaptation.bind(this));

    this.shaka.getNetworkingEngine().registerRequestFilter((type, request) => {
      if (this.props.preventCdnCaching) {
        request.uri += `?bust=${this.props.cdnCachingBuster}`;
        request.uris[0] += `?bust=${this.props.cdnCachingBuster}`;
      }
    });

    this.shaka.getNetworkingEngine().registerResponseFilter((type, response) => {
      if (type !== shaka.net.NetworkingEngine.RequestType.SEGMENT) return;

      const { name, stats } = createSegmentStatsFromNetworkResponse(response);

      if (!this.props.segmentStats[name]) {
        this.props.segmentStats[name] = [];
      }

      this.props.segmentStats[name].push(stats);
    });

    setInterval(() => {
      if (this.state !== 'paused') {
        const debugInfo = debugInfoFromShakaStats(
          this.shaka.getStats(),
          this.props.segmentStats,
          this.props.currentDateString,
          this.props.currentQualityName,
        );

        this.options.callbacks.debugInfo(debugInfo);
      }
    }, 1000);
  }

  play() {
    log('SHAKA', this.props.debugId, 'play request');

    const promise = this.video.play();
    if (isVariableDefinedNotNull(promise)) {
      promise
        .then(() => {
          this.props.lastPlayFailed = false;
        })
        .catch((error) => {
          console.warn('SHAKA', this.props.debugId, 'play failed', error);

          this.options.callbacks.playFailed();
          this.props.lastPlayFailed = true;

          this.loading = false;
          this.state = 'paused';
        });
    }
  }

  pause() {
    log('SHAKA', this.props.debugId, 'pause request');

    this.video.pause();
  }

  setMuted(muted) {
    this.video.muted = muted;
    this.props.muted = muted;
    this.options.callbacks.volumeChanged(this.props.volume, this.props.muted);
  }

  seekToLivePosition() {
    console.warn('SHAKA', this.props.debugId, 'seekToLivePosition not implemented');
  }

  setSubtitleTrack(track) {
    const ccTrack = this.props.ccTracks[track];

    this.props.activeCcTrack = track;

    if (!ccTrack.track) {
      this.shaka.setTextTrackVisibility(false);
    } else {
      this.shaka.selectTextTrack(ccTrack.track);
      this.shaka.setTextTrackVisibility(true);
    }

    if (this.shaka.getLoadMode() === shaka.Player.LoadMode.SRC_EQUALS) {
      for (const textTrack of this.video.textTracks) {
        if (textTrack.kind !== 'subtitles') continue;

        if (
          !ccTrack.track ||
          ccTrack.track.language !== textTrack.language ||
          ccTrack.track.label !== textTrack.label
        ) {
          textTrack.mode = 'disabled';
        } else {
          textTrack.mode = 'showing';
        }
      }
    }

    this.options.callbacks.activeSubtitlesChanged(this.props.activeCcTrack);
  }

  setQuality(quality) {
    log('SHAKA', this.props.debugId, 'set quality', quality);

    if (!this.shaka) {
      return;
    }

    if (quality === 'auto') {
      this.shaka.configure({ abr: { enabled: true } });
    } else {
      const currentVariantTrack = this.shaka.getVariantTracks().find((track) => track.active);
      const requestedVariantTrack = this.props.availableQualitiesData[quality].track;

      if (currentVariantTrack.id !== requestedVariantTrack.id) {
        const clearBuffer = requestedVariantTrack.bandwidth > currentVariantTrack.bandwidth;

        this.shaka.configure({ abr: { enabled: false } });
        this.shaka.selectVariantTrack(requestedVariantTrack, clearBuffer);
      }

      if (this.props.currentQuality === 'auto') {
        // eslint-disable-next-line dot-notation
        this.props.availableQualitiesData['auto'].name = 'Auto';

        if (this.options.callbacks.availableQualitiesChanged) {
          this.options.callbacks.availableQualitiesChanged(this._availableQualities());
        }
      }
    }

    this.props.currentQuality = quality;

    if (this.options.callbacks.qualityChanged) {
      this.options.callbacks.qualityChanged(quality);
    }
  }

  set playbackServer(serverIndex) {
    console.warn('SHAKA', this.props.debugId, 'playbackServer not implemented');
  }

  set volume(value) {
    this.props.muted = false;
    this.props.volume = value;

    this.video.muted = false;
    this.video.volume = this.props.volume / 100.0;

    this.options.callbacks.volumeChanged(this.props.volume, this.props.muted);
  }

  get currentStreamTime() {
    return this.props.timeSinceStreamStart;
  }

  get currentTime() {
    return this.props.currentTime;
  }

  set currentTime(value) {
    this.props.seekToLiveBeforePlay = false;

    this.props.currentTime = value;
    this.video.currentTime = value / 1000.0;

    this.props.inLivePosition = false;

    if (this.options.callbacks.inLivePositionChanged) {
      this.options.callbacks.inLivePositionChanged(this.props.inLivePosition);
    }
  }

  get duration() {
    return this.props.duration;
  }

  set playbackRate(value) {
    this.video.playbackRate = value;

    this.props.playbackRate = value;
    this.options.callbacks.playbackRateChanged(this.props.playbackRate);
  }

  // General

  get muted() {
    return this.props.muted;
  }

  get inLivePosition() {
    return this.props.inLivePosition;
  }

  get ready() {
    return this.props.ready;
  }

  get loading() {
    return this.props.loading;
  }

  set loading(value) {
    if (value === this.props.loading) {
      return;
    }

    this.props.loading = value;
    this.options.callbacks.loadingChanged(value);
  }

  get state() {
    return this.props.state;
  }

  set state(value) {
    if (value === this.props.state) {
      return;
    }

    this.props.state = value;
    this.options.callbacks.stateChanged(value);
  }

  get ended() {
    if (!this.video) return false;

    return this.video.ended;
  }

  // Internal

  updateAvailablePlaybackRates() {
    this.props.availablePlaybackRates = [0.5, 1, 1.2, 1.45, 1.7, 2];

    if (this.options.callbacks.availablePlaybackRatesChanged) {
      this.options.callbacks.availablePlaybackRatesChanged(this.props.availablePlaybackRates);
    }

    if (this.options.callbacks.playbackRateChanged) {
      this.options.callbacks.playbackRateChanged(this.props.playbackRate);
    }
  }

  updateAvailableQualities() {
    if (!this.shaka) {
      this.props.currentQualityName = 'Auto';
      this.props.currentQuality = 'auto';
      this.props.availableQualitiesData = {};

      if (this.options.callbacks.availableQualitiesChanged) {
        this.options.callbacks.availableQualitiesChanged(this._availableQualities());
      }

      if (this.options.callbacks.qualityChanged) {
        this.options.callbacks.qualityChanged(this.props.currentQuality);
      }

      return;
    }

    let quality = 'auto';
    const availableQualitiesData = {
      auto: {
        id: null,
        key: 'auto',
        name: 'Auto',
        bitrate: Infinity,
        track: null,
      },
    };

    for (const track of this.shaka.getVariantTracks()) {
      const name = `${track.width}x${track.height}`;
      const id = track.id;
      const bitrate = track.bandwidth;

      availableQualitiesData[name] = {
        id,
        key: name,
        name,
        bitrate,
        track,
      };

      if (track.active) {
        quality = name;
      }
    }

    this.props.currentQualityName = quality;

    if (this.props.currentQuality !== 'auto') {
      this.props.currentQuality = quality;
    }

    this.props.availableQualitiesData = availableQualitiesData;

    if (this.options.callbacks.availableQualitiesChanged) {
      this.options.callbacks.availableQualitiesChanged(this._availableQualities());
    }

    if (this.options.callbacks.qualityChanged) {
      this.options.callbacks.qualityChanged(this.props.currentQuality);
    }
  }

  setReady() {
    this.props.duration = this.video.duration * 1000;
    if (this.options.callbacks.timesChanged) {
      this.options.callbacks.timesChanged(this.currentTime, this.duration);
    }

    this.updateAvailablePlaybackRates();
    this.updateAvailableQualities();
    this._updateCurrentQuality();

    this.video.muted = this.props.muted;
    this.video.volume = this.props.volume / 100.0;
    this.options.callbacks.volumeChanged(this.props.volume, this.props.muted);

    this.state = 'paused';
    this.loading = false;

    this.props.ready = true;
    this.options.callbacks.ready();

    if (this.props.pendingSubtitles) {
      this.loadSubtitles(this.props.pendingSubtitles);
      this.props.pendingSubtitles = null;
    }
  }

  onStalled(event) {
    log('SHAKA', this.props.debugId, 'native', event.type, event);

    if (this.canStopLoading) {
      this.loading = false;
    }
  }

  onSuspend(event) {
    log('SHAKA', this.props.debugId, 'native', event.type, event);

    if (!this.ready) {
      this.setReady();
    }

    if (this.canStopLoading) {
      this.loading = false;
    }
  }

  onLoadedMetaData(event) {
    log('SHAKA', this.props.debugId, 'native', event.type, event);
  }

  get canStopLoading() {
    if (!this.shaka) {
      return !this.seeking;
    }

    if (this.shaka.getLoadMode() === shaka.Player.LoadMode.SRC_EQUALS) {
      if (this.state === 'playing') {
        return !this.seeking && !this.shaka.isBuffering();
      }

      return !this.seeking;
    }

    return !this.seeking && !this.shaka.isBuffering();
  }

  onCanPlay(event) {
    log('SHAKA', this.props.debugId, 'native', event.type, event);

    if (!this.ready) {
      this.setReady();
    }

    if (this.canStopLoading) {
      this.loading = false;
    }
  }

  onPlay(event) {
    log('SHAKA', this.props.debugId, 'native', event.type, event);

    if (this.canStopLoading) {
      this.loading = false;
    }

    this.state = 'playing';
  }

  onPause(event) {
    log('SHAKA', this.props.debugId, 'native', event.type, event);

    if (this.canStopLoading) {
      this.loading = false;
    }

    this.state = 'paused';
  }

  onPlaying(event) {
    log('SHAKA', this.props.debugId, 'native', event.type, event);

    if (this.canStopLoading) {
      this.loading = false;
    }

    this.state = 'playing';
  }

  onWaiting(event) {
    log('SHAKA', this.props.debugId, 'native', event.type, nativeVideoReadyStateName(this.video.readyState), event);

    this.loading = true;
  }

  onEnded(event) {
    log('SHAKA', this.props.debugId, 'native', event.type, event);

    this.options.callbacks.ended();
  }

  onSeeking(event) {
    log('SHAKA', this.props.debugId, 'native', event.type, event);

    this.seeking = true;
    this.loading = true;
  }

  onSeeked(event) {
    log('SHAKA', this.props.debugId, 'native', event.type, event);

    this.seeking = false;

    if (this.canStopLoading) {
      this.loading = false;
    }
  }

  // eslint-disable-next-line no-unused-vars
  onTimeUpdate(event) {
    this.props.duration = this.video.duration * 1000;
    this.props.currentTime = this.video.currentTime * 1000;
  }

  onTextTracksAdd(event) {
    log('SHAKA', this.props.debugId, 'native', event.type, event);
  }

  onShakaBuffering(details) {
    if (this.shaka.getLoadMode() === shaka.Player.LoadMode.SRC_EQUALS) {
      if (this.state === 'playing' && (details.buffering || !this.seeking)) {
        this.loading = details.buffering;
      } else {
        this.loading = this.seeking;
      }

      return;
    }

    if (details.buffering || !this.seeking) {
      this.loading = details.buffering;
    }
  }

  // eslint-disable-next-line no-unused-vars
  onShakaAdaptation(details) {
    this._updateCurrentQuality();
  }

  _updateCurrentQuality() {
    if (!this.shaka) {
      return;
    }

    const currentVariantTrack = this.shaka.getVariantTracks().find((track) => track.active);
    if (!currentVariantTrack) {
      return;
    }

    const currentQualityData = Object.values(this.props.availableQualitiesData).find(
      (quality) => quality.id === currentVariantTrack.id,
    );

    let currentQualityName;
    if (this.props.currentQuality === 'auto') {
      currentQualityName = `Auto (${currentVariantTrack.width}x${currentVariantTrack.height})`;

      // eslint-disable-next-line dot-notation
      this.props.availableQualitiesData['auto'].name = currentQualityName;

      if (this.options.callbacks.availableQualitiesChanged) {
        this.options.callbacks.availableQualitiesChanged(this._availableQualities());
      }
    } else {
      currentQualityName = currentQualityData.name;

      this.props.currentQuality = currentQualityData.key;

      if (this.options.callbacks.qualityChanged) {
        this.options.callbacks.qualityChanged(this.props.currentQuality);
      }
    }

    this.props.currentQualityName = currentQualityName;
  }

  get seeking() {
    return this.props.seeking;
  }

  set seeking(seeking) {
    this.props.seeking = seeking;
  }

  set size(size) {
    let maxHeight;

    if (size.h <= 300) {
      maxHeight = 432;
    } else if (size.h <= 600) {
      maxHeight = 720;
    } else if (size.h <= 1000) {
      maxHeight = 1080;
    } else {
      maxHeight = undefined;
    }

    this.shaka.configure({
      abr: {
        restrictions: {
          maxHeight,
        },
      },
    });
  }

  _availableQualities() {
    return Object.values(this.props.availableQualitiesData)
      .sort((a, b) => b.bitrate - a.bitrate)
      .map((q) => [q.name, q.key]);
  }

  get activeVideoSourceUrl() {
    if (!this.video) return null;
    if (!this.shaka) return this.video.src;

    return this.shaka.getAssetUri();
  }

  get activeQualityName() {
    return this.props.currentQualityName;
  }

  get activePlaybackRateValue() {
    return this.video ? this.video.playbackRate : null;
  }
}
