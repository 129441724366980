import formatTime from 'modules/player/format_time';

import log from 'modules/player/log';
import icon from 'modules/player/view/icons/icons';

import getOffset from 'plugins/element/get_offset';
import width from 'plugins/element/width';
import stimulus, { FUSE_TOOLTIP_CONTROLLER } from 'plugins/stimulus';
import addListener from 'plugins/utilities/add_listener';
import getEventPageX from 'plugins/utilities/get_event_page_x';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';
import removeListener from 'plugins/utilities/remove_listener';

export default class Progressbar {
  constructor(
    bar,
    padding,
    current,
    mouse,
    bookmarks,
    reviewNotes,
    startMoveCallback,
    updateMoveCallback,
    endMoveCallback,
    progressImageUrlCallback,
    initializeLocalization,
  ) {
    this.bar = bar;
    this.padding = padding;
    this.current = current;
    this.mouse = mouse;
    this.bookmarks = bookmarks;
    this.reviewNotes = reviewNotes;

    this.startMoveCallback = startMoveCallback;
    this.updateMoveCallback = updateMoveCallback;
    this.endMoveCallback = endMoveCallback;
    this.progressImageUrlCallback = progressImageUrlCallback;

    this.initializeLocalization = initializeLocalization;

    this.props = {
      bookmarks: [],
      reviewNotes: [],
      mouseIn: false,
      mouseDown: false,
      mouseTime: 0,
      duration: 0,
      slideRatio: 16 / 9.0,
      currentSlideUrl: null,
    };

    this.mouseUpHandler = (event) => {
      log('PROG', 'mouse up');

      this.endMove(event);
      this.props.mouseDown = false;

      if (!this.props.mouseIn) {
        this.setInactive();
      }
    };

    this.mouseMoveHandler = (event) => this.followMouse(event);

    addListener(this.padding, 'dragstart', (event) => event.preventDefault());
    addListener(this.padding, 'mouseenter', (event) => this.setActive(event), { passive: true });
    addListener(this.padding, 'mouseleave', (event) => this.setInactive(event), { passive: true });
    addListener(this.padding, 'mousedown', (event) => this.setMouseDown(event), { passive: true });

    addListener(
      this.padding,
      'touchstart',
      (event) => {
        event.preventDefault();
        this.disableTooltip();
        this.setActive(event);
        this.setMouseDown(event);
      },
      { passive: true },
    );

    addListener(
      this.padding,
      'touchmove',
      (event) => {
        event.preventDefault();
        this.mouseMoveHandler(event);
      },
      { passive: true },
    );

    addListener(
      this.padding,
      'touchend',
      (event) => {
        event.preventDefault();
        this.mouseUpHandler(event);
        this.setInactive(event);
        this.enableTooltip();
      },
      { passive: true },
    );

    addListener(
      this.padding,
      'touchcancel',
      (event) => {
        event.preventDefault();
        this.setInactive(event);
        this.enableTooltip();
      },
      { passive: true },
    );

    this.initTooltip();
  }

  initTooltip() {
    stimulus.set(this.padding, {
      target: { [FUSE_TOOLTIP_CONTROLLER]: 'item' },
      controllerDataValue: {
        [FUSE_TOOLTIP_CONTROLLER]: {
          appendTo: '[data-slp-target="rootElement"]',
          boundary: '[data-slp-target="rootElement"]',
          hideArrow: true,
          followCursor: true,
          followCursorAxis: 'x',
          classes: 'tw-text-md tw-bg-opacity-89 tw-rounded-sm tw-border-none tw-px-0.5 tw-pb-0',
        },
      },
    });

    this.tooltipElement = document.createElement('div');
    this.tooltipTimeElement = document.createElement('div');
    this.tooltipImageElement = document.createElement('img');

    this.tooltipElement.appendChild(this.tooltipTimeElement);

    this.tooltipImageElement.crossOrigin = 'anonymous';
    this.tooltipImageElement.alt = 'Slide';

    const connectedListenerId = addListener(
      this.padding,
      `${FUSE_TOOLTIP_CONTROLLER}:connected`,
      ({ detail: { controller } }) => {
        removeListener(this.padding, { id: connectedListenerId });

        controller.itemProps(this.padding).resolvedContent = this.tooltipElement;
      },
    );
  }

  disableTooltip() {
    if (stimulus.hasTarget(this.padding, { [FUSE_TOOLTIP_CONTROLLER]: 'item' })) {
      stimulus.setControllerDataValue(this.padding, {
        [FUSE_TOOLTIP_CONTROLLER]: {
          disabled: true,
        },
      });
    }
  }

  enableTooltip() {
    if (stimulus.hasTarget(this.padding, { [FUSE_TOOLTIP_CONTROLLER]: 'item' })) {
      stimulus.setControllerDataValue(this.padding, {
        [FUSE_TOOLTIP_CONTROLLER]: {
          disabled: false,
        },
      });
    }
  }

  setProgress(currentTime, duration) {
    this.props.duration = duration;

    const renderDurationDependentHtml = this.props.duration === 0;
    if (renderDurationDependentHtml) {
      this.renderBookmarksHtml();
      this.renderReviewNotesHtml();
    }

    if (this.props.mouseDown) {
      return;
    }

    const pct = (currentTime / duration) * 100;
    this.current.style.width = `${pct}%`;
  }

  setActive(event) {
    if (event && event.target !== this.padding) {
      return;
    }

    log('PROG', 'set active');

    this.props.mouseIn = true;

    if (event.type === 'mouseenter') {
      addListener(document, 'mouseup', this.mouseUpHandler);
      addListener(document, 'mousemove', this.mouseMoveHandler);
    }

    this.bar.classList.add('slp__progress--active');
  }

  setInactive(event) {
    if (event && event.target !== this.padding) {
      return;
    }

    log('PROG', 'set inactive', this.props.mouseDown);

    this.props.mouseIn = false;

    if (this.props.mouseDown) {
      return;
    }

    if (!event || event.type === 'mouseleave') {
      removeListener(document, { handler: this.mouseUpHandler });
      removeListener(document, { handler: this.mouseMoveHandler });
    }

    this.bar.classList.remove('slp__progress--active');
  }

  setMouseDown(event) {
    log('PROG', 'mouse down');

    this.props.mouseDown = true;

    this.startMove(event);
  }

  followMouse(event) {
    const pct = this.mousePositionToPct(event);

    this.setMouseWidth(pct);

    if (this.props.mouseDown) {
      this.setCurrentWidth(pct);
      this.updateMoveCallback(pct);
    }
  }

  startMove(event) {
    const pct = this.mousePositionToPct(event);

    this.setMouseWidth(pct);
    this.setCurrentWidth(pct);

    this.startMoveCallback(pct);
  }

  endMove(event) {
    const pct = this.mousePositionToPct(event);

    this.setCurrentWidth(pct);
    this.endMoveCallback(pct);
  }

  setCurrentWidth(pct) {
    this.current.style.width = `${pct}%`;
  }

  setMouseWidth(pct) {
    this.mouse.style.width = `${pct}%`;

    const time = this.props.duration * (pct / 100.0);
    const slideUrl = this.progressImageUrlCallback(time);

    if (isVariableDefinedNotNull(slideUrl)) {
      if (this.props.currentSlideUrl !== slideUrl) {
        if (!isVariableDefinedNotNull(this.props.currentSlideUrl)) {
          this.tooltipElement.className = 'slp__progress__imageTooltip';
          this.tooltipElement.insertAdjacentElement('afterbegin', this.tooltipImageElement);
        }

        const formattedTime = formatTime(time / 1000.0);
        this.tooltipTimeElement.textContent = formattedTime;
        this.tooltipImageElement.src = slideUrl;
      }
    } else if (!isVariableDefinedNotNull(this.props.currentSlideUrl)) {
      this.tooltipImageElement.remove();
      this.tooltipElement.className = 'slp__progress__textTooltip';
    }

    this.props.currentSlideUrl = slideUrl;

    const formattedTime = formatTime(time / 1000.0);
    this.tooltipTimeElement.textContent = formattedTime;
  }

  mousePositionToPct(event) {
    const mouseX = getEventPageX(event) - getOffset('left', this.bar, true) || 0;
    const pct = Math.max(0, Math.min(100, (mouseX / width(this.bar)) * 100));

    return pct;
  }

  set slideRatio(value) {
    this.props.slideRatio = value;
  }

  setBookmarks(list) {
    this.props.bookmarks = list;

    if (this.props.duration > 0) {
      this.renderBookmarksHtml();
    }
  }

  setReviewNotes(reviewNotes) {
    this.props.reviewNotes = reviewNotes;

    if (this.props.duration > 0) {
      this.renderReviewNotesHtml();
    }
  }

  renderBookmarksHtml() {
    const bookmarkIcon = icon('player-bookmark');
    let html = '';

    for (let i = 0; i < this.props.bookmarks.length; i++) {
      const bookmark = this.props.bookmarks[i];
      const title = bookmark.note.trim() === '' ? formatTime(bookmark.current_time) : bookmark.note;
      const timePct = this.timeMsToDurationPct(bookmark.current_time * 1000);

      html += '<li class="slp__progress__bookmark"';
      html += `  data-slp-title="${title}"`;
      html += '  data-slp-action-w-param="seekTo"';
      html += `  data-slp-action-param="${(bookmark.current_time - 2) * 1000}"`;
      html += `  style="left: ${timePct}%;">`;
      html += bookmarkIcon;
      html += '</li>';
    }

    this.bookmarks.innerHTML = html;
    this.initializeLocalization(this.bookmarks);
  }

  renderReviewNotesHtml() {
    const reviewIcon = icon('player-review');
    let html = '';

    for (const reviewNote of this.props.reviewNotes) {
      const lastComment = reviewNote.comments.length > 0 && reviewNote.comments[reviewNote.comments.length - 1];

      let title;
      if (lastComment && lastComment.text !== '') {
        title = `${formatTime(reviewNote.time_ms / 1000.0)} [${lastComment.commenter_name}]: ${lastComment.text}`;
      } else {
        title = formatTime(reviewNote.time_ms / 1000.0);
      }

      const timePct = this.timeMsToDurationPct(reviewNote.time_ms);

      html += '<li class="slp__progress__reviewNote"';
      html += `  data-review-state="${reviewNote.state}"`;
      if (reviewNote.data && reviewNote.data.palpatine_review_type) {
        html += `  data-palpatine-review-type="${reviewNote.data.palpatine_review_type}"`;
      }
      html += `  data-slp-title="${title}"`;
      html += '  data-slp-action-w-param="openReviewNote"';
      html += `  data-slp-action-param="${reviewNote.id}"`;
      html += `  style="left: ${timePct}%;">`;
      html += reviewIcon;
      html += '</li>';
    }

    this.reviewNotes.innerHTML = html;
    this.initializeLocalization(this.reviewNotes);
  }

  timeMsToDurationPct(timeMs) {
    return (timeMs / this.props.duration) * 100;
  }
}
