import shaka from 'shaka-player';

function shakaErrorCodeName(error) {
  for (const k in shaka.util.Error.Code) {
    if (shaka.util.Error.Code[k] === error.code) {
      return k;
    }
  }

  return `UNKNOWN (${error.code})`;
}

export default shakaErrorCodeName;
