function formatTime(total) {
  const hours = Math.floor(total / 3600);
  const minutes = Math.floor((total % 3600) / 60);
  const seconds = Math.floor(total % 60);

  let minutePad = 1;
  let time = '';

  if (hours > 0) {
    minutePad = 2;
    time += hours.toString();
    time += ':';
  }

  time += minutes.toString().padStart(minutePad, '0');
  time += ':';
  time += seconds.toString().padStart(2, '0');

  return time;
}

export default formatTime;
