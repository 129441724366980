const UpdateInterval = 100;
const HighResUpdateInterval = 25;

export default class UpdateTimer {
  constructor({ callback, highResCallback }) {
    this.callback = callback;
    this.highResCallback = highResCallback;

    this.props = {
      isRunning: false,
    };
  }

  start() {
    this.isRunning = true;
  }

  stop() {
    this.isRunning = false;
  }

  scheduleTick() {
    setTimeout(() => this.tick(), UpdateInterval);
  }

  scheduleHighResTick() {
    setTimeout(() => this.highResTick(), HighResUpdateInterval);
  }

  tick() {
    if (!this.isRunning) {
      return;
    }

    this.callback();
    this.scheduleTick();
  }

  highResTick() {
    if (!this.isRunning) {
      return;
    }

    this.highResCallback();
    this.scheduleHighResTick();
  }

  set isRunning(running) {
    if (this.props.isRunning !== running) {
      this.props.isRunning = running;

      if (running) {
        this.scheduleTick();
        this.scheduleHighResTick();
      }
    }
  }

  get isRunning() {
    return this.props.isRunning;
  }

  set interval(interval) {
    this.props.interval = interval;
  }

  get interval() {
    return this.props.interval;
  }
}
