import log from 'modules/player/log';

import ajax from 'plugins/ajax/ajax';
import slidesLiveDomain from 'plugins/utilities/slideslive_domain';

const PREDEFINED_PLAYLISTS = ['history', 'watch-later', 'favorite'];

export default class Playlists {
  constructor(options, callbacks) {
    this.props = {
      options: {},
      presentationPlaylists: [],
      playlistsList: [],
      baseUrl: '',
      predefinedPlaylistsExists: false,
      queue: [],

      destroyed: false,
    };

    this.options = options;
    this.callbacks = callbacks;

    this.initBaseUrl();
  }

  initBaseUrl() {
    this.baseUrl = `${window.location.protocol}//${slidesLiveDomain()}`;
  }

  initPlaylistsList() {
    if (this.props.destroyed) {
      return;
    }

    ajax('GET', this.fetchPlaylistsListUrl, undefined, {
      withCredentials: true,
      success: (req) => {
        if (this.props.destroyed) {
          return;
        }

        const data = this.sortPlaylists(JSON.parse(req.response).data);

        log('PLAYLISTS', 'fetch playlists list success', data.length);

        this.playlistsList = data;

        this.callbacks.updatePlaylistsList(this.playlistsList, this.presentationPlaylists);
      },
      error: (req) => {
        if (this.props.destroyed) {
          return;
        }

        log('PLAYLISTS', 'fetch playlists list error', req);
      },
      complete: () => {
        if (this.props.destroyed) {
          return;
        }

        this.createPredefinedPlaylists();
        this.initPresentationPlaylists();
      },
    });
  }

  initPresentationPlaylists() {
    ajax('GET', this.fetchPresentationPlaylistsUrl, undefined, {
      withCredentials: true,
      success: (req) => {
        if (this.props.destroyed) {
          return;
        }

        const data = JSON.parse(req.response).data;

        log('PLAYLISTS', 'fetch presentation playlists success', data.length);

        this.presentationPlaylists = data;

        let inFavorite = false;
        let inWatchLater = false;

        for (let i = 0; i < data.length; i++) {
          const playlistName = data[i].canonical_name || data[i].id;

          this.callbacks.afterAction(playlistName, true, true);

          if (playlistName === 'favorite') {
            inFavorite = true;
          } else if (playlistName === 'watch-later') {
            inWatchLater = true;
          }
        }

        if (!inFavorite) {
          this.callbacks.afterAction('favorite', false, true);
        }

        if (!inWatchLater) {
          this.callbacks.afterAction('watch-later', false, true);
        }
      },
      error: (req) => {
        if (this.props.destroyed) {
          return;
        }

        log('PLAYLISTS', 'fetch presentation playlists error', req);

        this.callbacks.afterAction('favorite', false, true);
        this.callbacks.afterAction('watch-later', false, true);
      },
    });
  }

  add(name) {
    if (!this.predefinedPlaylistsExists) {
      this.queue.push({
        action: 'add',
        name,
      });

      return;
    }

    const reqData = {
      presentation_id: this.options.presentationId,
    };

    let playlist = this.playlistsList.find((p) => p.id.toString() === name);
    if (!playlist) {
      playlist = this.playlistsList.find((p) => p.canonical_name === name);

      if (playlist) {
        reqData.playlist_canonical_name = name;
      } else {
        reqData.playlist_name = name;
      }
    } else {
      reqData.playlist_id = name;
    }

    ajax('POST', this.addUrl, JSON.stringify({ data: reqData }), {
      withCredentials: true,
      success: (req) => {
        if (this.props.destroyed) {
          return;
        }

        const data = JSON.parse(req.response).data;

        if (data.canonical_name === 'history') {
          return;
        }

        this.presentationPlaylists.push(data);

        this.callbacks.afterAction(name, true);
      },
      error: (req) => {
        if (this.props.destroyed) {
          return;
        }

        log('PLAYLISTS', 'add presentation to playlist error', req);
      },
    });
  }

  remove(name) {
    if (!this.predefinedPlaylistsExists) {
      this.queue.push({
        action: 'remove',
        name,
      });

      return;
    }

    const reqData = {
      presentation_id: this.options.presentationId,
    };

    let playlist = this.playlistsList.find((p) => p.id.toString() === name);

    if (!playlist) {
      playlist = this.playlistsList.find((p) => p.canonical_name === name);
      reqData.playlist_canonical_name = name;
    } else {
      reqData.playlist_id = name;
    }

    if (!playlist) {
      console.warn('PLAYLISTS', 'unknown playlist', name);
      return;
    }

    ajax('POST', this.removeUrl, JSON.stringify({ data: reqData }), {
      withCredentials: true,
      success: (req) => {
        if (this.props.destroyed) {
          return;
        }

        const data = JSON.parse(req.response).data;

        this.presentationPlaylists = this.presentationPlaylists.filter((p) => p.id !== data.id);

        this.callbacks.afterAction(name, false);
      },
      error: (req) => {
        if (this.props.destroyed) {
          return;
        }

        log('PLAYLISTS', 'remove presentation from playlist error', req);
      },
    });
  }

  createPredefinedPlaylists() {
    let allPredefinedCreated = true;

    for (let i = 0; i < PREDEFINED_PLAYLISTS.length; i++) {
      if (!this.playlistsList.find((p) => p.canonical_name === PREDEFINED_PLAYLISTS[i])) {
        allPredefinedCreated = false;
        this.addPlaylist({ canonical_name: PREDEFINED_PLAYLISTS[i] });
      }
    }

    if (allPredefinedCreated) {
      this.predefinedPlaylistsCreated();
    }
  }

  predefinedPlaylistsCreated() {
    this.predefinedPlaylistsExists = true;

    if (this.queue.length > 0) {
      for (let i = 0; i < this.queue.length; i++) {
        if (this.queue[i].action === 'add') {
          this.add(this.queue[i].name);
        } else {
          this.remove(this.queue[i].name);
        }
      }
    }
  }

  addPlaylist(data) {
    const reqData = {};

    if ('canonical_name' in data) {
      reqData.playlist_canonical_name = data.canonical_name;
    }

    if ('name' in data) {
      reqData.playlist_name = data.name;
    }

    if ('custom' in data) {
      reqData.playlist_custom = data.custom;
    }

    ajax('POST', this.createPlaylistUrl, JSON.stringify({ data: reqData }), {
      withCredentials: true,
      success: (req) => {
        if (this.props.destroyed) {
          return;
        }

        const d = JSON.parse(req.response).data;

        this.playlistsList.push(d);
        this.playlistsList = this.sortPlaylists(this.playlistsList);

        this.callbacks.updatePlaylistsList(this.playlistsList, this.presentationPlaylists);

        if (
          !this.predefinedPlaylistsExists &&
          this.playlistsList.filter((p) => PREDEFINED_PLAYLISTS.includes(p.canonical_name)).length ===
            PREDEFINED_PLAYLISTS.length
        ) {
          this.predefinedPlaylistsCreated();
        }
      },
    });
  }

  sortPlaylists(data) {
    return data.sort((a, b) => {
      if (a.canonical_name) {
        if (b.canonical_name && b.canonical_name < a.canonical_name) {
          return 1;
        }

        return -1;
      }

      if (b.canonical_name) {
        return 1;
      }

      return a.name.localeCompare(b.name, { sensitivity: 'accent' });
    });
  }

  destroy() {
    this.props.destroyed = true;
  }

  set options(options) {
    this.props.options = options;
  }

  get options() {
    return this.props.options;
  }

  set predefinedPlaylistsExists(predefinedPlaylistsExists) {
    this.props.predefinedPlaylistsExists = predefinedPlaylistsExists;
  }

  get predefinedPlaylistsExists() {
    return this.props.predefinedPlaylistsExists;
  }

  set queue(queue) {
    this.props.queue = queue;
  }

  get queue() {
    return this.props.queue;
  }

  set presentationPlaylists(presentationPlaylists) {
    this.props.presentationPlaylists = presentationPlaylists;
  }

  get presentationPlaylists() {
    return this.props.presentationPlaylists;
  }

  set playlistsList(playlistsList) {
    this.props.playlistsList = playlistsList;
  }

  get playlistsList() {
    return this.props.playlistsList;
  }

  get addUrl() {
    return `${this.baseUrl}/api/v1/playlists/add_presentation`;
  }

  get removeUrl() {
    return `${this.baseUrl}/api/v1/playlists/remove_presentation`;
  }

  get createPlaylistUrl() {
    return `${this.baseUrl}/api/v1/playlists`;
  }

  get fetchPresentationPlaylistsUrl() {
    return `${this.baseUrl}/api/v1/playlists/by_presentation/${this.options.presentationId}`;
  }

  get fetchPlaylistsListUrl() {
    let params = 'search_by=custom,canonical_name';
    params += '&canonical_name=history,favorite,watch-later';
    params += '&custom=true';

    return `${this.baseUrl}/api/v1/playlists?${params}`;
  }

  set baseUrl(baseUrl) {
    this.props.baseUrl = baseUrl;
  }

  get baseUrl() {
    return this.props.baseUrl;
  }
}
