// eslint-disable-next-line max-classes-per-file
import log from 'modules/player/log';

import defer from 'plugins/utilities/defer';

class VideoPlayerController {
  constructor(callbacks) {
    this.callbacks = callbacks;

    this.props = {
      loading: null,
      state: '',
      player: null,
    };
  }

  play() {
    if (!this.active) return;

    this.props.player.play();
  }

  pause() {
    if (!this.active) return;

    this.props.player.pause();
  }

  updatePlayer(player, playing) {
    this.props.loading = false;
    this.props.state = playing ? 'playing' : 'paused';
    this.props.player = player;

    this.callbacks.changed();
  }

  get active() {
    return !!this.props.player;
  }

  get playing() {
    return this.props.state === 'playing';
  }

  get ended() {
    return this.props.player.ended;
  }

  get loading() {
    return this.active && this.props.loading;
  }

  set loading(loading) {
    if (this.props.loading !== loading) {
      this.props.loading = loading;
      this.callbacks.changed();
    }
  }

  get playbackRate() {
    if (!this.active) {
      return null;
    }

    return this.props.player.playbackRate;
  }

  set playbackRate(value) {
    this.props.player.playbackRate = value;
  }

  set state(state) {
    if (this.props.state !== state) {
      this.props.state = state;
      this.callbacks.changed();
    }
  }

  set player(player) {
    this.updatePlayer(player, false);
  }
}

export default class PlaybackController {
  constructor(callbacks) {
    this.callbacks = callbacks;

    this.props = {
      loading: false,
      playWhenLoaded: false,
      uberUpdateTimeout: null,

      lastAction: null,
      state: '',

      playFailed: false,

      playbackRate: 1,

      destroyed: false,
    };

    this.speakerVideo = new VideoPlayerController({ changed: () => this.scheduleUberUpdate() });
    this.slideVideo = new VideoPlayerController({ changed: () => this.scheduleUberUpdate() });
  }

  scheduleUberUpdate() {
    if (this.props.destroyed) {
      return;
    }

    if (this.props.uberUpdateTimeout) return;

    this.props.uberUpdateTimeout = setTimeout(() => {
      this.props.uberUpdateTimeout = null;
      this.uberUpdate();
    }, 0);
  }

  play() {
    log('PLBCKCTRL', 'play');

    this.playFailed = false;

    this.props.lastAction = 'play';
    this.state = 'playing';

    if (this.loading) {
      this.props.playWhenLoaded = true;
    }

    this.speakerVideo.play();
    this.slideVideo.play();
  }

  pause() {
    log('PLBCKCTRL', 'pause');

    this.props.lastAction = 'pause';
    this.state = 'paused';

    this.speakerVideo.pause();
    this.slideVideo.pause();

    if (this.loading) {
      this.props.playWhenLoaded = false;
    }
  }

  updateSpeakerVideoLoading(loading) {
    log('PLBCKCTRL', 'updateSpeakerVideoLoading', loading);

    this.speakerVideo.loading = loading;
  }

  updateSpeakerVideoState(state) {
    log('PLBCKCTRL', 'updateSpeakerVideoState', state);

    this.speakerVideo.state = state;
  }

  updateSlideVideoPlayer(player, playing) {
    log('PLBCKCTRL', 'updateSlideVideoPlayer', player ? 'set' : 'reset', playing);

    this.slideVideo.updatePlayer(player, playing);

    if (this.slideVideo.active && this.slideVideo.playbackRate !== this.playbackRate) {
      this.slideVideo.playbackRate = this.playbackRate;
    }
  }

  updateSlideVideoLoading(loading) {
    log('PLBCKCTRL', 'updateSlideVideoLoading', loading);

    this.slideVideo.loading = loading;
  }

  updateSlideVideoState(state) {
    if (!this.slideVideo.active) {
      return;
    }

    log('PLBCKCTRL', 'updateSlideVideoState', state, this.slideVideo.ended ? 'ended' : 'not ended');

    if (!this.slideVideo.ended) {
      this.slideVideo.state = state;
    }
  }

  uberUpdate() {
    if (this.speakerVideo.loading || this.slideVideo.loading) {
      return this.updateLoading();
    }

    if (this.loading) {
      this.updateLoadingDone();
    }

    if (this.playing) {
      return this.updatePlaying();
    }

    return this.updatePaused();
  }

  updateLoading() {
    log('PLBCKCTRL', 'updateLoading', this.speakerVideo.loading, this.slideVideo.loading);

    if (!this.loading) {
      this.props.playWhenLoaded = this.playing;
    }

    this.loading = true;

    if (!this.speakerVideo.loading || !this.slideVideo.loading) {
      if (this.speakerVideo.loading) {
        this.slideVideo.pause();
      }

      if (this.slideVideo.loading) {
        this.speakerVideo.pause();
      }
    }
  }

  updateLoadingDone() {
    const debug = [];
    if (this.props.playWhenLoaded) debug.push('playWhenLoaded');
    if (this.props.playFailed) debug.push('playFailed');

    log('PLBCKCTRL', 'updateLoadingDone', debug);

    this.loading = false;

    if (this.props.playWhenLoaded || this.props.lastAction === 'play') {
      this.props.playWhenLoaded = false;

      if (
        (this.speakerVideo.active && !this.speakerVideo.playing) ||
        (this.slideVideo.active && !this.slideVideo.playing)
      ) {
        this.play();
      }
    }

    this.scheduleUberUpdate();
  }

  updatePlaying() {
    log('PLBCKCTRL', 'updatePlaying', this.speakerVideo.playing, this.slideVideo.playing);

    if (
      (!this.speakerVideo.active || !this.speakerVideo.playing) &&
      (!this.slideVideo.active || !this.slideVideo.playing || this.slideVideo.ended)
    ) {
      this.state = 'paused';
    } else if (
      (this.speakerVideo.active && !this.speakerVideo.playing) ||
      (this.slideVideo.active && (!this.slideVideo.playing || this.slideVideo.ended))
    ) {
      if (this.props.lastAction === 'pause') this.pause();
    } else {
      this.state = 'playing';
    }
  }

  updatePaused() {
    log('PLBCKCTRL', 'updatePaused', this.speakerVideo.playing, this.slideVideo.playing);

    if (
      (!this.speakerVideo.active || this.speakerVideo.playing) &&
      (!this.slideVideo.active || this.slideVideo.playing || this.slideVideo.ended)
    ) {
      this.state = 'playing';
    } else if (
      (this.speakerVideo.active && this.speakerVideo.playing) ||
      (this.slideVideo.active && (this.slideVideo.playing || this.slideVideo.ended))
    ) {
      if (this.props.lastAction === 'play') this.play();
    } else {
      this.state = 'paused';
    }
  }

  updatePlaybackRate(rate) {
    if (this.playbackRate === rate) {
      return;
    }

    log('PLBCKCTRL', 'updatePlaybackRate', rate);

    this.playbackRate = rate;
  }

  destroy() {
    this.props.destroyed = true;

    if (this.props.uberUpdateTimeout) {
      clearTimeout(this.props.uberUpdateTimeout);
      this.props.uberUpdateTimeout = null;
    }
  }

  get playing() {
    return this.props.state === 'playing';
  }

  get loading() {
    return this.props.loading;
  }

  set loading(loading) {
    if (this.props.loading !== loading) {
      log('PLBCKCTRL', 'loadingChanged', loading);

      this.props.loading = loading;
      defer(() => this.callbacks.loadingChanged(loading));
    }
  }

  get playbackRate() {
    return this.props.playbackRate;
  }

  set playbackRate(value) {
    if (value === this.playbackRate) {
      return;
    }

    log('PLBCKCTRL', 'playbackRateChanged', value);

    this.props.playbackRate = value;
    this.speakerVideo.playbackRate = value;

    if (!this.slideVideo.active) {
      return;
    }

    this.slideVideo.playbackRate = value;
  }

  set state(state) {
    if (this.props.state !== state) {
      log('PLBCKCTRL', 'stateChanged', state);

      this.props.state = state;
      this.callbacks.stateChanged(state);
    }
  }

  set speakerVideoPlayer(player) {
    this.speakerVideo.player = player;
  }

  set playFailed(value) {
    this.props.playFailed = value;

    if (value) {
      this.pause();
    }
  }

  get activeSlideVideoSourceUrl() {
    return this.slideVideo && this.slideVideo.props.player && this.slideVideo.props.player.activeVideoSourceUrl
      ? this.slideVideo.props.player.activeVideoSourceUrl
      : null;
  }

  get activeSlideQualityName() {
    return this.slideVideo && this.slideVideo.props.player && this.slideVideo.props.player.activeQualityName
      ? this.slideVideo.props.player.activeQualityName
      : null;
  }

  get activeSlidePlaybackRateValue() {
    return this.slideVideo && this.slideVideo.props.player && this.slideVideo.props.player.activeSlidePlaybackRateValue
      ? this.slideVideo.props.player.activeSlidePlaybackRateValue
      : null;
  }
}
