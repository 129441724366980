import average from './average';
import percentile from './percentile';
import standardDeviation from './standard_deviation';

function createMinAvgMaxStdDevPercentileString(values, inv = false) {
  const sortedValues = values.sort((a, b) => (inv ? b - a : a - b));

  const min = Math.round(sortedValues[0]);
  const avg = Math.round(average(sortedValues));
  const max = Math.round(sortedValues[sortedValues.length - 1]);
  const stdDev = Math.round(standardDeviation(sortedValues));

  return [
    inv ? max : min,
    avg,
    inv ? min : max,
    stdDev,
    Math.round(percentile(sortedValues, 0.25)),
    Math.round(percentile(sortedValues, 0.5)),
    Math.round(percentile(sortedValues, 0.75)),
    Math.round(percentile(sortedValues, 0.9)),
    Math.round(percentile(sortedValues, 0.99)),
  ].join('/');
}

export default createMinAvgMaxStdDevPercentileString;
