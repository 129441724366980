import createUserMessageArray from './create_user_message_array';

function videoCdnLoadingFailedUserMessage(presentationId) {
  return createUserMessageArray({
    presentationId,
    userMessage: 'Loading video CDN failed.',
    tryAgainAndReport: true,
  });
}

export default videoCdnLoadingFailedUserMessage;
