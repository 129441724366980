function nativeVideoNetworkStateName(networkState) {
  switch (networkState) {
    case 0:
      return 'NETWORK_EMPTY';
    case 1:
      return 'NETWORK_IDLE';
    case 2:
      return 'NETWORK_LOADING';
    case 3:
      return 'NETWORK_NO_SOURCE';
    default:
      return `UNKNOWN (${networkState})`;
  }
}

export default nativeVideoNetworkStateName;
