import createUserMessageArray from './create_user_message_array';

function noSuitableVideoFormatUserMessage(presentationId) {
  return createUserMessageArray({
    presentationId,
    userMessage: 'No suitable video format for playback found.',
    upgradeBrowser: true,
    report: true,
  });
}

export default noSuitableVideoFormatUserMessage;
