import createUserMessageArray from './create_user_message_array';

function videoNotFoundUserMessage(presentationId) {
  return createUserMessageArray({
    presentationId,
    userMessage: 'Video not found.',
    tryAgainAndReport: true,
  });
}

export default videoNotFoundUserMessage;
