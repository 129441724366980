import VideoKenLoader from './video_ken_loader';

export default class VideoKen {
  constructor(options) {
    this.options = options;
  }

  load(callback) {
    if (window.VideoKenPlayer) {
      this._loadVideoKenPlayer(callback);
    } else {
      VideoKenLoader.load(() => {
        this._loadVideoKenPlayer(callback);
      });
    }
  }

  _loadVideoKenPlayer(callback) {
    // #player is present only in the old Library -> load Video Ken manually in the new Library
    if (!this.options.container.closest('#player')) {
      window.VideoKenPlayer(this.options.container, this.options.presentationId);
    }

    if (callback) {
      callback();
    }
  }
}
