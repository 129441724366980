import addListener from 'plugins/utilities/add_listener';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

import Popup from './popup';

const INITIAL_PROPS = {
  id: null,
};

export default class BookmarksPopup extends Popup {
  constructor(popup, options) {
    super(popup, options);

    if (!isVariableDefinedNotNull(popup)) {
      return;
    }

    this.props = { ...INITIAL_PROPS };

    this.form = this.popup.querySelector('[data-slp-target="updateBookmarkForm"]');
    this.noteInput = this.form.querySelector('[data-slp-target="bookmarkNote"]');
    this.updateButton = this.form.querySelector('[data-slp-target="updateBookmark"]');
    this.removeButton = this.form.querySelector('[data-slp-target="removeBookmark"]');

    addListener(this.form, 'submit', this.handleUpdateBookmarkFormSubmit.bind(this));
    addListener(this.removeButton, 'click', this.remove.bind(this));
    addListener(this.noteInput, 'keydown', this.handleKeyDown.bind(this));
  }

  open(id) {
    if (!isVariableDefinedNotNull(this.popup) || !isVariableDefinedNotNull(id)) {
      return;
    }

    const prevNote = this.noteInput.value.trim();

    if (isVariableDefinedNotNull(this.id) && prevNote !== '') {
      this.options.callbacks.update({ id: this.id, note: prevNote });
    }

    this.id = id;
    this.noteInput.value = '';

    super.open();

    this.noteInput.focus();
  }

  close(update = false) {
    if (!isVariableDefinedNotNull(this.popup)) {
      return;
    }

    const note = this.noteInput.value.trim();

    if (update && note !== '') {
      this.options.callbacks.update({ id: this.id, note });
    }

    super.close();

    this.noteInput.value = '';
  }

  remove(event) {
    event.preventDefault();
    event.stopPropagation();

    this.options.callbacks.remove(this.id);
    super.close();
  }

  handleUpdateBookmarkFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    this.close(true);
  }

  handleKeyDown(event) {
    if (
      event.ctrlKey ||
      event.metaKey
    ) {
      return;
    }

    if (event.keyCode === 27) {
      event.preventDefault();
      this.close(true);
    }
  }

  set id(id) {
    this.props.id = id;
  }

  get id() {
    return this.props.id;
  }
}
