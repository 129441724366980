import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

import EditorImage from './slide/editor_image';
import EditorVideo from './slide/editor_video';

export default class Editor {
  constructor(options, callbacks) {
    this.options = options;
    this.callbacks = callbacks;

    this.props = {
      timedSlideIndex: null,
      manualSlideIndex: null,
      ready: false,
      inSync: true,
    };

    this.slides = [];

    this.pointer = [];
    this.currentPointerIndex = undefined;
    this.lastUpdatePointerTime = 0;
  }

  load() {
    this.callbacks.loadingChanged(false);
    this.props.ready = true;
    this.callbacks.ready();
  }

  updateAllSlides(data, updateFrom = 0) {
    let firstSlideWithTime = null;
    let forceFireCurrentSlideChanged = false;
    this.slides.splice(updateFrom);

    for (let i = updateFrom; i < data.length; i++) {
      const slide = this.getNewSlideFromData(data[i]);

      if (data[i].current) {
        if (this.props.inSync) {
          this.props.timedSlideIndex = i;
        } else {
          this.props.manualSlideIndex = i;
        }

        if (slide.loading) {
          forceFireCurrentSlideChanged = true;
        }
      }

      if (isVariableDefinedNotNull(slide.time) && !isVariableDefinedNotNull(firstSlideWithTime)) {
        firstSlideWithTime = i;
      }

      this.slides.push(slide);
    }

    this.afterSlidesUpdate(firstSlideWithTime, forceFireCurrentSlideChanged);
  }

  updateSlidesBetween(data, changed) {
    this.updateAllSlides(data, changed.from);
  }

  updateSpecificSlides(data, changed) {
    let firstSlideWithTime = null;
    let forceFireCurrentSlideChanged = false;

    for (let i = 0; i < changed.length; i++) {
      const index = changed[i];
      const slide = this.updateSpecificSlide(data, index);

      if (data[index].current && slide.loading) {
        forceFireCurrentSlideChanged = true;
      }

      if (isVariableDefinedNotNull(slide.time) && !isVariableDefinedNotNull(firstSlideWithTime)) {
        firstSlideWithTime = index;
      }
    }

    this.afterSlidesUpdate(firstSlideWithTime, forceFireCurrentSlideChanged);
  }

  updateSpecificSlide(data, index, fireCurrentSlideChanged = false) {
    const oldSlide = this.slides[index];
    const newSlide = this.getNewSlideFromData(data[index]);
    const areTheSame = this.slidesTheSameExceptKeys(oldSlide, newSlide, ['data.muted']);
    const slide = areTheSame ? oldSlide : newSlide;

    if (areTheSame) {
      fireCurrentSlideChanged = false;

      if (slide.type === 'video') {
        slide.alwaysMuted = newSlide.alwaysMuted;
      }
    } else {
      this.slides[index] = slide;
    }

    if (slide.current) {
      if (this.props.inSync) {
        this.props.timedSlideIndex = index;
      } else {
        this.props.manualSlideIndex = index;
      }
    }

    if (fireCurrentSlideChanged) {
      const firstSlideWithTime = isVariableDefinedNotNull(slide.time) ? index : null;
      const forceFireCurrentSlideChanged = slide.loading;

      this.afterSlidesUpdate(firstSlideWithTime, forceFireCurrentSlideChanged);
    }

    return slide;
  }

  updateSlides(data, type, changed) {
    switch (type) {
      case 'all':
        this.updateAllSlides(data);
        break;
      case 'between':
        this.updateSlidesBetween(data, changed);
        break;
      case 'multiple':
        this.updateSpecificSlides(data, changed);
        break;
      case 'single':
        this.updateSpecificSlide(data, changed, true);
        break;
      default:
        break;
    }
  }

  afterSlidesUpdate(firstSlideWithTime, forceFireCurrentSlideChanged = false) {
    if (this.slideCount > 0 && isVariableDefinedNotNull(firstSlideWithTime)) {
      const initialSlideIndexKey = this.props.inSync ? 'timedSlideIndex' : 'manualSlideIndex';

      if (!isVariableDefinedNotNull(this.props[initialSlideIndexKey])) {
        const initialSlideIndex = this.slideIndexForTime(this.slides[firstSlideWithTime].time);
        this.props[initialSlideIndexKey] = initialSlideIndex;
      }

      this.fireCurrentSlideChanged(0);
    } else if (forceFireCurrentSlideChanged) {
      this.fireCurrentSlideChanged(0);
    }
  }

  updateVideoCurrentTimeSlideIfInSync(time, slide) {
    if (!this.props.inSync) return;
    if (slide.type !== 'video') return;

    const expectedSlideVideoCurrentTime = time - slide.time;
    const slideVideoCurrentTime = this.currentSlide.currentTime;

    if (Math.abs(expectedSlideVideoCurrentTime - slideVideoCurrentTime) > 1000) {
      this.currentSlide.currentTime = expectedSlideVideoCurrentTime;
    }
  }

  updateSlide(time, programDateTime, force, autoPlayVideoSlide = false) {
    if (this.slides.length === 0) {
      return;
    }

    const nextSlideIndex = this.slideIndexForTime(time);
    const nextSlide = this.slides[nextSlideIndex];

    if (force || nextSlideIndex !== this.props.timedSlideIndex) {
      this.props.timedSlideIndex = nextSlideIndex;

      if (this.props.inSync) {
        this.fireCurrentSlideChanged(autoPlayVideoSlide);
      }
    }

    if (autoPlayVideoSlide) {
      nextSlide.autoPlay = true;

      this.updateVideoCurrentTimeSlideIfInSync(time, nextSlide);
    }
  }

  timeForSlideIndex(slideIndex) {
    return this.slides[slideIndex].time;
  }

  slideIndexForTime(time) {
    let i = 1;
    while (i < this.slides.length && isVariableDefinedNotNull(this.slides[i].time) && this.slides[i].time <= time) {
      i += 1;
    }

    return i - 1;
  }

  updatePointerData(pointer) {
    this.pointer = pointer;

    if (isVariableDefinedNotNull(this.lastUpdatePointerTime)) {
      this.updatePointer(this.lastUpdatePointerTime);
    }
  }

  updatePointer(time) {
    let nextPointerIndex;
    if (this.inSync) {
      nextPointerIndex = this.pointerIndexForTime(time);
    } else {
      nextPointerIndex = null;
    }

    const nextPointer = this.pointer[nextPointerIndex];

    if (!nextPointer || nextPointer.time > time || time - nextPointer.time > 5000) {
      nextPointerIndex = null;
    }

    if (this.currentPointerIndex !== nextPointerIndex) {
      this.currentPointerIndex = nextPointerIndex;
      this.fireCurrentPointerChanged();
    }

    this.lastUpdatePointerTime = time;
  }

  pointerIndexForTime(time) {
    let i = 1;
    while (i < this.pointer.length && this.pointer[i].time <= time) {
      i += 1;
    }

    return i - 1;
  }

  fireCurrentPointerChanged() {
    this.callbacks.currentPointerChanged({
      time: this.currentPointer?.time,
      x: this.currentPointer?.x,
      y: this.currentPointer?.y,
    });
  }

  progressImageUrl(time) {
    const slideIndex = this.slideIndexForTime(time);

    if (slideIndex < 0 || slideIndex >= this.slides.length) {
      return null;
    }

    this.callbacks.preloadSlides(slideIndex);
    return this.slides[slideIndex].progressImageUrl;
  }

  dataForSlideIndex(index) {
    const slide = this.slides[index];

    if (slide.loading) {
      return {};
    }

    return { url: slide.url, type: slide.type, time: slide.time };
  }

  fireCurrentSlideChanged(autoPlayVideoSlide) {
    if (this.currentSlideIndex >= this.slides.length) {
      if (this.props.inSync) {
        this.props.timedSlideIndex = this.slides.length - 1;
      } else {
        this.props.manualSlideIndex = this.slides.length - 1;
      }
    }

    const currentSlideIndex = this.currentSlideIndex;
    const currentSlide = this.slides[currentSlideIndex];
    const time = currentSlideIndex === 0 ? 0 : currentSlide.time;
    const url = currentSlide.loading ? null : currentSlide.url;
    const originalUrl = currentSlide.loading ? null : currentSlide.originalUrl;

    const currentSlideData = {
      url,
      originalUrl,
      type: currentSlide.type,
      time,
    };

    if (this.currentSlide.type === 'video') {
      currentSlideData.video = this.currentSlide;
    }

    this.callbacks.currentSlideChanged(currentSlideIndex, this.slideCount, currentSlideData, autoPlayVideoSlide);
  }

  fireCurrentSlideUpdateVideoCurrentTime(expectedVideoCurrentTime) {
    if (this.currentSlide.type !== 'video') {
      return;
    }

    this.callbacks.currentSlideUpdateVideoCurrentTime(expectedVideoCurrentTime);
  }

  goToByIndex(index) {
    if (index < 0 || index > this.slideCount - 1) {
      return;
    }

    if (this.props.inSync) {
      this.props.timedSlideIndex = index;
    } else {
      this.props.manualSlideIndex = index;
    }

    this.fireCurrentSlideChanged();
  }

  next() {
    this.inSync = false;

    this.props.manualSlideIndex = (this.currentSlideIndex + 1) % this.slideCount;
    this.fireCurrentSlideChanged();
  }

  prev() {
    this.inSync = false;

    this.props.manualSlideIndex = (this.currentSlideIndex - 1 + this.slideCount) % this.slideCount;
    this.fireCurrentSlideChanged();
  }

  setInSync() {
    this.inSync = true;
  }

  getNewSlideFromData(data) {
    if (data.type === 'image') {
      return new EditorImage(
        {
          time: data.time,
          url: data.playerUrl,
          originalUrl: data.originalUrl,
          loading: isVariableDefinedNotNull(data.cropId) || isVariableDefinedNotNull(data.uploadId),
        },
        {
          loaded: this.callbacks.slideLoaded,
          error: this.callbacks.slideLoaded,
        },
      );
    }

    return new EditorVideo(
      {
        data: data.typeData,
        time: data.time,
        url: data.playerUrl || data.originalUrl,
        originalUrl: data.originalUrl,
        loading: isVariableDefinedNotNull(data.cropId) || isVariableDefinedNotNull(data.uploadId),
      },
      {
        loaded: this.callbacks.slideLoaded,
        error: this.callbacks.slideLoaded,
        updateVolume: this.callbacks.updateVolume,
        loadingChanged: (loading, target) => {
          this.callbacks.loadingChanged(loading);
          this.callbacks.videoLoadingChanged(target, loading);
        },
        stateChanged: this.callbacks.videoStateChanged,
        ended: this.callbacks.videoEnded,
        playbackRateChanged: this.callbacks.videoPlaybackRateChanged,
        playFailed: this.callbacks.videoPlayFailed,
        cmcd: (...args) => {
          this.callbacks.cmcd(...args);
        },
      },
    );
  }

  slidesTheSameExceptKeys(slideOne, slideTwo, exceptKeys = []) {
    if (slideOne.type !== slideTwo.type) {
      return false;
    }

    const oneKeys = Object.keys(slideOne.options);
    const oneDataKeys = slideOne.options.data ? Object.keys(slideOne.options.data) : [];
    const twoKeys = Object.keys(slideTwo.options);
    const twoDataKeys = slideTwo.options.data ? Object.keys(slideTwo.options.data) : [];

    if (oneKeys.length !== twoKeys.length || oneDataKeys.length !== twoDataKeys.length) {
      return false;
    }

    for (let i = 0; i < oneKeys.length; i++) {
      const k = oneKeys[i];

      if (k === 'data') {
        const exceptDataKeys = exceptKeys.filter((ek) => ek.startsWith('data.')).map((ek) => ek.replace('data.', ''));

        for (let j = 0; j < oneDataKeys.length; j++) {
          const dk = oneDataKeys[j];

          if (exceptDataKeys.indexOf(dk) === -1 && slideOne.options.data[dk] !== slideTwo.options.data[dk]) {
            return false;
          }
        }
      } else if (exceptKeys.indexOf(k) === -1 && slideOne.options[k] !== slideTwo.options[k]) {
        return false;
      }
    }

    return true;
  }

  set inSync(value) {
    if (this.props.inSync !== value) {
      this.props.inSync = value;

      if (this.props.inSync) {
        this.props.manualSlideIndex = null;
      }

      this.callbacks.inSyncChanged(this.props.inSync);
    }
  }

  get inSync() {
    return this.props.inSync;
  }

  get ready() {
    return this.props.ready;
  }

  get currentSlideIndex() {
    if (this.props.inSync || !isVariableDefinedNotNull(this.props.manualSlideIndex)) {
      return this.props.timedSlideIndex;
    }

    return this.props.manualSlideIndex;
  }

  get currentSlideTime() {
    return this.currentSlide ? this.currentSlide.time : null;
  }

  get currentSlideType() {
    return this.currentSlide ? this.currentSlide.type : null;
  }

  get currentSlide() {
    return this.slides[this.currentSlideIndex];
  }

  get currentPointer() {
    if (!isVariableDefinedNotNull(this.currentPointerIndex)) {
      return null;
    }

    return this.pointer[this.currentPointerIndex];
  }

  get slideCount() {
    return this.slides.length;
  }

  // eslint-disable-next-line no-empty-function
  set size(size) {}
}
