import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

function parseTime(string) {
  if (!isVariableDefinedNotNull(string)) {
    return 0;
  }

  if (typeof string !== 'string') {
    return string;
  }

  if (!string.match(/[hHmMsS]/)) {
    return parseInt(string, 10);
  }

  let time = 0;
  time += parseInt((/([0-9]+)[hH]/g).exec(string) || 0, 10) * 60 * 60;
  time += parseInt((/([0-9]+)[mM]/g).exec(string) || 0, 10) * 60;
  time += parseInt((/([0-9]+)[sS]/g).exec(string) || 0, 10);

  return time;
}

export default parseTime;
