function nativeVideoReadyStateName(readyState) {
  switch (readyState) {
    case 0:
      return 'HAVE_NOTHING';
    case 1:
      return 'HAVE_METADATA';
    case 2:
      return 'HAVE_CURRENT_DATA';
    case 3:
      return 'HAVE_FUTURE_DATA';
    case 4:
      return 'HAVE_ENOUGH_DATA';
    default:
      return `UNKNOWN (${readyState})`;
  }
}

export default nativeVideoReadyStateName;
