import createUserMessageArray from './create_user_message_array';

function videoLoadingFailedUserMessage(presentationId) {
  return createUserMessageArray({
    presentationId,
    userMessage: 'Video loading failed.',
    tryAgainAndReport: true,
  });
}

export default videoLoadingFailedUserMessage;
