import log from 'modules/player/log';

import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

export default class EmbedInterface {
  constructor() {
    this.props = {
      enabled: false,
    };

    if (window !== window.parent) {
      if (!this.embedOrigin) {
        console.warn('EMBED', 'embedOrigin is not set!');
      }

      if (!this.embedContainerId) {
        console.warn('EMBED', 'embedContainerId is not set!');
      }

      if (!this.embedParentUrl) {
        console.warn('EMBED', 'embedParentUrl is not set!');
      }

      this.props.enabled = true;
    }

    log('EMBED', 'enabled', this.enabled);
  }

  sendSetSize(size) {
    const height = size.height;

    this.sendMessage({
      method: 'setSize',
      height,
    });
  }

  sendUpdateSizeRequest() {
    this.sendMessage({ method: 'updateSizeRequest' });
  }

  sendBeforeLoad() {
    this.sendMessage({
      method: 'beforeLoad',
    });
  }

  sendLoad() {
    this.sendMessage({
      method: 'load',
    });
  }

  sendReady() {
    this.sendMessage({
      method: 'ready',
    });
  }

  sendError(error) {
    this.sendMessage({
      method: 'error',
      error,
    });
  }

  sendStateChanged(state) {
    this.sendMessage({
      method: 'stateChanged',
      state,
    });
  }

  sendVolumeChanged(volume, muted) {
    this.sendMessage({
      method: 'volumeChanged',
      volume,
      muted,
    });
  }

  sendAvailablePlaybackRatesChanged(rates) {
    this.sendMessage({
      method: 'availablePlaybackRatesChanged',
      rates,
    });
  }

  sendPlaybackRateChanged(rate) {
    this.sendMessage({
      method: 'playbackRateChanged',
      rate,
    });
  }

  sendAvailableSubtitlesChanged(tracks) {
    this.sendMessage({
      method: 'availableSubtitlesChanged',
      tracks,
    });
  }

  sendActiveSubtitlesChanged(track) {
    this.sendMessage({
      method: 'activeSubtitlesChanged',
      track,
    });
  }

  sendZoomRatioChanged(ratio) {
    this.sendMessage({
      method: 'zoomRatioChanged',
      ratio,
    });
  }

  sendSizeChanged(size) {
    this.sendMessage({
      method: 'sizeChanged',
      size,
    });
  }

  sendSlideUrl(index, url, type) {
    this.sendMessage({
      method: 'slideUrl',
      slide: index,
      url,
      type,
    });
  }

  sendTimeUpdate(currentTime, duration) {
    this.sendMessage({
      method: 'timeUpdate',
      currentTime,
      duration,
    });
  }

  sendMessage(message) {
    if (!this.enabled) {
      return;
    }

    let m = message;
    m.targetId = this.embedContainerId;
    m.slideslive = true;
    if (isVariableDefinedNotNull(window.ActiveXObject)) {
      m = JSON.stringify(m);
    }

    log('EMBED', 'post message', m);

    window.parent.postMessage(m, this.embedOrigin);
  }

  get enabled() {
    return this.props.enabled;
  }

  get embedOrigin() {
    return document.body.dataset.embedOrigin;
  }

  get embedContainerId() {
    return document.body.dataset.embedContainerId;
  }

  get embedParentUrl() {
    return document.body.dataset.embedParentUrl;
  }
}
